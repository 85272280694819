import MainLayout from "layouts/main"
import { Card } from "components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getFullDateWithTime } from "utils/validators";
import { Table, Row, Col, Button, message, Input } from "antd";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { executeTransfer, getProTransactions, stripeCheckout } from "services/transaction";
import ProTransactionStyle from "styles/ProTransactionStyle";
import { ExportOutlined } from "@ant-design/icons";
import FormItem from "antd/lib/form/FormItem";

const ProTransactionList = () => {

  const { t } = useTranslation(["common", "protransaction"]);
  const { colors } = useTheme();
  let history = useHistory();
  const [allTransaction, setAllTransaction] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalToTransfer, setTotalToTransfer] = useState(0);
  const [stripeAvailableBalance, setStripeAvailableBalance] = useState(0);
  const [topUpAmount, setTopUpAmount] = useState(0);
  const [sufficientFund, setSufficientFund] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isTransferring, setIsTransferring] = useState(false);

  const columns = [
    {
      key: "Id",
      title: t`protransaction:table.headers.id`,
      dataIndex: "id",
      align: "center" as "center",
    },
    {
      key: "Nom",
      title: t`protransaction:table.headers.name`,
      dataIndex: "name",
    },
    // {
    //   key: "TotalTtc",
    //   title: t`protransaction:table.headers.totalGross`,
    //   dataIndex: "totalGross",
    //   align: "center" as "center",
    //   render: (value) => { return Number(value ?? 0).toFixed(2)}
    // },
    // {
    //   key: "TotalTransferPaid",
    //   title: t`protransaction:table.headers.totalTransferPaid`,
    //   dataIndex: "totalTransferPaid",
    //   align: "center" as "center",
    //   render: (value) => { return Number(value ?? 0).toFixed(2)}
    // },
    // {
    //   key: "TotalComm",
    //   title: t`protransaction:table.headers.totalComm`,
    //   dataIndex: "totalCommUnpaid",
    //   align: "center" as "center",
    //   render: (value) => { return Number(value ?? 0).toFixed(2)}
    // },
    {
      key: "ToPay",
      title: t`protransaction:table.headers.toPay`,
      dataIndex: "totalToPay",
      align: "center" as "center",
      render: (value) => { return Number(value ?? 0).toFixed(2)}
    },
    {
      key: "Discount",
      title: t`protransaction:table.headers.totalDiscount`,
      dataIndex: "totalUserDiscount",
      align: "center" as "center",
      render: (value) => { return Number(value ?? 0).toFixed(2)}
    },
    {
      key: "LastDate",
      title: t`protransaction:table.headers.lastTransferDate`,
      dataIndex: "lastTransferDate",
      render: (date: string) => getFullDateWithTime(date),
      align: "center" as "center",
    },
    {
      key: "LastTransferAmount",
      title: t`protransaction:table.headers.lastTransferAmount`,
      dataIndex: "lastTransferAmount",
      align: "center" as "center",
      render: (value) => { return Number(value ?? 0).toFixed(2)}
    },
  ];

  useEffect(() => {
    async function fetchRatings() {
      await fetchData(null);
    }
    fetchRatings();

  }, []);

  const fetchData = async (params) => {
    const response = await getProTransactions();
    setAllTransaction(response.transactions);
    setTotalToTransfer(response.totalToPay);
    setStripeAvailableBalance(response.stripeAvailableBalance);
    setSufficientFund(response.fundsAvailable);
    setTotalRecords(response.totalRecords);
  };

  const handleTransfer = async() => {
    if (Number(totalToTransfer > 0)) {
      setIsTransferring(true);
      executeTransfer().then(() => {
        message.success(t`protransaction:message.success.transferComplete`, 5);
        window.location.reload();
      })
      .catch((e) => {
        if (e?.response?.data?.error?.message === "insufficient_fund")
          message.error(t`protransaction:message.error.insufficientFund`, 5);
        else
          message.error(t`protransaction:message.error.unknownError`, 5);
      })
      .finally(() => setIsTransferring(false));
    }
  }

  const handleTopUpAmountChanged = (e) => {
    setTopUpAmount(e.target.value);
  };

  const checkOut = async() => {
      if (Number(topUpAmount) > 0) {
      const payload = {
        topUpAmount: topUpAmount
      };

      setIsProcessing(true);
      stripeCheckout(payload).then((r: any) => {
        if (r.url) {
          window.location.href = r.url;
        }
      })
      .catch((e) => {
        message.error(t`protransaction:message.error.unknownError`, 5);
      })
      .finally(() => setIsProcessing(false));
    } else {
      message.error(t`protransaction:message.error.enterAmount`, 5);
    }
  }


  return (
    <MainLayout>
      <ProTransactionStyle />
      <Card>
        <h1 className="page-title">{t`protransaction:title`}</h1>
        <Row justify="space-between" gutter={[16, 8]} style={{ marginBottom: "10px" }}>
          <Col>
            <Row>
              <Col>
                <p className="page-sub-title">
                  {t("protransaction:stripeAvailableAmount")}:
                  <span className={!sufficientFund ? "not-sufficient-fund" : ""} >
                    {" "}{Number(stripeAvailableBalance ?? 0).toFixed(2)} €
                  </span>
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="page-sub-title">{t("protransaction:amountToTransfer")}: {Number(totalToTransfer ?? 0).toFixed(2)} €
                </p>
              </Col>
            </Row>
            {
              !sufficientFund && (
                <Row>
                <Col>
                <p className="not-sufficient-fund-text">{t("protransaction:fundCheck")}</p>
                </Col>
              </Row>
              )
            }
          </Col>
          <Col>
            <Button
              icon={<ExportOutlined />}
              size="large" onClick={handleTransfer}
              style={{ color: colors.shades.grey[25], border: "1px solid #707070", fontSize: "12pt" }}
              disabled={totalToTransfer <= 0 || isTransferring}
            >{t("protransaction:transfer")}</Button>
          </Col>
        </Row>
        <Row>
          <hr  style={{width: "100%"}}/>
        </Row>
        <Row style={{marginBottom: "10px"}}>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6}>
            <FormItem
              label={t("protransaction:topUpAmount")}
              className="page-sub-title"
            >
              <Input
                type="number"
                suffix="EUR"
                placeholder={t("protransaction:topUpAmount")}
                min={0}
                style={{ width: "200px"}}
                value={topUpAmount}
                onChange={(e) =>
                  handleTopUpAmountChanged(e)
                }
                  />
              </FormItem>
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={6} className="topup-button-pos-style">
            <Button 
              size="large" 
              onClick={checkOut}  
              style={{ color: colors.shades.grey[25], border: "1px solid #707070", fontSize: "12pt" }} 
              disabled={topUpAmount <= 0 || isProcessing}
            >
              {t("protransaction:checkOut")}
            </Button>
          </Col>
        </Row>
        <Row>
          <p className="discount-label">{t("protransaction:discountLabel")}</p>
        </Row>

        <Table
          rowKey={record => record.id}
          columns={columns}
          dataSource={allTransaction}
          locale={{ emptyText: t`common:table.emptyText` }}
          pagination={{
            pageSize: 10,
            defaultPageSize: 10,
            total: totalRecords,
          }}
        />
      </Card>
    </MainLayout>
  )
}
export default ProTransactionList;
