import { get, patch, post } from "./axios-service";

export const getPartners = () => {
  return get(`admin/partners`);
};

export const getPartnerById = (partnerId) => {
  return get(`admin/partner/${partnerId}`);
};

export const createPartner = (payload) => {
  return post(`admin/partners`, payload);
};

export const updatePartner = (partnerId, payload) => {
  return patch(`admin/partner/${partnerId}`, payload);
};

export const createPartnerAdmin = (payload) => {
  return post(`partner/signup`, payload);
};

export const updatePartnerAdmin = (partnerAdminId, payload) => {
  return patch(`partner-user/${partnerAdminId}`, payload);
};

export const getPartnerDocumentList = (partnerId) => {
  return get(`admin/partner/${partnerId}/documents`);
};

export const uploadPartnerDocument = (queryString, payload, fileType) => {
  const headers = {
    "Content-Type": "multipart/form-data",
    "Mime-Type": fileType,
  };
  return post(`admin/partner/upload-doc?filter=${queryString}`, payload, headers);
};

export const deletePartnerDocument = (partnerId) => {
  return post(`admin/partner-document/${partnerId}/delete`, {});
};

export const generatePartnerApiKey = (partnerId) => {
  return post(`partner/${partnerId}/generate-api-key`, {});
};

export const updateCreditBalance = (payload) => {
  return post(`admin/partners/update-credit-balance`, payload);
};