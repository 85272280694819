import { get, patch, post } from "./axios-service";

export async function retrieveAdmins() {

  const response = await get(`admin/admin-users`);
  return response;
}

export const patchAdminUser = (id, payload) => {
  return patch(`admin/admin-user/${id}`, payload);
}

export const createAdminUser = (payload) => {
  return post("admin/signup", payload);
};

export const uploadAdminAvatar = (id, payload, headers) => {
  return post(`admin/admin-user/${id}/upload-user-avatar`, payload, headers);
};

export const getAdminUser = (id) => {
  return get(`admin/admin-user/${id}`);
};

export const changeAdminPassword = (payload) => {
  return post(`admin/change-password`, payload);
};

export const forgetPassword = (payload) => {
  return post(`admin/forget-password`, payload);
};

export const resetPassword = (payload) => {
  return post(`admin/reset-password`, payload);
};

export const adminAccountActivation = (payload) => {
  return post(`admin/account-activation`, payload);
};