import * as React from "react";

function SvgPartnerIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37.994" height="39.351" viewBox="0 0 37.994 39.351">
      <g id="partnership" transform="translate(-2.25 -1.125)">
        <path id="Path_1554" data-name="Path 1554" d="M9.928,11.98a5.428,5.428,0,1,1,5.428-5.428A5.428,5.428,0,0,1,9.928,11.98Zm0-8.142a2.714,2.714,0,1,0,2.714,2.714A2.714,2.714,0,0,0,9.928,3.839Z" transform="translate(0.464)" fill="#fff"/>
        <path id="Path_1555" data-name="Path 1555" d="M27.928,11.98a5.428,5.428,0,1,1,5.428-5.428A5.428,5.428,0,0,1,27.928,11.98Zm0-8.142a2.714,2.714,0,1,0,2.714,2.714A2.714,2.714,0,0,0,27.928,3.839Z" transform="translate(4.175)" fill="#fff"/>
        <path id="Path_1556" data-name="Path 1556" d="M34.816,38.389H29.389a2.714,2.714,0,0,1-2.714-2.714v-9.5h2.714v9.5h5.428V23.462H37.53V15.321a1.357,1.357,0,0,0-1.357-1.357H27.461L21.247,24.819,15.033,13.964H6.321a1.357,1.357,0,0,0-1.357,1.357v8.142H7.678V35.675h5.428v-9.5h2.714v9.5a2.714,2.714,0,0,1-2.714,2.714H7.678a2.714,2.714,0,0,1-2.714-2.714v-9.5A2.714,2.714,0,0,1,2.25,23.462V15.321A4.071,4.071,0,0,1,6.321,11.25H16.606l4.641,8.142,4.641-8.142H36.173a4.071,4.071,0,0,1,4.071,4.071v8.142a2.714,2.714,0,0,1-2.714,2.714v9.5A2.714,2.714,0,0,1,34.816,38.389Z" transform="translate(0 2.087)" fill="#fff"/>
      </g>
    </svg>
  );
}

export default SvgPartnerIcon;
