import React from "react";
import { Row, Col, Card } from "antd";
import Meta from "antd/lib/card/Meta";
import ParametersStyle from "styles/parametersStyles";
import { useHistory } from "react-router-dom";

export const ParameterCard = (props) => {

  let history = useHistory();

  return (
    <>
      <ParametersStyle />

      <Card className="parameter-card" onClick={() => history.push(props.path)} hoverable>
        <Row className="card-row">
          <Col span={4} className="col-left">
            {props.icon}
          </Col>
          <Col span={20}>
            <Meta
              title={props.title}
              description={props.description}
            /> 
          </Col>
        </Row>
    </Card>
  </>
  );
};
