import * as React from "react";

function SvgBlogIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30.307"
      height="25.306"
      viewBox="0 0 30.307 25.306"
    >
      <g id="noun_Blog_3799801" transform="translate(-9.849 -9.844)">
        <path
          id="Path_493"
          data-name="Path 493"
          d="M47.264,56.273a6.4,6.4,0,0,0,2.463.488,6.539,6.539,0,0,0,1.751-.239,5.309,5.309,0,0,0,3.195-2.314c1.36-2.274.251-5.048-2.473-6.184a6.479,6.479,0,0,0-4.213-.25,5.309,5.309,0,0,0-3.2,2.314C43.431,52.364,44.54,55.138,47.264,56.273Zm-1.8-5.9a4.588,4.588,0,0,1,2.761-2,5.648,5.648,0,0,1,1.512-.206,5.536,5.536,0,0,1,2.128.422c2.354.982,3.313,3.38,2.137,5.346a4.589,4.589,0,0,1-2.761,2,5.6,5.6,0,0,1-3.64-.216C45.245,54.733,44.287,52.334,45.462,50.368Z"
          transform="translate(-21.381 -25.806)"
          fill="#b0b0b0"
          stroke="#b0b0b0"
          strokeWidth="0.3"
        />
        <path
          id="Path_494"
          data-name="Path 494"
          d="M52.23,58.837a3.676,3.676,0,0,0,1.415.281,3.761,3.761,0,0,0,1.006-.137,3.05,3.05,0,0,0,1.835-1.329A2.466,2.466,0,0,0,55.065,54.1a3.723,3.723,0,0,0-2.421-.143,3.05,3.05,0,0,0-1.835,1.329A2.466,2.466,0,0,0,52.23,58.837Zm-.749-3.273a2.329,2.329,0,0,1,1.4-1.015,2.863,2.863,0,0,1,.768-.1,2.807,2.807,0,0,1,1.08.214,1.884,1.884,0,0,1,1.085,2.714,2.329,2.329,0,0,1-1.4,1.015,2.842,2.842,0,0,1-1.847-.109A1.884,1.884,0,0,1,51.481,55.564Z"
          transform="translate(-25.297 -30.125)"
          fill="#b0b0b0"
          stroke="#b0b0b0"
          strokeWidth="0.3"
        />
        <path
          id="Path_495"
          data-name="Path 495"
          d="M15.037,32.646a.321.321,0,0,0,.166.2.434.434,0,0,0,.2.048.447.447,0,0,0,.084-.008l8.4-1.613,8.567,3.573a2.1,2.1,0,0,0,.8.159,2.138,2.138,0,0,0,.573-.078,1.735,1.735,0,0,0,1.044-.756l4.933-8.246A1.4,1.4,0,0,0,39,23.9l-4.619-1.926.709-1.185.374.156a.438.438,0,0,0,.168.033.446.446,0,0,0,.119-.016.364.364,0,0,0,.217-.157l1.848-3.088a.29.29,0,0,0-.168-.419l-6.476-2.7a.443.443,0,0,0-.287-.017.364.364,0,0,0-.217.157l-.785,1.312-1.433-5.2a.321.321,0,0,0-.166-.2.44.44,0,0,0-.283-.04l-2.336.448-.225-.817a.383.383,0,0,0-.449-.235.316.316,0,0,0-.282.374l.225.818-3.372.647-.225-.817a.383.383,0,0,0-.449-.235.316.316,0,0,0-.282.374l.225.818-3.372.647-.225-.817a.384.384,0,0,0-.449-.235.316.316,0,0,0-.282.374l.225.818-3.372.647-.225-.817a.384.384,0,0,0-.449-.235.316.316,0,0,0-.282.374l.225.818-2.336.448a.316.316,0,0,0-.281.374Zm23.629-8.187a.82.82,0,0,1,.472,1.182l-4.933,8.246a1.013,1.013,0,0,1-.61.442,1.239,1.239,0,0,1-.8-.047l-14.962-6.24a.82.82,0,0,1-.472-1.182l4.933-8.246a1.014,1.014,0,0,1,.61-.442,1.249,1.249,0,0,1,.334-.045,1.224,1.224,0,0,1,.47.093l14.962,6.24Zm-4.954-2.765-4.157-1.734.709-1.185,4.157,1.734Zm-2.538-6.4,5.8,2.421-1.513,2.529-5.8-2.421Zm-18.38-1.124.225.817a.367.367,0,0,0,.365.243.452.452,0,0,0,.084-.008.316.316,0,0,0,.282-.374l-.225-.818,3.372-.647.225.817a.367.367,0,0,0,.365.243.453.453,0,0,0,.084-.008.316.316,0,0,0,.282-.374l-.225-.818L21,12.6l.225.817a.367.367,0,0,0,.365.243.453.453,0,0,0,.084-.008.316.316,0,0,0,.282-.374l-.225-.818L25.1,11.81l.225.817a.367.367,0,0,0,.365.243.453.453,0,0,0,.084-.008.316.316,0,0,0,.282-.374l-.225-.818,1.971-.378,1.553,5.64-.536.9a.29.29,0,0,0,.168.419l.6.251-.709,1.185-4.845-2.02a2.118,2.118,0,0,0-1.377-.082,1.736,1.736,0,0,0-1.044.756l-.872,1.459-5.221,1a.316.316,0,0,0-.281.375.368.368,0,0,0,.365.242.458.458,0,0,0,.085-.008l4.619-.887-2.234,3.735-1.521.292a.316.316,0,0,0-.281.375.368.368,0,0,0,.365.242.458.458,0,0,0,.085-.008l.919-.176-.959,1.6a1.4,1.4,0,0,0,.808,2.02l5.342,2.228L15.684,32.2l-4.86-17.651Z"
          transform="translate(0 0)"
          fill="#b0b0b0"
          stroke="#b0b0b0"
          strokeWidth="0.3"
        />
        <path
          id="Path_496"
          data-name="Path 496"
          d="M33.262,25.811a.383.383,0,0,0-.45-.234L21.495,27.749a.316.316,0,0,0-.281.375.368.368,0,0,0,.365.242.458.458,0,0,0,.085-.008l11.318-2.173A.316.316,0,0,0,33.262,25.811Z"
          transform="translate(-7.002 -10.703)"
          fill="#b0b0b0"
          stroke="#b0b0b0"
          strokeWidth="0.3"
        />
      </g>
    </svg>
  );
}

export default SvgBlogIcon;
