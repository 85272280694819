import { createGlobalStyle } from "styled-components";

const ParametersStyle = createGlobalStyle`
  
  body {
    margin: 0;
    padding: 0;
    font-family: "Gilroy","Glacial Indifference",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  }

  h1 {
    font-family: var(--font-glacial);
    font-weight: 400;
    font-size: 2.5rem;
    color: ${({ theme }) => theme.colors.shades.grey[4]};
  }

  h2 {
    font-family: var(--font-gilroy);
    font-weight: 800;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.shades.grey[18]};
  }

  h2 {
    font-family: var(--font-gilroy);
    font-weight: 800;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  p {
    margin: 0;
  }

  .ant-card-body {
    padding: 0;
  }

  .card-style {
    width: 360;
    height: 130;
    box-shadow: var(--box-shadow);
  }

  .card-row {
    row-gap: 0px;
    height: 130px;
    align-items: center;
  }

  .col-left {
    height: 100%;
    background-color: ${({ theme }) => theme.colors.darkBlue};
    align-items: center;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .ant-card-meta {
    padding-left: 20px;
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  .ant-card-meta-description {
    color: ${({ theme }) => theme.colors.darkBlue};
    font-family: var(--font-gilroy);
    font-size: 1.00rem;
  }

  .ant-card-meta-title {
    color: ${({ theme }) => theme.colors.darkBlue};
    font-family: var(--font-gilroy);
    font-size: 1.42rem;
  }

  .col-card {
    margin-bottom: 10;
  }

  .parameter-card {
    box-shadow: var(--box-shadow);
  }

  .section-title {
    font-size: 20pt;
    font-family: var(--font-glacial);
    font-weight: bold;
    color: ${({ theme }) => theme.colors.darkBlue};
    padding: 10px;
  }

  .section-title-sub {
    font-size: 16pt;
    font-family: var(--font-glacial);
    font-weight: bold;
    color: ${({ theme }) => theme.colors.darkBlue};
    margin-top: 5;
    margin-bottom: 5;
  }

  .text-label {
    font-size: 14pt;
    font-family: var(--font-glacial);
    font-weight: bold;
    color: ${({ theme }) => theme.colors.darkBlue};
    margin-top: 5;
    margin-bottom: 5;

    .ant-form-item-control{
      padding-left: 15px;
      color: black;
      font-weight: normal;
    }

    &.quill-label {
      display: block;
      margin: 0 0 0.5rem 0;
    }
  }

  .ql-container {
    // height: 175px !important;
    min-height:170px !important;
  }

  .ql-editor {
    // height: 175px !important;
    min-height:170px !important;
  }

  .ql-toolbar.ql-snow {
    background-color:  ${({ theme }) => theme.colors.darkBlue};
  }

  .ql-snow .ql-stroke {
    fill: none;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
  }

  .ql-snow .ql-fill, .ql-snow .ql-stroke.ql-fill {
    fill: #fff;
  }

  .ql-toolbar.ql-snow .ql-picker-label {
    color: #fff;
  }

  .ant-table-wrapper {
    padding: 2px;
  }

  .table-row-style{
    background-color: ${({ theme }) => theme.colors.darkBlue};
    color: white
  }

  .ant-form-item-label > label {
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  .form-vertical {
    padding: 10;
  }

  .select-style {
    width: 100%;
  }
  .ql-font span[data-value="SimSun"]::before, .ql-font span[data-label="SimSun"]::before {
    font-family: "SimSun";
  }

  .quil-row-container {
    height: 300px;
  }

  .quil-container-button {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .ql-font-SimSun { font-family: 'SimSun'; }

  .ql-font-roboto {
    font-family: 'Roboto', sans-serif;
  }

  .vertical-divider{
    border-left: 1px solid white;
    height: 50px;
  }

  .category-container {
    margin: 1.5rem 0 0 0;
  }

  .parent-card-style {
    box-shadow: var(--box-shadow);
  }
  
  .language-label {
    font-size: 10.5pt;
    padding-bottom: 7px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    top: 15.0005px;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header{
    font-size: 20pt;
    font-family: var(--font-glacial);
    font-weight: bold;
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.darkBlue};
    border-color: ${({ theme }) => theme.colors.darkBlue};
  }

  .category-image-uploader-container {
    padding: 1rem;

    .category-image-uploader {
      .category-image-preview {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .ql-container.ql-snow {
    height: 150px;
    overflow: scroll;
  }

`

export default ParametersStyle