import { useEffect, useState } from "react";
import MainLayout from "layouts/main";
import { Badge, Button, Card, Col, Dropdown, Menu, message, Radio, Row, Space, Table } from "antd";
import { useTranslation } from "react-i18next";
import Avatar from "antd/lib/avatar/avatar";
import { LaunchRounded } from "@material-ui/icons";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { getNotifications } from "services/notification";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { TextField } from "components/text-field";
import NotificationStyle from "styles/notficationStyles";
import { MenuItem } from "@material-ui/core";
import { fullImageUrl } from "utils";

const Notification = () => {
  const { t } = useTranslation(["common", "notification", "dashboard"]);
  const history = useHistory();
  const [limit, setLimit] = useState(25);
  const [searchTerm, setSearchTerm] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [filterChoice, setFilterChoice] = useState(null);
  const [offset, setOffset] = useState(0);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();

  const NOTIFICATION_TYPE = {
    ACTIVITY: {
      link: (id) => () => history.push(`/annonces/${id}`)
    },
    MESSAGE: {
      link: () => () => history.push("/")
    },
    USER: {
      link: (id) => () => history.push(`/utilisateurs/${id}`)
    },
    RATING: {
      link: (id) => () => history.push(`/evaluations/${id}`)
    },
    TRANSACTION: {
      link: (id) => () => history.push(`/transaction`)
    }
  }

  const columns = [
    {
      title: t`notification:columns.image`,
      dataIndex:['avatar', 'isOnlineOrActive'],
      align: "center" as "center",
      render: (text, record) => {
        return (<div className="notification-avatar-container">
          <Avatar src={fullImageUrl(record.imageUrls, 'sm')} size={60} className="notification-avatar" icon={<UserOutlined />}/>
          <Badge color={record.isOnlineOrActive ? `green`: `red`}/>
        </div>);
      },
    },
    {
      title: t`notification:columns.user`,
      dataIndex: "objectCreatedBy",
      // sorter: (a, b) => a.objectCreatedBy > b.objectCreatedBy ? 1 : -1,
      sorter: true,
    },
    {
      title: t`notification:columns.title`,
      dataIndex: "title",
      render: (text) => {
        return t(`dashboard:notifications.types.${text}`);
      }
    },
    {
      key: "date",
      title: t`notification:columns.date`,
      dataIndex: "createdAt",
      render: (date: string) => format(new Date(date), `dd MMMM`),
      align: "center" as "center",
      // sorter: (a, b) => a.createdAt > b.createdAt ? 1 : -1,
      sorter: true,
    },
    {
      key: "time",
      title: t`notification:columns.time`,
      dataIndex: "createdAt",
      render: (date: string) => format(new Date(date), `HH:mm`),
      align: 'center' as "center",
      // sorter: (a, b) => a.createdAt > b.createdAt ? 1 : -1,
      sorter: true,
    },
    {
      title: t`notification:columns.link`,
      render: (record) => {
        return (
          <Button type="primary" 
            icon={<LaunchRounded />} 
            className="notification-icon-button-redirect" 
            onClick={NOTIFICATION_TYPE[record.objectType].link(record.objectId)}
          />
        );
      },
      align: "center" as "center"
    },
  ];

  useEffect(() => {
    let params = {
      "offset": 0,
      "limit": limit,
    };
    fetchData(params);
  }, []);



  const fetchData = (params={}) => {
    getNotifications(JSON.stringify(params)).then((resp) => {
      setNotifications(resp.notifications);
      setTotalRecords(resp.totalRecords);
    }).catch((e) => {
      console.log(e);
      message.error(t`common:errors.commonError`, 5);
    });
  };

  const handleSearch = (event) => {
    let searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    let searchParams = null;
    searchParams = {
      where: {
        search: searchTerm,
        title: filterChoice ?? null
      }
    }
    fetchData(searchParams);
  };

  const onTableChange = (pagination, b, sort) => {
    if (sort && sort.field && sort.order) {
      sortColumn(sort.field, sort.order, pagination.current - 1);
    } else {
      setOffset(pagination.current - 1);
      let params = null;

      params = {
        offset: pagination.current - 1,
        limit: limit,
        where: {
          search: searchTerm ?? null,
          title: filterChoice ?? null
        },
      };
  
      fetchData(params);
    }
  };

  const sortColumn = (field, order, currentPage) => {
    setSortField(field);
    setSortOrder(order);

    order = order === "ascend" ? 'ASC' : 'DESC'; 
    let params = null;
    let sortOrder = null;

    if (field === "objectCreatedBy") {
      sortOrder = `CREATEDBY${order}`;
    }
    else if (field === "createdAt") {
      sortOrder = `CREATEDAT${order}`;
    }

    params = {
      offset: currentPage,
      limit: limit,
      where: {
        search: searchTerm ?? null,
        title: filterChoice ?? null,
        sorting: sortOrder
      },
    };  

    fetchData(params);
  };

  const radioItems = [
    {
      label: t("common:filters.all"),
      value: "ALL"
    },
    {
      label: t(`dashboard:notifications.types.NEW_USER_PRO_CREATED`),
      value: "NEW_USER_PRO_CREATED"
    },
    {
      label: t(`dashboard:notifications.types.NEW_ACTIVITY_CREATED`),
      value: "NEW_ACTIVITY_CREATED"
    },
    {
      label: t(`dashboard:notifications.types.NEW_RATING_CREATED`),
      value: "NEW_RATING_CREATED"
    },
    {
      label: t(`dashboard:notifications.types.NEW_TRANSACTION_CREATED`),
      value: "NEW_TRANSACTION_CREATED"
    },
    {
      label: t(`dashboard:notifications.types.ACTIVITY_UPDATED`),
      value: "ACTIVITY_UPDATED"
    },
    {
      label: t(`dashboard:notifications.types.ACTIVITY_EXPIRED`),
      value: "ACTIVITY_EXPIRED"
    },
  ];

  const onChangeFilter = (event) => {
    setFilterChoice(event.target.value);
    let filterParams = {
      where: {
        title : event.target.value,
        search: searchTerm ?? null,
      }
    }
    fetchData(filterParams);
  }

  const showMenu = (
    <Menu className="filter-container">
      <p className="filter-menu-title">{t("common:filters.filter")}</p>
      <Radio.Group value={filterChoice} onChange={onChangeFilter}>
        {radioItems.map((item, index) =>
          <MenuItem key={index} className="filter-menu-item">
            <Radio value={item.value}>{item.label}</Radio>
          </MenuItem>
        )}
      </Radio.Group>
    </Menu>
  );

  return (
    <MainLayout>
      <NotificationStyle />
      <Card>
        <Row justify="space-between" gutter={[16, 8]} className="notification-list-header">
          <Col>
            <h1 className="featured-title">{t`notification:title`}</h1>
            <Dropdown overlay={showMenu} placement="bottomLeft" arrow>
              <a className="filter-dropdown">
                {t("common:filters.sort")}{"   "}<DownOutlined />
              </a>
            </Dropdown>
          </Col>
          <Col className="search-text-container">
            <Space>
              <p className="search-text">{t("common:search")}:</p>
              <TextField radius="0.8125rem" onPressEnter={handleSearch} />
            </Space>
          </Col>
        </Row>
        <Table
          rowKey={record => record.id}
          loading={isLoading}
          columns={columns}
          dataSource={notifications}
          locale={{ emptyText: t`common:table.emptyText` }}
          onChange={onTableChange}
          pagination={{
            pageSize: limit,
            defaultPageSize: limit,
            total: totalRecords,
            showSizeChanger: false
          }}
        />
      </Card>
    </MainLayout>
  );
};

export default Notification;