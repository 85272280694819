import {
  Space,
  Col,
  Row,
  message,
  Card,
  Table,
  Switch,
} from "antd";
import {
  TextButton,
  TransactionInfoBanner,
} from "components";
import MainLayout from "layouts/main";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  addDays,
  endOfDay,
  format,
  isWithinInterval,
  startOfDay,
} from "date-fns";
import TransactionStyle from "styles/transactionStyle";
import { deleteTransaction, getRatingTrackingInfo, getTransactionLogs, getTransactionSummaryById, refundTransactionPayment, sendEmailForRating, sendReminderEmail } from "services/transaction";
import { fr } from "date-fns/locale";
import { TRANSACTION_STATUS } from "../../constants";
import { fullImageUrl } from "utils";

interface ITransactionDetail {
  /** transaction's id */
  id?: number;
  /** date of the transaction */
  transactionDateTime: string;
  /** url of the picture of the activity booked */
  activityPicture: string;
  /** name of the activity booked */
  activityName: string;
  /** date of the activity booked */
  activityDateTime: string;
  /** activity's id */
  activityId: number;
  /** admin first name */
  adminNeoFirstName: string;
  /** amount paid by the buyer */
  amount: string;
  /** amount received by neosilver - the commission */
  applicationAmount: string;
  /** booking reference */
  bookingReference: string;
  /** avatar of the buyer */
  buyerAvatar: string;
  /** first name of the buyer */
  buyerFirstname: string;
  /** first name of the seller */
  sellerFirstname: string;
  /** status of the booking */
  status: string;
  /** amount that the pro gets */
  transferAmount: string;
  /** object of image urls */
  imageUrls: IImageUrls;
  grossAmount: string;
  discount: string;

}
interface IImageUrls {
  sm: string;
  md: string;
  url: string;
}

const TransactionDetail = () => {

  let history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation(["common", "transaction"]);
  const [transactionSummary, setTransactionSummary] = useState([]);
  const [transactionDetails, setTransactionDetails] = useState<ITransactionDetail>();
  const [transactionStatus, setTransactionStatus] = useState([]);
  const [ratingTracking, setRatingTracking] = useState([]);
  const [ratingId, setRatingId] = useState();
  const [refundInProcess, setRefundInProcess] = useState(false);

  useEffect(() => {
    fetchTransactionDetails();
  }, [id]);

  const fetchTransactionDetails = async () => {
    try {
      const response = await getTransactionSummaryById(id);
      const resRating = await getRatingTrackingInfo(id);
      const resTransactionLogs = await getTransactionLogs(id);
      console.log('response :>> ', response);
      console.log('resRating :>> ', resRating);
      console.log("resTransactionLogs", resTransactionLogs);

      const arr = [];
      arr.push(response);
      setTransactionSummary(arr);
      console.log("Response >> ", response);
      setTransactionDetails(response);
      setRatingId(resRating.ratingId);
      setRatingTracking([resRating]);
      setTransactionStatus(resTransactionLogs);
    }
    catch (e) {
      console.log("ERROR", e?.response?.data?.error?.message);
      console.log("ERROR CODE", e?.response?.data?.error?.statusCode);
      message.error(`${e?.response?.data?.error?.statusCode}: ${e?.response?.data?.error?.message} `, 10);
    }
  }

  const handleSendReminder = () => {
    sendReminderEmail(id)
      .then((resp) => {
        getRatingTrackingInfo(id).then((resp) => {
          setRatingId(resp.ratingId);
          setRatingTracking([resp]);
        });
        message.success(t`common:success.emailReminder`, 5);
      })
      .catch((e) => {
        console.log('e?.response?.data :>> ', e?.response?.data);
      });
  };

  const handleSendEmail = () => {
    sendEmailForRating(id)
      .then((resp) => {
        console.log('resp :>> ', resp);
        message.success(t`common:success.emailReminder`, 5);
      })
      .catch((e) => {
        console.log('e?.response?.data :>> ', e?.response?.data?.error?.message?.message);
        if (e?.response?.data?.error?.message?.message === "no_rating_found_for_booking") {
          message.error(t`common:errors.noRatingFound`, 10);
        }
      });
  };

  const handleDeleteTransaction = () => {
    deleteTransaction(id).then((resp) => {
      console.log('resp :>> ', resp);
      if (resp && resp.status === "deleted") {
        message.success(t`common:success.transactionDeleted`, 5);
        history.push("/transactions");
      }
    }).catch((e) => {
      console.log('e?.response?.data?.error :>> ', e?.response?.data?.error);
      //"unable_to_delete_transaction"
      message.error(t`common:errors.transactionDeletedError`, 10);
    });
  };

  const ratingTrackingColumns = [
    {
      title: t`transaction:details.rating.sendOn`,
      dataIndex: "lastTrackingDate",
      align: "left" as "left",
      render: (date: string) => date && format(new Date(date), `EEEE dd MMMM yyyy ${t("common:at")} HH:mm`, {
        locale: fr,
      }),
    },
    {
      title: t`transaction:details.rating.completedOn`,
      dataIndex: "ratingCreatedAt",
      align: "left" as "left",
      render: (date: string) => date && format(new Date(date), `EEEE dd MMMM yyyy ${t("common:at")} HH:mm`, {
        locale: fr,
      }),
    },
    {
      title: t`transaction:details.rating.status`,
      dataIndex: "ratingStatus",
      render: (status: string) => {return status ? t(`transaction:details.rating.statusList.${status}`) : "" },
      align: "left" as "left",
    },
    {
      title: t`transaction:details.rating.visible`,
      dataIndex: "isVisible",
      align: "left" as "left",
      render: (checked, record) => {
        return (
          <Switch
            checked={record.isVisible}
            disabled={true}
          />
        );
      },
    },
  ];

  const statusColumns = [
    {
      title: t`transaction:details.transactionStatus.columns.to`,
      dataIndex: "sender",
      align: "left" as "left",
    },
    {
      title: t`transaction:details.transactionStatus.columns.subject`,
      dataIndex: "subject",
      align: "left" as "left",
      render: (subject: string) => t(`transaction:details.transactionStatus.subjects.${subject}`)
    },
    {
      title: t`common:calendar.date`,
      dataIndex: "createdDate",
      align: "left" as "left",
      render: (date: string) => date && format(new Date(date), `EEEE dd MMMM yyyy ${t("common:at")} HH:mm`, {
        locale: fr,
      }),
    },
  ];

  const renderTransactionStatusTable = () => (
    <Table
      rowKey={record => record.id}
      columns={statusColumns}
      dataSource={transactionStatus}
      pagination={false}
      locale={{ emptyText: t`common:table.emptyText` }}
    />
  );

  const renderEvaluationTable = () => (
    <Table
      rowKey={record => record.ratingId}
      columns={ratingTrackingColumns}
      dataSource={ratingTracking}
      pagination={false}
      locale={{ emptyText: t`common:table.emptyText` }}
    />
  );

  const handleRefundPayment = () => {
    const payload = {
      bookingId: id
    }
    refundTransactionPayment(payload).then((resp) => {
      console.log('resp :>> ', resp);
      message.success(t`transaction:success.refundSucess`, 5);
      setRefundInProcess(true);
    }).catch((e) => {
      console.log('e :>> ', e);
      message.error(t`transaction:error.refundError`, 5);
    });
  };

  const isTransactionDateBetween = useCallback(() => {
    const startDate = startOfDay(new Date());
    const endDate = endOfDay(addDays(new Date(), 3));

    if (isWithinInterval(new Date(transactionDetails?.transactionDateTime), { start: startDate, end: endDate })) {
      return true;
    }

    return false;
  }, [transactionDetails]);

  const canSendEvalutations = () => {

  }

  return (
    <MainLayout>
      <TransactionStyle />
      <Space direction="horizontal" size={16} className="page-header-container">
        <TextButton
          variant="salmon"
          rounded
          height="2.5rem"
          width="2.5rem"
          onClick={() => history.push("/transactions")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6.667"
            height="10.63"
            viewBox="0 0 6.667 10.63"
          >
            <g
              id="Groupe_2445"
              data-name="Groupe 2445"
              transform="translate(-89.525 -140.615)"
            >
              <path
                id="Tracé_1256"
                data-name="Tracé 1256"
                d="M1298.171,1464.772l-3.848,4.062,3.848,3.74"
                transform="translate(-1203.394 -1322.743)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </g>
          </svg>
        </TextButton>
      </Space>
      <TransactionInfoBanner transaction={transactionSummary} />
      <section className="transaction-detail-container">
        <Row gutter={[8, 8]}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Card title={
                  <div className="transaction-info-header">
                    <img src="/icons/transaction-icon.svg" alt="icon pour information de commande" />
                    <h1>{t`transaction:details.title`}</h1>
                    {
                      transactionDetails?.transactionDateTime &&
                      <label>
                        {t("transaction:details.date",
                          {
                            date: format(new Date(transactionDetails?.transactionDateTime), "dd/MM/yyyy"),
                            time: format(new Date(transactionDetails?.transactionDateTime), "HH:mm")
                          })}
                      </label>
                    }
                  </div>
                }
                  className="transaction-card transaction-detail-card"
                >
                  <Row gutter={[24, 16]}>
                    <Col xs={24} sm={24} md={24} lg={14} xl={14} xxl={14}>
                      <h2>{t`transaction:details.transactionContent.title`}</h2>
                      <div className="transaction-detail-content-container">
                        <img src={fullImageUrl(transactionDetails?.activityPicture, "md")} alt="activité choisie" />
                        <div className="transaction-detail-content">
                          <label>{transactionDetails?.activityName}</label>
                          <p>Qté: 1 x {transactionDetails?.grossAmount}€</p>
                          <label>{transactionDetails?.grossAmount}€</label>
                          <a href={`/annonces/${transactionDetails?.activityId}`}>{t`common:buttons.seeAdvert`}</a>
                        </div>
                      </div>
                      <div className="transaction-detail-commission">
                        <label>{t`transaction:details.transactionContent.commission`}</label>
                        <label>{transactionDetails?.applicationAmount}€</label>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={10} xl={10} xxl={10}>
                      <h2>{t`transaction:details.paymentDetails.title`}</h2>
                      <aside className="transaction-payment-info-container">
                        <label>
                          {t("transaction:details.paymentDetails.amountPaidBy", { neoName: transactionDetails?.buyerFirstname })}
                          <span>{Number(transactionDetails?.amount).toFixed(2)}€</span>
                        </label>
                        {
                          Number(transactionDetails?.discount) > 0 && (
                            <label>
                              {t("transaction:details.paymentDetails.discount")}
                              <span>
                                {Number(transactionDetails?.discount).toFixed(2)}€
                              </span>
                            </label>
                          )
                        }
                        <label>
                          {t("transaction:details.paymentDetails.neosilverAmount")}
                          <span>
                            {Number(transactionDetails?.applicationAmount).toFixed(2)}€
                          </span>
                        </label>
                        <label>
                          {t("transaction:details.paymentDetails.amountReceivedByPro", { proName: transactionDetails?.sellerFirstname })}
                          <span>
                            {Number(transactionDetails?.transferAmount).toFixed(2)}€
                          </span>
                          <p>{t`transaction:details.paymentDetails.note`}</p>
                        </label>
                      </aside>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card className="transaction-card transaction-detail-actions-container">
                  {transactionDetails?.status === TRANSACTION_STATUS.CREATED &&
                    <TextButton variant="salmon" rounded={true} onClick={handleDeleteTransaction}>
                      {t`common:buttons.deleteTransaction`}
                    </TextButton>
                  }
                  {transactionDetails?.status === TRANSACTION_STATUS.CONFIRMED && isTransactionDateBetween() &&
                    <TextButton variant="salmon" type="outlined" rounded={true} disabled={refundInProcess} onClick={handleRefundPayment}>{t`common:buttons.refund`}</TextButton>
                  }
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
            <Row gutter={[8, 8]}>
              <Col span={24}>
                <Card className="transaction-card transaction-evaluation-container">
                  <h2>{t`transaction:details.rating.title`}</h2>

                  <Row gutter={[8, 24]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={14}>
                      {renderEvaluationTable()}
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={10} className="transaction-evaluation-actions-container">
                      {ratingId && <a href={`/evaluations/${ratingId}`}>{t`common:buttons.seeMore`}</a>}
                      <TextButton variant="salmon" rounded={true} onClick={handleSendReminder}>{t`common:buttons.sendReminder`}</TextButton>
                      <TextButton variant="darkBlue" rounded={true} onClick={handleSendEmail}>{t`common:buttons.sendRequest`}</TextButton>
                    </Col>
                  </Row>

                </Card>
              </Col>
              <Col span={24}>
                <Card className="transaction-card">
                  <h2>{t`transaction:details.transactionStatus.title`}</h2>
                  {
                    renderTransactionStatusTable()
                  }
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </MainLayout>
  );
};

export default TransactionDetail;
