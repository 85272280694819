import { createGlobalStyle } from "styled-components";

const FeaturedStyle = createGlobalStyle`
  .featured-title {
    margin-bottom: 0;
    font-family: var(--font-glacial);
    font-weight: 600;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.shades.grey[18]};
  }

  .create-featured-button {
    .anticon {
      line-height: 2;
    }

    &.ant-btn-primary {
      background: ${({ theme }) => theme.colors.darkBlue};
      border-color: ${({ theme }) => theme.colors.darkBlue};
    }
  }

  .featured-list-header {
    margin: 0 0 1rem 0;

    .filter-dropdown {
      text-decoration: underline;
      color: ${({ theme }) => theme.colors.shades.grey[25]};
      font-family: var(--font-glacial);
      font-weight: 200;
      font-size: 0.75rem;
    }

    .search-text-container {
      display: flex;
      align-items: center;

      .search-text {
        margin-bottom: 0;
        font-family: var(--font-glacial);
        font-weight: 200;
        font-size: 0.75rem;
        color: ${({ theme }) => theme.colors.shades.grey[25]};
      }
    }
  }

  .filter-container {
    padding: 1rem;
    background: none;
    backdrop-filter: blur(30px);
  }

  .filter-menu-title {
    color: ${({ theme }) => theme.colors.shades.grey[2]};
    font-family: var(--font-glacial);
    font-weight: 200;
    font-size: 0.9375rem;
  }

  .MuiButtonBase-root.filter-menu-item {
    border: ${({ theme }) => `0.05rem solid ${theme.colors.darkBlue}`};
    border-radius: 0.5rem;
    margin: 0.1rem 0;
    height: 2.75rem;
    background-color: ${({ theme }) => `${theme.colors.white}80`};
  }

  .ant-dropdown-trigger > .anticon.anticon-down, .ant-dropdown-link > .anticon.anticon-down, .ant-dropdown-button > .anticon.anticon-down {
    font-size: 12px;
    vertical-align: sub;
  }

  .ant-switch-checked {
    background-color: ${({ theme }) => theme.colors.green};
  }

  .featured-icon-button-edit {
    &.ant-btn-primary {
      background: ${({ theme }) => theme.colors.darkBlue};
      border-color: ${({ theme }) => theme.colors.darkBlue};
    }
  }

  .featured-icon-button-delete {
    &.ant-btn-primary {
      background: ${({ theme }) => theme.colors.shades.red[8]};
      border-color: ${({ theme }) => theme.colors.shades.red[8]};
    }
  }

  .featured-tag-container {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
  }

  .featured-detail-content-container {
    /* --col-1-height: min(2000rem, calc(100vh - 20rem)); */
    --col-1-height: max(100%, 100%);

    .col-1 {
      height: var(--col-1-height);

      .description-textarea {
        height: 10rem;
      }
    }

    .col-2 {
      .sub-row {
        .sub-col-1 {
          .category-image-uploader {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .ant-upload.ant-upload-select-picture-card {
              border-color: ${({ theme }) => theme.colors.darkBlue};
              width: 100%;
              height: 10rem;
            }

            .category-image-preview {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .edit-image-icon {
              position: absolute;
              opacity: 0;
              transition: all ease 0.5s;
            }

            &:hover,
            &:focus {
              .edit-image-icon {
                opacity: 1;
                color: ${({ theme }) => theme.colors.white};
                background: rgba(0,0,0,0.4);
                backdrop-filter: blur(10px);
              }
            }
          }
        }

        .sub-col-2 {
          .tag-container {
            padding: 0.25rem 0 0 0;
            display: flex;
            flex-direction: row;
            gap: 0.5rem;

            .delete-btn-container {

            }

            .tag-form-container {
              width: 100%;

              .tag-content {
                margin: 0;

                .ant-form-item-control-input-content {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  gap: 1rem;
                }
              }
            }
          }

          .ant-form-item-label {
            margin: 0.75rem 0 0 0;
          }

          .add-tag-btn {
            margin: 1rem 0 0 0;
          }
        }
      }
    }

    .col-3 {

    }

    h2 {
      font-family: var(--font-glacial);
      font-size: 1.3rem;
      color: ${({ theme }) => theme.colors.darkBlue};
    }

    .ant-card {
      box-shadow: var(--box-shadow);
    }

    .ant-form-item-label {
      margin: 1rem 0 0 0;

      & > label {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-family: var(--font-gilroy);
        font-weight: 800;
        font-size: 0.9375rem;
      }
    }

    .ant-form-item-explain {
      min-height: 0;
    }
  }
`;

export default FeaturedStyle;
