import styled from "styled-components";
import { Divider, Row, Space } from "antd";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { TextButton } from "components";
import { useHistory } from "react-router-dom";
import { BackIcon } from "components/icons";

export const ParameterHeader = styled(
  ({ icon, title="", subTitle, goBack, btnClick, btnText, ...rest }) => {
    const { t, i18n } = useTranslation(["common", "parameters"]);
    const { colors } = useTheme();
    let history = useHistory();

    return (
      <>
        <Space
          direction="horizontal"
          size={16}
          className="page-header-container"
          style={{ paddingBottom: 10, }}
        >
          <TextButton
            variant="darkBlue"
            rounded
            height="2.5rem"
            width="2.5rem"
            onClick={() => history.push(goBack)}
          >
            <BackIcon />
          </TextButton>
        </Space>
        <Row {...rest} align="middle"  style={{ justifyContent: 'space-between' }}>
          <Space  className="header-icon-title-container">
            {icon}
            <Divider type="vertical" className="vertical-divider" />
            <p style={{ fontWeight: "bold" }}>{`${title} `}</p>
            {subTitle ? `/ ${subTitle}` : subTitle}
          </Space>
          <Space>
            {btnClick && (
              <TextButton
                rounded={true}
                variant="white"
                style={{ width: "250px", color: colors.darkBlue }}
                onClick={() => history.push(btnClick)}
              >
                {btnText}
              </TextButton>
            )}
          </Space>
        </Row>
      </>
    );
  }
)`
  background: ${({ theme }) => theme.colors.darkBlue};
  margin: 0 0 0 -5.91rem;
  padding: 1rem 1rem 1rem 5.4rem;
  width: 100vw;
  flex-wrap: nowrap;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 1rem;
  font-family: var(--font-glacial);
  font-size: 1.66rem;

  // .ant-divider-vertical {
  //   height: 3.9em;
  //   border-left: 1px solid #fff;
  // }

  .vertical-divider {
    border-left: 1px solid white;
    height: 50px;
  }

  // .ant-space-item{
  //   width: 100%;
  // }

  .header-icon-title-container {
    .ant-space-item {
      display: flex;
    }
  }
`;
