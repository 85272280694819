import MainLayout from "layouts/main"
import React, { Fragment, useEffect, useState } from "react";
import { Layout, Avatar, Input, Select, Row, Col, message, Divider, Badge, Tag } from "antd";
import 'moment/locale/fr';
import { UserOutlined } from "@ant-design/icons";
import { get } from '../../services/axios-service';
import { fullImageUrl } from "utils";
import { Card, TextButton } from "components";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import { approveMessage, blockMessage } from "services/message";
import { useTheme } from "definitions/styled-components";
import MessageStyle from "styles/MessageStyle";

const { Header, Content, Sider } = Layout;
const { Option } = Select;
const FILTER_OLD = "old";
const FILTER_RECENT = "recent";

const Messages = () => {

  const { t } = useTranslation(["common", "message"]);
  const { theme } = useTheme();
  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [userMessage, setUserMessage] = useState(null);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [messageValidated, setMessageValidated] = useState(false);

  useEffect(() => {

    get('admin/messages').then((res) => {
      console.log('RES', res);
      setConversations(res)
    }, (err) => {

    })
  }, []);

  const handleApproveMessage = (id) => () => {
    approveMessage(id).then((resp) => {
      console.log('resp :>> ', resp);
      message.success(t`message:success.messageValidated`, 5);
      setMessageValidated(true);
    }).catch((e) => {
      console.log('e :>> ', e);
      message.success(t`message:error.messageValidated`, 5);
    });
  }

  const handleBlockMessage = (id) => () => {
    blockMessage(id).then((resp) => {
      console.log('resp :>> ', resp);
      message.success(t`message:success.messageBlocked`, 5);
      setMessageValidated(false);
    }).catch((e) => {
      console.log('e :>> ', e);
      message.success(t`message:error.messageBlocked`, 5);
    });
  }

  const getCurrentMessage = (currentMessage) => {
    setCurrentConversation(currentMessage);
    get(`admin/message/${currentMessage?.id}`).then((res) => {
      console.log('res :>> ', res);
      if (res) {
        setUserMessage(res);
        setMessageValidated(res?.isValidated);
      }
    }, errr => {

    })
  }

  const handleSelectFilter = (value) => {
    const listConversations = [...conversations];
    if (value === FILTER_OLD) {
      listConversations?.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
    }
    if (value === FILTER_RECENT) {
      listConversations?.sort((a, b) => b.createdAt > a.createdAt ? 1 : -1);
    }

    setConversations(listConversations);
  };

  return (
    <>
      <MessageStyle />
      <MainLayout>
        <Layout className="message-main-content">
          <aside className="message-aside">
            <div className="message-title">
              <label>{t`message:title`}</label>
            </div>
            <div className="message-filter">
              <Select defaultValue="recent" onChange={handleSelectFilter}>
                <Option value={FILTER_OLD}>{t`message:filters.recents`}</Option>
                <Option value={FILTER_RECENT}>{t`message:filters.old`}</Option>
              </Select>
            </div>
            <div className="message-conversation">
              {conversations.length === 0 && (
                <p style={{ padding: 10, textAlign: "center" }}>
                  {t`message:noMessage`}
                </p>
              )}
              {conversations.length > 0 &&
                conversations.map((conversation, index) => (
                  <Fragment key={`${index}-${conversation.id}`}>
                    <section className={`conversation-item${conversation.id === currentConversation?.id ? " selected" : ""}`} onClick={() => getCurrentMessage(conversation)}>
                      <div className="conversation-avatar-container">
                        <Avatar src={fullImageUrl(conversation?.sentBy?.imageUrls, 'sm')} size={60} className="conversation-avatar" icon={<UserOutlined />}/>
                        <Badge color={conversation.sentBy.isOnlineOrActive ? `green`: `red`}/>
                      </div>
                      <div className="conversation-content-container">
                        <label>
                          {conversation?.sentBy.firstname} {conversation?.sentBy.lastname}
                        </label>
                        <p>
                          {conversation.text}
                        </p>
                      </div>
                      <div className="conversation-secondary-container">
                        <label className="conversation-date">{format(new Date(conversation.createdAt), "dd/MM/yyyy")}</label>
                        <Tag color={conversation?.sentBy?.role === "PRO" ? theme.colors.darkBlue : theme.colors.salmon }>{conversation?.sentBy?.role}</Tag>
                      </div>
                    </section>
                    <Divider />
                  </Fragment>
                ))}
            </div>
          </aside>
          <Layout className="site-layout">
            <label className="conversation-title">{t`message:subtitle`}</label>
              {currentConversation && (
                <section className="conversation-header">
                  <Avatar
                    size={{
                      xs: 20,
                      sm: 20,
                      md: 20,
                      lg: 40,
                      xl: 40,
                      xxl: 40,
                    }}
                    icon={<UserOutlined />}
                    src={fullImageUrl(currentConversation?.sentBy?.imageUrls)}
                  />
                  <div className="conversation-sender-details-container">
                    <label>
                      {currentConversation?.sentBy?.firstname} {currentConversation?.sentBy?.lastname}
                    </label>
                    <p>
                      {t("message:conversation.memberSince", {date: format(new Date(currentConversation?.sentBy?.createdAt), "dd/MM/yyyy")})}
                    </p>
                  </div>
                </section>
              )}
            <Content className="messages-wrapper">
              {userMessage &&
                <Row className="conversation-container" justify="center" gutter={[16, 16]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="conversation-date-container">
                    <p>{format(new Date(userMessage?.createdAt), "dd/MM/yyyy")}</p>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="conversation-content-container">
                    <Card>
                      <Avatar
                        size={{
                          xs: 40,
                          sm: 40,
                          md: 40,
                          lg: 60,
                          xl: 60,
                          xxl: 60,
                        }}
                        icon={<UserOutlined />}
                        src={fullImageUrl(currentConversation?.sentBy?.imageUrls)}
                      />
                      <p>{userMessage?.text}</p>
                      <label className="conversation-sender-details">
                        {userMessage?.sentBy?.firstname}
                        <span>•</span>
                        {format(new Date(userMessage?.sentBy?.createdAt), "dd MMMM yyyy")}
                        {messageValidated && <img src="/icons/validated-icon.svg" alt="message validé" />}
                      </label>
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} className="conversation-footer">
                    <TextButton
                      onClick={handleApproveMessage(userMessage?.id)}
                      variant="salmon"
                      width="14rem"
                      rounded>
                      {t`message:button.validate`}
                    </TextButton>
                    <TextButton
                      onClick={handleBlockMessage(userMessage?.id)}
                      variant="salmon"
                      type="outlined"
                      width="14rem"
                      rounded>
                      {t`message:button.block`}
                    </TextButton>
                  </Col>
                </Row>
              }

            </Content>
          </Layout>
        </Layout>
      </MainLayout>
    </>
  )
}
export default Messages;
