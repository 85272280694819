import {
  Avatar,
  Col,
  Form,
  Input,
  Row,
  Space,
  Image,
  Switch,
  Select,
  InputNumber,
  Checkbox,
  Radio,
  message,
  Modal,
  List,
} from "antd";
import {
  CustomCalendar,
  FileUploader,
  TextButton,
} from "components";
import { AdvertInfoBanner } from "components/advert-info-banner";
import MainLayout from "layouts/main";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  deleteActivityPicture,
  getAdvertById,
  getAdvertSummaryById,
  updateActivity,
  updateAdvert,
} from "services/advert";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AdvertStyle from "styles/AdvertStyle";
import {
  CameraOutlined,
  UserOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { getUser } from "services/user";
import {
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
} from "date-fns";
import { IconButton } from "@material-ui/core";
import { getCategoryList } from "services/category";
import COUNTRIES from "../../assets/countries.json";
import { get, patch, post } from "services/axios-service";
import { validateAddress, validateCity, validatePostCode } from "utils/validators";
import {
  CALENDAR,
  OFFER_TYPE_COURSE,
  OFFER_TYPE_PACK,
  OFFER_TYPE_SUBSCRIPTION,
  OFFER_TYPE_TRIAL,
  OFFER_TYPE_UNIT,
  SCHEDULE_REPEAT_END_DATE,
  SCHEDULE_REPEAT_END_NEVER,
  SCHEDULE_REPEAT_MONTH_TYPE_WEEKLY,
  SCHEDULE_REPEAT_TYPE_DAY,
  SCHEDULE_REPEAT_TYPE_ONE_TIME,
} from "../../constants";
import uuid from "react-uuid";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper/core";
import "swiper/swiper-bundle.css";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

let isFetchingSchedules = false;
SwiperCore.use([Pagination]);

const AdvertDetail = () => {
  let history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation(["common", "advert"]);
  const [advertSummary, setAdvertSummary] = useState([]);
  const [advert, setAdvert] = useState(null);
  const [assetImages, setAssetImages] = useState([]);
  const [user, setUser] = useState(null);
  const [isReviewed, setIsReviewed] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [isPopular, setIsPopular] = useState(false);
  const [listCategories, setListCategories] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedScheduleType, setSelectedScheduleType] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [country, setCountry] = useState("");
  const [postcode, setPostcode] = useState("");
  const [hostDescription, setHostDescription] = useState("");
  const [materialRequired, setMaterialRequired] = useState("");
  const [materialProvided, setMaterialProvided] = useState("");
  const [duration, setDuration] = useState("");
  const [level, setLevel] = useState("");
  const [activityAddressInitial, setActivityAddressInitial] = useState(null);
  const [browsedImg, setBrowsedImg] = useState(null);
  const [cityError, setCityError] = useState("");
  const [countryError, setCountryError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [postcodeError, setPostcodeError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [coursesCount, setCoursesCount] = useState("");
  const [isExclusive, setIsExclusive] = useState(false);
  const [youtube, setYoutube] = useState("");
  const [vimeo, setVimeo] = useState("");
  const [visioLink, setVisioLink] = useState("");
  const [schedules, setSchedules] = useState([]);
  const [forWhom, setForWhom] = useState([]);
  const [occasion, setOccasion] = useState([]);
  const [isUnitOffert, setIsUnitOffer] = useState(false);
  const [isPackOffer, setIsPackOffer] = useState(false);
  const [isSubscriptionOffer, setIsSubscriptionOffer] = useState(false);
  const [isCourseOffer, setIsCourseOffer] = useState(false);
  const [isTrialOffer, setIsTrialOffer] = useState(false);
  const [unitOfferPrice, setUnitOfferPrice] = useState("");
  const [packOfferPrice, setPackOfferPrice] = useState("");
  const [packQty, setPackQty] = useState("");
  const [subscriptionMonthlyPrice, setSubscriptionMonthlyPrice] = useState("");
  const [subscriptionYearlyPrice, setSubscriptionYearlyPrice] = useState("");
  const [subscriptionQuarterlyPrice, setSubscriptionQuarterlyPrice] = useState("");
  const [coursePrice, setCoursePrice] = useState("");
  const [courseQty, setCourseQty] = useState("");
  const [trialPrice, setTrialPrice] = useState("");
  const [unitOfferAvailablePlace, setUnitOfferAvailablePlace] = useState("");
  const [trialAvailablePlace, setTrialAvailablePlace] = useState("");
  const [offers, setOffers] = useState([]);
  const [courseOffers, setCourseOffers] = useState([]);
  const [packOffers, setPackOffers] = useState([]);
  const [refreshKey, setRefreshKey] = useState("");
  const [packRefreshKey, setPackRefreshKey] = useState("");
  const [locationType, setLocationType] = useState(null);

  const [showSchedulesModal, setShowSchedulesModal] = useState(false);
  const [calendarView, setCalendarViewChanged] = useState(CALENDAR.VIEWS.WEEK);
  const [calendarVisibleDate, setCalendarVisibleDateChanged] = useState(new Date());
  const [calendarIsLoading, setCalendarIsLoading] = useState(false);
  const newScheduleRef = useRef(null);

  const { Option } = Select;
  const { TextArea } = Input;
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    async function fetchAdvert() {
      getCategoryList().then((cat) => {
        setListCategories(cat);
      });
      const response = await getAdvertSummaryById(id);
      retrieveAllAdData();
      const arr = [];
      arr.push(response);
      setAdvertSummary(arr);
      if (response && response.creatorId) {
        const userResp = await getUser(response.creatorId);
        setUser(userResp);
      }
    }
    fetchAdvert();
  }, [id]);

  useEffect(() => {
    refreshSchedules().finally(() => setCalendarIsLoading(false));
  }, [title]);

  const retrieveAllAdData = async () => {
    const ad = await getAdvertById(id);
    console.log("ADD >>> ", ad);
    setAdvert(ad);
    setIsReviewed(ad.activity.isReviewed);
    setIsPublished(ad.activity.isPublished);
    setIsPopular(ad.activity.isPopular);
    setSelectedCategoryId(ad.activity.categoryId);
    setTitle(ad.activity.name);
    setDescription(ad.activity.description);
    setHostDescription(ad.activity.hostDescription);
    setMaterialProvided(ad.activity.materialProvided);
    setMaterialRequired(ad.activity.materialRequired);
    setLevel(ad.activity.requiredLevel);
    setDuration(ad.activity.duration);
    setSelectedScheduleType(ad.activity.scheduleType);
    setIsExclusive(ad.activity.isExclusive);
    setVisioLink(ad.activity.onlineLink);
    setForWhom(ad.activity.forWhom);
    setOccasion(ad.activity.occasion);
    setTrialAvailablePlace(ad.activity.paxMax);
    setUnitOfferAvailablePlace(ad.activity.paxMax);
    setLocationType(ad.activity.locationType);
    if (ad.activityAddress) {
      setActivityAddressInitial(ad.activityAddress);
      setCity(ad.activityAddress.city);
      setAddress(ad.activityAddress.address);
      setCountry(ad?.activityAddress?.country ? ad?.activityAddress?.country?.toString()?.toLowerCase() : "");
      setPostcode(ad.activityAddress.postCode);
    }
    if (ad.activitySchedules) {
      if (ad.activitySchedules.length > 0) {
        setCoursesCount(ad.activitySchedules.length);
      }
    }

    if (ad.activityOffers) {
      if (ad.activityOffers.length > 0) {
        setOffers(ad.activityOffers);
        const actCourseOffers = ad.activityOffers.filter(x => x.offerType === OFFER_TYPE_COURSE);
        setCourseOffers(actCourseOffers);
        const packOff = ad.activityOffers.filter(x => x.offerType === OFFER_TYPE_PACK && x.quantity > 1);
        setPackOffers(packOff);
        ad.activityOffers.forEach((o) => {
          if (o.offerType === OFFER_TYPE_UNIT) {
            if (o.quantity === 1) {
              setIsUnitOffer(true);
              setUnitOfferPrice(o.price);
            }
            if (o.quantity > 1) {
              setIsPackOffer(true);
            }
          }
          if (o.offerType === OFFER_TYPE_SUBSCRIPTION) {
            setIsSubscriptionOffer(true);
            if (o.subscriptionType === "MONTHLY") {
              setSubscriptionMonthlyPrice(o.price);
            }
            if (o.subscriptionType === "QUARTERLY") {
              setSubscriptionQuarterlyPrice(o.price);
            }
            if (o.subscriptionType === "YEARLY") {
              setSubscriptionYearlyPrice(o.price);
            }
          }
          if (o.offerType === OFFER_TYPE_COURSE) {
            setIsCourseOffer(true);
          }
          if (o.offerType === OFFER_TYPE_TRIAL) {
            setIsTrialOffer(true);
            setTrialPrice(o.price);
          }
        });
      }
    }

    const assetsImg = ad.activityAssets;
    const arr = [];
    if (assetsImg) {
      assetsImg.forEach((x) => {
        if (x.type === "PICTURE") arr.push(x);
        if (x.type === "VIMEO") setVimeo(x.url);
        if (x.type === "YOUTUBE") setYoutube(x.url);
      });
      setAssetImages(arr);
    }
  };

  const handleOfferCoursePriceChange = (e, type) => {

    let tmp = courseOffers;
    let idx = tmp.findIndex(x => x.id === type.id);
    type.price = e.target.value;
    tmp[idx] = type;
    setCourseOffers(tmp);
    setRefreshKey(`${new Date().getTime()}${idx}${type.price}`);
  };

  const handleOfferCourseQtyChange = (e, type) => {

    let tmp = courseOffers;
    let idx = tmp.findIndex(x => x.id === type.id);
    type.quantity = e.target.value;
    tmp[idx] = type;
    setCourseOffers(tmp);
    setRefreshKey(`${new Date().getTime()}${idx}${type.quantity}`);
  };

  const addPackCourseFormFields = () => {

    if (coursePrice && courseQty) {
      let qtyVal = courseOffers.filter(x => x.quantity === Number(courseQty));
      if (qtyVal && qtyVal.length > 0) {
        message.error(t`advert:offerTypes.uniqueSession`, 5);
      } else {
        const o = {
          offerType: OFFER_TYPE_COURSE,
          price: coursePrice,
          quantity: courseQty,
          subscriptionType: "PACK",
          id: uuid()
        };
        let arr = [];
        arr.push(o);
        let temp = courseOffers;
        let tmp = temp.concat(arr);
        setCourseOffers(tmp);
        setCoursePrice("");
        setCourseQty("");
      }
    }

  }

  const deleteSelectedOffer = (e, item) => {
    let arr = courseOffers.filter(x => x.id !== item.id);
    setCourseOffers(arr);
    setRefreshKey(`${new Date().getTime()}${item.id}${item.price}`);
  }

  const renderCourseOffers = () => {

    return (
      <List
        dataSource={courseOffers}
        itemLayout="horizontal"
        renderItem={item => (
          <List.Item
            key={refreshKey + item.id}
            actions={[<DeleteOutlined onClick={(e) => deleteSelectedOffer(e, item)} />]}
          >
            <div>
              <Input
                type="number"
                min={0}
                max={9999999999}
                placeholder={t("advert:description.effectivePrice")}
                style={{ width: "100%" }}
                suffix="EUR"
                value={item.price}
                onChange={(e) =>
                  handleOfferCoursePriceChange(e, item)
                }
              />
            </div>

            <div>
              <Input
                type="number"
                min={0}
                max={9999999999}
                placeholder={t("advert:description.effectivePrice")}
                style={{ width: "100%" }}
                value={item.quantity}
                onChange={(e) =>
                  handleOfferCourseQtyChange(e, item)
                }
              />
            </div>
          </List.Item>
        )}
      >
      </List>
    );

  }

  const renderPackOffers = () => {

    return (
      <List
        dataSource={packOffers}
        itemLayout="horizontal"
        renderItem={item => (
          <List.Item
            key={packRefreshKey + item.id}
            actions={[<DeleteOutlined onClick={(e) => deleteSelectedPackOffer(e, item)} />]}
          >
            <div>
              <Input
                type="number"
                min={0}
                max={9999999999}
                placeholder={t("advert:description.effectivePrice")}
                style={{ width: "100%" }}
                suffix="EUR"
                value={item.price}
                onChange={(e) =>
                  handleOfferPackPriceChange(e, item)
                }
              />
            </div>

            <div>
              <Input
                type="number"
                min={0}
                max={9999999999}
                placeholder={t("advert:description.effectivePrice")}
                style={{ width: "100%" }}
                value={item.quantity}
                onChange={(e) =>
                  handleOfferPackQtyChange(e, item)
                }
              />
            </div>
          </List.Item>
        )}
      >
      </List>
    );

  }

  const handleOfferPackPriceChange = (e, type) => {

    let tmp = packOffers;
    let idx = tmp.findIndex(x => x.id === type.id);
    type.price = e.target.value;
    tmp[idx] = type;
    setPackOffers(tmp);
    setPackRefreshKey(`${new Date().getTime()}${idx}${type.price}`);
  };

  const handleOfferPackQtyChange = (e, type) => {

    let tmp = packOffers;
    let idx = tmp.findIndex(x => x.id === type.id);
    type.quantity = e.target.value;
    tmp[idx] = type;
    setPackOffers(tmp);
    setPackRefreshKey(`${new Date().getTime()}${idx}${type.quantity}`);
  };

  const addPackOffersFormFields = () => {

    if (packOfferPrice && packQty) {
      let qtyVal = packOffers.filter(x => x.quantity === Number(packQty));
      if (qtyVal && qtyVal.length > 0) {
        message.error(t`advert:offerTypes.uniqueSession`, 5);
      } else if (Number(packQty) === 1)
        message.error(t`advert:offerTypes.packUniqueSection`, 5);
      else {
        const o = {
          offerType: OFFER_TYPE_PACK,
          price: packOfferPrice,
          quantity: packQty,
          subscriptionType: "PACK",
          id: uuid()
        };
        let arr = [];
        arr.push(o);
        let temp = packOffers;
        let tmp = temp.concat(arr);
        setPackOffers(tmp);
        setPackOfferPrice("");
        setPackQty("");
      }
    }

  }

  const deleteSelectedPackOffer = (e, item) => {
    let arr = packOffers.filter(x => x.id !== item.id);
    setPackOffers(arr);
    setPackRefreshKey(`${new Date().getTime()}${item.id}${item.price}`);
  }

  const handleCategoryImageUpload = ({ blob, base64, file }) => {
    setBrowsedImg(base64);

    if (base64) {
      const fileName = file.name;
      const fileType = file.type;
      const headers = {
        "Content-Type": "multipart/form-data",
        "Mime-Type": fileType,
      };
      const payload = new FormData();
      payload.append("avatar", blob, fileName);

      post(
        `admin/activity/${id}/upload-picture`,
        payload,
        headers
      )
        .catch((e) => {
          message.error(t`common:message.error`, 5);
        })
        .finally(() => retrieveAllAdData());
    }
  };

  const renderGalleryItem = (item, index) => {
    return <SwiperSlide key={index}>
      <div className="carousel-tag">
        <Image
          src={`${process.env.REACT_APP_S3_CDN_URL}${item.url}`}
          alt={item.name}
          className="carousel-image-gallery"
        />
        <IconButton
          aria-label="delete"
          style={{ fontSize: "12pt", color: "red" }}
          onClick={() => handleDeletePicture(item.id)}
        >
          <DeleteOutlined />
        </IconButton>
      </div>
    </SwiperSlide>
  };

  const onHandleActivityStatus = (checked) => {
    setIsPublished(checked);
    let payload = null;
    if (checked) {
      payload = {
        isPublished: true,
        publishedDate: new Date().toISOString(),
        status: "VALIDATED"
      };
    }
    else {
      payload = {
        isPublished: false,
      }
    }
    updateActivity(id, payload);

  };

  const onHandleActivityReviewed = (checked) => {
    setIsReviewed(checked);
    updateActivity(id, {isReviewed: checked});
  }

  const onHandleActivityPopular = (checked) => {
    setIsPopular(checked);
    updateActivity(id, { isPopular: checked });
  };

  const handleOnChangeCategory = (value) => {
    setSelectedCategoryId(value);
  };

  const handleOnChangeTransactionType = (value) => {
    setSelectedScheduleType(value);
  };

  const TRANSACTION_SCHEDULE_TYPE = [
    {
      key: "fixed",
      label: t`advert:transactionOptions.fixed`,
      value: "FIXED",
    },
    {
      key: "appointment",
      label: t`advert:transactionOptions.appointement`,
      value: "APPOINTMENT",
    },
  ];

  // const ACTIVITY_MOMENT = [
  //   {
  //     key: "morning",
  //     label: t`advert:attribute.moment.morning`,
  //     value: "MORNING",
  //   },
  //   {
  //     key: "noon",
  //     label: t`advert:attribute.moment.noon`,
  //     value: "NOON",
  //   },
  //   {
  //     key: "afternoon",
  //     label: t`advert:attribute.moment.afternoon`,
  //     value: "AFTERNOON",
  //   },
  //   {
  //     key: "evening",
  //     label: t`advert:attribute.moment.evening`,
  //     value: "EVENING",
  //   },
  // ];

  const LEVELS = [
    {
      label: t`advert:attribute.levels.none`,
      value: "NONE",
    },
    {
      label: t`advert:attribute.levels.beginner`,
      value: "BEGINNER",
    },
    {
      label: t`advert:attribute.levels.intermediate`,
      value: "INTERMEDIATE",
    },
    {
      label: t`advert:attribute.levels.advanced`,
      value: "ADVANCED",
    },
  ];

  const FOR_WHOM_OPTIONS = [
    {
      label: t`advert:attribute.forWhomOptions.couple`,
      value: "COUPLE",
    },
    {
      label: t`advert:attribute.forWhomOptions.singleMale`,
      value: "SINGLE_MALE",
    },
    {
      label: t`advert:attribute.forWhomOptions.singleFemale`,
      value: "SINGLE_FEMALE",
    },
    {
      label: t`advert:attribute.forWhomOptions.all`,
      value: "ALL",
    },
  ];

  const OCCASION_OPTIONS = [
    {
      label: t`advert:attribute.occasionOptions.valentine`,
      value: "VALENTINE",
    },
    {
      label: t`advert:attribute.occasionOptions.christmas`,
      value: "CHRISTMAS",
    },
    {
      label: t`advert:attribute.occasionOptions.birthday`,
      value: "BIRTHDAY",
    },
    {
      label: t`advert:attribute.occasionOptions.easter`,
      value: "EASTER",
    },
    {
      label: t`advert:attribute.occasionOptions.congratulation`,
      value: "CONGRATULATION",
    },
    {
      label: t`advert:attribute.occasionOptions.weddingAnniversary`,
      value: "WEDDING_ANNIVERSARY",
    },
    {
      label: t`advert:attribute.occasionOptions.wedding`,
      value: "WEDDING",
    },
    {
      label: t`advert:attribute.occasionOptions.thanks`,
      value: "GRATITUDE",
    },
    {
      label: t`advert:attribute.occasionOptions.hanouka`,
      value: "HANUKKA",
    },
    {
      label: t`advert:attribute.occasionOptions.mothersDay`,
      value: "MOTHERS_DAY",
    },
    {
      label: t`advert:attribute.occasionOptions.fathersDay`,
      value: "FATHERS_DAY",
    },
    {
      label: t`advert:attribute.occasionOptions.retirement`,
      value: "RETIREMENT",
    },
  ];

  const handleActivityAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleActivityAddressCityChange = (event) => {
    setCity(event.target.value);
  };

  const handleActivityAddressCountryChange = (value) => {
    setCountry(value);
  };

  const handleActivityAddressPostcodeChange = (event) => {
    setPostcode(event.target.value);
  };

  const handleHostDescriptionChange = (event) => {
    setHostDescription(event.target.value);
  };

  const handleMaterialRequiredChange = (event) => {
    setMaterialRequired(event.target.value);
  };

  const handleMaterialProvidedChange = (event) => {
    setMaterialProvided(event.target.value);
  };

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const handleAdTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleAdDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleCancelChanges = async () => {
    await retrieveAllAdData();
  };

  const handleYoutubeChange = (event) => {
    setYoutube(event.target.value);
  };

  const handleVimeoChange = (event) => {
    setVimeo(event.target.value);
  };

  const handleVisioLinkChange = (event) => {
    setVisioLink(event.target.value);
  };

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  }

  const handleSaveAdvert = async () => {
    let activityAddress = null;
      if (activityAddressInitial) {
        activityAddress = {
          id: activityAddressInitial.id,
          activityId: id,
          address: address,
          city: city,
          postCode: postcode,
          country: country,
        };
      } else {
        activityAddress = {
          activityId: id,
          address: address,
          city: city,
          postCode: postcode,
          country: country,
        };
      }

    if (!address && !city && !country) {
      activityAddress = null;
    }
   
    let activityAssets = [];
    if (youtube && youtube.length > 0) {
      activityAssets.push({
        activityId: id,
        type: "YOUTUBE",
        url: youtube,
        name: youtube,
      });
    }

    if (vimeo && vimeo.length > 0) {
      activityAssets.push({
        activityId: id,
        type: "VIMEO",
        url: vimeo,
        name: vimeo,
      });
    }

    let activityOffers = [];
    if (isUnitOffert) {
      let f = offers.filter(x => x.offerType === OFFER_TYPE_UNIT && x.quantity === 1);
      let o = {
        id: f && f[0] ? f[0].id : 0,
        offerType: OFFER_TYPE_UNIT,
        quantity: 1,
        price: unitOfferPrice,
        subscriptionType: "PACK",
      };
      activityOffers.push(o);
    }

    if (isTrialOffer) {
      let f = offers.filter(x => x.offerType === OFFER_TYPE_TRIAL);
      let o = {
        id: f && f[0] ? f[0].id : 0,
        offerType: OFFER_TYPE_TRIAL,
        quantity: 1,
        price: trialPrice,
        subscriptionType: "PACK",
      };
      activityOffers.push(o);
    }

    if (isPackOffer) {
      packOffers.forEach(x => {
        let pay = null;
        if (Number(x.id)) {
          pay = x;
        } else {
          pay = {
            id: 0,
            activityId: id,
            offerType: x.offerType,
            price: x.price,
            quantity: x.quantity,
            subscriptionType: x.subscriptionType
          }
        }
        activityOffers.push(pay);
      });
    }

    if (isCourseOffer) {
      courseOffers.forEach(x => {
        let pay = null;
        if (Number(x.id)) {
          pay = x;
        } else {
          pay = {
            id: 0,
            activityId: id,
            offerType: x.offerType,
            price: x.price,
            quantity: x.quantity,
            subscriptionType: x.subscriptionType
          }
        }
        activityOffers.push(pay);
      });
    }

    if (isSubscriptionOffer) {
      if (subscriptionMonthlyPrice) {
        let o = handleSubscriptionSave("MONTHLY", subscriptionMonthlyPrice);
        activityOffers.push(o);
      }
      if (subscriptionQuarterlyPrice) {
        let o = handleSubscriptionSave("QUARTERLY", subscriptionQuarterlyPrice);
        activityOffers.push(o);
      }
      if (subscriptionYearlyPrice) {
        let o = handleSubscriptionSave("YEARLY", subscriptionYearlyPrice);
        activityOffers.push(o);
      }
    }

    const payload = {
      activity: {
        id: id,
        name: title,
        description: description ? description : "",
        hostDescription: hostDescription ? hostDescription : "",
        locationType: activityAddress ? "ONLOCATION" : "ONLINE",
        onlineLink: visioLink,
        materialProvided: materialProvided ? materialProvided : "",
        materialRequired: materialRequired ? materialRequired : "",
        duration: duration ? duration : 0,
        requiredLevel: level,
        scheduleType: selectedScheduleType,
        isExclusive: isExclusive,
        forWhom: forWhom,
        occasion: occasion,
        categoryId: selectedCategoryId
      },
      // activityAddress: activityAddress ?? {},
      activityAssets: activityAssets,
      activityOffers: activityOffers,
    };

    if (activityAddress)
      payload["activityAddress"] = activityAddress;

    const response = await updateAdvert(payload);
    if (response) {
      message.success(t("common:message.success"), 5);
    }
  };

  const handleSubscriptionSave = (type, price) => {
    let f = offers.filter((x) => x.offerType === OFFER_TYPE_SUBSCRIPTION && x.subscriptionType === type);
    let o = {
      id: f && f[0] ? f[0].id : 0,
      activityId: id,
      offerType: OFFER_TYPE_SUBSCRIPTION,
      price: price,
      quantity: 1,
      subscriptionType: type,
    };

    return o;
  };

  const handleDeletePicture = async (assetId) => {
    const result = await deleteActivityPicture(assetId, user.id);
    if (result && result.result === "deleteSuccess") {
      retrieveAllAdData();
    }
  };

  const addressValidator = (key, address) => {
    let addressError = "";
    // if (!address) {
    //   addressError = "REQUIRED";
    // } else {
    if (address) {
      if (key === "POSTCODE") {
        if (!validatePostCode(address)) {
          addressError = "NOT_VALID";
        }
      } else if (key === "CITY") {
        if (!validateCity(address)) {
          addressError = "NOT_VALID";
        }
      }
      else {
        if (!validateAddress(address)) {
          addressError = "NOT_VALID";
        }
      }
    }
    return addressError;
  };

  useEffect(() => {
    const validator = addressValidator("CITY", city);
    // if (validator === "REQUIRED") {
    //   setCityError(t("advert:errors.city.blank"));
    // } else 
    if (validator === "NOT_VALID") {
      setCityError(t("advert:errors.city.invalid"));
    } else {
      setCityError("");
    }
  }, [city, t]);

  useEffect(() => {
    const validator = addressValidator("COUNTRY", country);
    if (validator === "REQUIRED") {
      setCountryError(t("advert:errors.country.blank"));
    } else if (validator === "NOT_VALID") {
      setCountryError(t("advert:errors.country.invalid"));
    } else {
      setCountryError("");
    }
  }, [country, t]);

  useEffect(() => {
    const validator = addressValidator("ADDRESS", address);
    if (validator === "NOT_VALID") {
      setAddressError(t("advert:errors.address.invalid"));
    } else {
      setAddressError("");
    }
  }, [address, t]);

  useEffect(() => {
    const validator = addressValidator("POSTCODE", postcode);
    // if (validator === "REQUIRED") {
    //   setPostcodeError(t("advert:errors.postcode.blank"));
    // } else 
    if (validator === "NOT_VALID") {
      setPostcodeError(t("advert:errors.postcode.invalid"));
    } else {
      setPostcodeError("");
    }
  }, [postcode, t]);

  /**
   * Section to validate ad title/description
   */

  useEffect(() => {
    if (title) {
      if (title.trim().length === 0)
        setTitleError(t("advert:errors.title.blank"));
      else setTitleError("");
    } else {
      setTitleError(t("advert:errors.title.blank"));
    }
  }, [title, t]);

  useEffect(() => {
    if (description) {
      if (description.trim().length === 0)
        setDescriptionError(t("advert:errors.description.blank"));
      else setDescriptionError("");
    } else {
      setDescriptionError(t("advert:errors.description.blank"));
    }
  }, [description, t]);

  const toggleSchedulesModal = () => setShowSchedulesModal(!showSchedulesModal);

  const sanitizeScheduleForCalendar = (schedule) => ({
    id: schedule.id,
    title: title || "",
    start: schedule.startDateTime ? new Date(schedule.startDateTime) : null,
    end: schedule.endDateTime ? new Date(schedule.endDateTime) : null,
    repeatAmount: schedule.repeatAmount ? schedule.repeatAmount : 1,
    repeat:
      !schedule.repeatType ||
        schedule.repeatType === SCHEDULE_REPEAT_TYPE_ONE_TIME
        ? "NO_REPEAT"
        : "REPEAT",
    repeatType:
      !schedule.repeatType ||
        schedule.repeatType === SCHEDULE_REPEAT_TYPE_ONE_TIME
        ? SCHEDULE_REPEAT_TYPE_DAY
        : schedule.repeatType,
    repeatWeekDays: schedule.repeatWeekDays ? schedule.repeatWeekDays : [],
    repeatMonthType:
      schedule.repeatMonthType || SCHEDULE_REPEAT_MONTH_TYPE_WEEKLY,
    repeatEnd: schedule.repeatEndDate
      ? SCHEDULE_REPEAT_END_DATE
      : SCHEDULE_REPEAT_END_NEVER,
    repeatEndDate: schedule.repeatEndDate
      ? new Date(schedule.repeatEndDate)
      : null,
  });

  const fetchSchedules = (startDateTime, endDateTime) => {
    const queryString = JSON.stringify({
      where: {
        startDateTime,
        endDateTime,
      },
    });

    return get(`${apiUrl}admin/activity/${id}/schedules?filter=${queryString}`);
  };

  const refreshSchedules = () =>
    new Promise((resolve, reject) => {
      setCalendarIsLoading(true);

      const { startDateTime, endDateTime } = retrieveDateTimes(
        calendarView,
        calendarVisibleDate
      );

      fetchSchedules(startDateTime, endDateTime)
        .then((resp: Array<unknown>) => {
          setSchedules(
            resp.map((schedule) => sanitizeScheduleForCalendar(schedule))
          );
          resolve(true);
        })
        .catch(() => reject(message.error(t`common:errors.commonError`, 5)));
    });

  const createSchedule = (newSchedule) =>
    new Promise((resolve, reject) => {
      setCalendarIsLoading(true);

      const finalSchedule = {
        startDateTime: newSchedule.start
          ? newSchedule.start.toISOString()
          : null,
        endDateTime: newSchedule.end ? newSchedule.end.toISOString() : null,
        repeatAmount:
          newSchedule.repeat === "REPEAT" ? newSchedule.repeatAmount : 0,
        repeatType:
          newSchedule.repeat === "REPEAT"
            ? newSchedule.repeatType
            : SCHEDULE_REPEAT_TYPE_ONE_TIME,
        repeatWeekDays:
          newSchedule.repeat === "REPEAT" ? newSchedule.repeatWeekDays : [],
        repeatMonthType:
          newSchedule.repeat === "REPEAT" ? newSchedule.repeatMonthType : "",
      };

      if (newSchedule.repeat === "REPEAT" && newSchedule.repeatEndDate) {
        finalSchedule["repeatEndDate"] = newSchedule.repeatEndDate;
      }

      post(`${apiUrl}admin/activity/${id}/schedules/create`, finalSchedule)
        .then(async () => {
          setSchedules([]);
          await refreshSchedules();
          setCalendarIsLoading(false);
          resolve(true);
        })
        .catch((e) => {
          console.log("ERROR >>> ", e);
          // GET ERROR FROM BE THEN SHOW IN message
          setCalendarIsLoading(false);
          if (e?.response?.data?.error?.message === "overlappedSchedule") {
            reject(
              message.error(t`common:schedule.errors.overlappingActivity`, 5)
            );
            return;
          }

          reject(message.error(t`common:errors.saveError`, 5));
        });
    });

  const updateSchedule = (scheduleToUpdate) =>
    new Promise((resolve, reject) => {
      setCalendarIsLoading(true);

      const finalSchedule = {
        startDateTime: scheduleToUpdate.start
          ? scheduleToUpdate.start.toISOString()
          : null,
        endDateTime: scheduleToUpdate.end
          ? scheduleToUpdate.end.toISOString()
          : null,
      };

      patch(
        `${apiUrl}admin/activity-schedule/${scheduleToUpdate.id}`,
        finalSchedule
      )
        .then(async () => {
          setSchedules([]);
          await refreshSchedules();
          setCalendarIsLoading(false);
          resolve(true);
        })
        .catch((e) => {
          console.log("ERROR >>> ", e);
          // GET ERROR FROM BE THEN SHOW IN message
          setCalendarIsLoading(false);
          if (e?.response?.data?.error?.message === "overlappedSchedule") {
            reject(
              message.error(t`common:schedule.errors.overlappingActivity`, 5)
            );
            return;
          }

          reject(message.error(t`common:errors.saveError`, 5));
        });
    });

  const deleteSchedule = (scheduleToDelete, allOccurrences = false) =>
    new Promise((resolve, reject) => {
      setCalendarIsLoading(true);

      post(`${apiUrl}admin/activity-schedule/${scheduleToDelete?.id}/delete`, {
        allOccurrences,
      })
        .then(async () => {
          setSchedules([]);
          await refreshSchedules();
          setCalendarIsLoading(false);
          resolve(true);
        })
        .catch((e) => {
          console.log("ERROR >>> ", e);
          // GET ERROR FROM BE THEN SHOW IN message
          setCalendarIsLoading(false);
          reject(message.error(t`common:errors.commonError`, 5));
        });
    });

  const retrieveDateTimes = (view = "week", date = new Date()) => {
    let startDateTime = date.toISOString();
    let endDateTime = date.toISOString();

    if (view === "week") {
      startDateTime = startOfWeek(date, {
        weekStartsOn: 1,
      }).toISOString();
      endDateTime = endOfWeek(date, {
        weekStartsOn: 1,
      }).toISOString();
    } else {
      startDateTime = startOfMonth(date).toISOString();
      endDateTime = endOfMonth(date).toISOString();
    }

    return {
      startDateTime,
      endDateTime,
    };
  };

  const retrieveCalendarKey = () =>
    schedules.reduce(
      (acc, { start, end, repeatType }, index) =>
        `${index}-${start}-${end}-${repeatType}`,
      ""
    );

  const onCalendarViewChanged = (val) => {
    if (!isFetchingSchedules) {
      setCalendarViewChanged(val);
      setCalendarIsLoading(true);
      isFetchingSchedules = true;

      const { startDateTime, endDateTime } = retrieveDateTimes(
        val,
        calendarVisibleDate
      );

      fetchSchedules(startDateTime, endDateTime)
        .then((resp: Array<unknown>) => {
          setSchedules(resp.map(sanitizeScheduleForCalendar));
        })
        .finally(() => {
          isFetchingSchedules = false;
          setCalendarIsLoading(false);
        });
    }
  };

  const onCalendarVisibleDateChanged = (val) => {
    if (!isFetchingSchedules) {
      setCalendarVisibleDateChanged(val);
      setCalendarIsLoading(true);
      isFetchingSchedules = true;

      const { startDateTime, endDateTime } = retrieveDateTimes(
        calendarView,
        val
      );

      fetchSchedules(startDateTime, endDateTime)
        .then((resp: Array<unknown>) => {
          setSchedules(resp.map(sanitizeScheduleForCalendar));
        })
        .finally(() => {
          isFetchingSchedules = false;
          setCalendarIsLoading(false);
        });
    }
  };

  const onExclusivityChange = (e) => {
    setIsExclusive(e.target.checked);
  };

  const onTrialOfferChange = (e) => {
    setIsTrialOffer(e.target.checked);
  };

  const onCourseOfferChange = (e) => {
    setIsCourseOffer(e.target.checked);
  };

  const onSubscriptionOfferChange = (e) => {
    setIsSubscriptionOffer(e.target.checked);
  };

  const onPackOfferChange = (e) => {
    setIsPackOffer(e.target.checked);
  };

  const onUnitOfferChange = (e) => {
    setIsUnitOffer(e.target.checked);
  };

  const handleUnitOfferPriceChange = (e) => {
    setUnitOfferPrice(e.target.value);
  };

  const handlePackOfferPriceChange = (e) => {
    setPackOfferPrice(e.target.value);
  };

  const handleMonthlySubsPriceChange = (e) => {
    setSubscriptionMonthlyPrice(e.target.value);
  };

  const handleQuarterlySubsPriceChange = (e) => {
    setSubscriptionQuarterlyPrice(e.target.value);
  };

  const handleYearlySubsPriceChange = (e) => {
    setSubscriptionYearlyPrice(e.target.value);
  };

  const handleTrialPriceChange = (e) => {
    setTrialPrice(e.target.value);
  };

  const handleCoursePriceChange = (e) => {
    setCoursePrice(e.target.value);
  };

  const handleCourseQtyChange = (e) => {
    setCourseQty(e.target.value);
  };

  const handlePackQtyChange = (e) => {
    setPackQty(e);
  };

  const onChangeForWhom = (checkedValues) => {
    setForWhom(checkedValues);
  };

  const onChangeOcassion = (checkedValues) => {
    setOccasion(checkedValues);
  };

  return (
    <MainLayout>
      <AdvertStyle />
      <Modal
        title={t`advert:attribute.schedules`}
        visible={showSchedulesModal}
        footer={null}
        onCancel={toggleSchedulesModal}
        centered
        closable
        maskClosable
        bodyStyle={{
          maxHeight: "calc(100vh - 10rem)",
          height: "auto",
          overflowY: "scroll",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1rem",
          minWidth: "16rem",
          width: "100%",
          padding: "3.5rem 1.5rem 1.5rem",
        }}
        style={{
          width: "16rem",
          maxWidth: "100%",
        }}
        className="advert-custom-calendar-modal"
      >
        <div className="create-schedule-btn-container">
          <TextButton
            variant="yellow"
            onClick={() => newScheduleRef?.current?.click()}
            width="14rem"
            rounded
          >
            {t`advert:createAnActivitySession`}
          </TextButton>
        </div>
        <CustomCalendar
          key={retrieveCalendarKey()}
          editable={true}
          events={schedules}
          setEvents={setSchedules}
          eventTitle={title}
          newEventRef={newScheduleRef}
          handleCreateEvent={createSchedule}
          handleUpdateEvent={updateSchedule}
          handleDeleteEvent={deleteSchedule}
          handleViewChanged={onCalendarViewChanged}
          handleOnNavigate={onCalendarVisibleDateChanged}
          loading={calendarIsLoading}
          dateInView={calendarVisibleDate}
          calendarView={calendarView}
        />
      </Modal>
      <Space direction="horizontal" size={16} className="page-header-container">
        <TextButton
          variant="salmon"
          rounded
          height="2.5rem"
          width="2.5rem"
          onClick={() => history.push("/annonces")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6.667"
            height="10.63"
            viewBox="0 0 6.667 10.63"
          >
            <g
              id="Groupe_2445"
              data-name="Groupe 2445"
              transform="translate(-89.525 -140.615)"
            >
              <path
                id="Tracé_1256"
                data-name="Tracé 1256"
                d="M1298.171,1464.772l-3.848,4.062,3.848,3.74"
                transform="translate(-1203.394 -1322.743)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </g>
          </svg>
        </TextButton>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "350px",
          }}
        >
          <TextButton
            variant="salmon"
            rounded
            width="10.625rem"
            onClick={handleCancelChanges}
          >{t`advert:button.cancel`}</TextButton>
          <TextButton
            variant="salmon"
            type="outlined"
            rounded
            width="10.625rem"
            onClick={handleSaveAdvert}
          >{t`advert:button.save`}</TextButton>
        </div>
      </Space>
      <AdvertInfoBanner advert={advertSummary} />

      <Row gutter={[8, 8]} className="advert-info-container">
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <div className="advert-card-small">
            <Row>
              <h2 className="advert-box-header">{t`advert:localisationSection.title`}</h2>
            </Row>
            <Row className="localisation-row-spacing no-bottom-margin">
              <Form.Item
                required
                help={cityError}
                rules={[{ required: true }]}
                validateStatus={cityError && "error"}
                // hasFeedback
                style={{
                  width: "100%",
                  paddingBottom: 0,
                  marginBottom: 0,
                }}
              >
                <Input
                  placeholder={t("advert:localisationSection.city")}
                  value={city}
                  onChange={handleActivityAddressCityChange}
                />
              </Form.Item>
            </Row>
            <Row className="localisation-row-spacing no-bottom-margin">
              <Form.Item
                // required
                // help={countryError}
                // rules={[{ required: true }]}
                // validateStatus={countryError && "error"}
                // hasFeedback
                style={{
                  width: "100%",
                  // paddingBottom: 0,
                  // marginBottom: 0,
                }}
              >
                <Select
                  onChange={(value) =>
                    handleActivityAddressCountryChange(value)
                  }
                  value={country}
                  className="select-style"
                  style={{ width: "100%" }}
                >
                  {COUNTRIES?.map((item) => (
                    <Option
                      style={{ textAlign: "left" }}
                      key={item.id}
                      value={item.alpha2}
                    >
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Row>
            <Row className="localisation-row-spacing no-bottom-margin">
              <Form.Item
                help={addressError}
                validateStatus={addressError && "error"}
                hasFeedback
                style={{
                  width: "100%",
                  paddingBottom: 0,
                  marginBottom: 0,
                }}
              >
                <Input
                  placeholder={t("advert:localisationSection.address")}
                  value={address}
                  onChange={handleActivityAddressChange}
                />
              </Form.Item>
            </Row>
          </div>
          <div className="advert-card personal-profile-card">
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25.835"
              height="28.082"
              viewBox="0 0 25.835 28.082"
              className="personal-profile-icon blurred-circle"
            >
              <defs>
                <filter
                  id="Ellipse_429"
                  x="0"
                  y="0"
                  width="25.835"
                  height="28.082"
                  filterUnits="userSpaceOnUse"
                >
                  <feOffset />
                  <feGaussianBlur stdDeviation="1.5" result="blur" />
                  <feFlood floodOpacity="0.161" />
                  <feComposite operator="in" in2="blur" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <g
                transform="matrix(1, 0, 0, 1, 0, 0)"
                filter="url(#Ellipse_429)"
              >
                <ellipse
                  id="Ellipse_429-2"
                  data-name="Ellipse 429"
                  cx="8.418"
                  cy="9.541"
                  rx="8.418"
                  ry="9.541"
                  transform="translate(4.5 4.5)"
                  fill="#fff"
                />
              </g>
            </svg> */}
            <Row gutter={[0, 16]} className="form-container">
              <Col span={8} className="avatar-center">
                <Avatar
                  size={76}
                  src={user && user.userImage ? user.userImage : null}
                  className="user-avatar"
                  icon={<UserOutlined />}
                />
              </Col>
              <Col span={16}>
                <h2>{t`advert:userProfile.personalProfile`}</h2>
                <p className="member">
                  {t("advert:userProfile.memberSince", {
                    date: user?.createdAt
                      ? format(new Date(user?.createdAt), "dd/MM/yyyy")
                      : "",
                  })}
                </p>
                <div className="pro-tag">PRO</div>
              </Col>
            </Row>
            <hr />
            <Row gutter={[0, 16]} className="form-container">
              <Form
                layout="vertical"
                className="form-vertical"
                style={{
                  paddingLeft: 10,
                  paddingRight: 10,
                  marginTop: 0,
                  width: "100%",
                }}
              >
                <Form.Item
                  label={t("advert:userProfile.email")}
                  className="text-label form-text-box"
                >
                  <Input
                    placeholder={t("advert:userProfile.email")}
                    value={(user && user.email) || ""}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:userProfile.firstname")}
                  className="text-label form-text-box"
                >
                  <Input
                    placeholder={t("advert:userProfile.firstname")}
                    value={(user && user.firstname) || ""}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:userProfile.name")}
                  className="text-label form-text-box"
                >
                  <Input
                    placeholder={t("advert:userProfile.name")}
                    value={(user && user.name) || ""}
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:userProfile.displayName")}
                  className="text-label form-text-box"
                >
                  <Input
                    placeholder={t("advert:userProfile.displayName")}
                    value={(user && user.firstname) || ""}
                    disabled={true}
                  />
                </Form.Item>
              </Form>
            </Row>
          </div>
        </Col>

        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <div className="advert-card-small">
            <Row>
              <h2 className="advert-box-header">{t`advert:mediaTitle`}</h2>
            </Row>
            <Row
              className="localisation-row-spacing"
              style={{ marginBottom: 0 }}
            >
              <div style={{ width: "100%" }}>
                <Swiper
                  className="swiper-container"
                  spaceBetween={30}
                  slidesPerView={1}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                  pagination={{
                    clickable: true,
                  }}
                >
                  {assetImages.map(renderGalleryItem)}
                </Swiper>
              </div>
            </Row>

            <Row justify="center">
              <FileUploader
                acceptMultipleFiles={false}
                showUploadList={false}
                maxFileSize={5 * 1024 * 1024}
                uploadFile={handleCategoryImageUpload}
                className="category-image-uploader"
                disabled={assetImages && assetImages.length >= 5}
              >
                <IconButton
                  className="main-carousel-arrow main-carousel-arrow-left"
                  aria-label="previous"
                  disabled={assetImages && assetImages.length >= 5}
                >
                  <CameraOutlined />
                </IconButton>
              </FileUploader>
            </Row>
          </div>
          <div className="advert-card-small">
            <Row>
              <h2 className="advert-box-header">{t`advert:statusSection.title`}</h2>
            </Row>
            <Row className="localisation-row-spacing">
              <Col span={8} className="status-switch-box">
                <Switch
                  checked={isReviewed}
                  onChange={(e) => onHandleActivityReviewed(e)}
                />
              </Col>
              <Col span={16}>
                <p className="status-box-text">
                  {t`advert:statusSection.review`}
                </p>
              </Col>
            </Row>
            <Row className="localisation-row-spacing">
              <Col span={8} className="status-switch-box">
                <Switch
                  checked={isPublished}
                  onChange={(e) => onHandleActivityStatus(e)}
                />
              </Col>
              <Col span={16}>
                <p className="status-box-text">
                  {isPublished
                    ? t`advert:statusSection.online`
                    : t`advert:statusSection.offline`}
                </p>
              </Col>
            </Row>
            <Row className="localisation-row-spacing">
              <Col span={8} className="status-switch-box">
                <Switch
                  checked={isPopular}
                  onChange={(e) => onHandleActivityPopular(e)}
                />
              </Col>
              <Col span={16}>
                <p className="status-box-text">{t`advert:statusSection.mostPopular`}</p>
              </Col>
            </Row>
          </div>
          <div className="advert-card-small">
            <Row>
              <h2 className="advert-box-header">{ }</h2>
            </Row>
            <Row>
              <Form
                layout="vertical"
                className="form-vertical full-width"
                style={{ padding: 10 }}
              >
                <Form.Item label={t("advert:category")} className="text-label">
                  <Select
                    onChange={(value) => handleOnChangeCategory(value)}
                    value={selectedCategoryId}
                    className="select-style"
                  >
                    {listCategories?.map((item) => (
                      <Option
                        style={{ textAlign: "center" }}
                        key={item.id}
                        value={item.id}
                      >
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t("advert:transactionType")}
                  className="text-label"
                >
                  <Select
                    onChange={(value) => handleOnChangeTransactionType(value)}
                    value={selectedScheduleType}
                    className="select-style"
                  >
                    {TRANSACTION_SCHEDULE_TYPE?.map((item) => (
                      <Option
                        style={{ textAlign: "center" }}
                        key={item.key}
                        value={item.value}
                      >
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Form>
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <div
            className="advert-card"
            // style={{ maxHeight: "94vh", overflowY: "scroll" }}
          >
            <Row>
              <h2 className="advert-box-header">{t`advert:attribute.title`}</h2>
            </Row>
            <Row>
              <Form
                layout="vertical"
                className="form-vertical"
                style={{ padding: 10, width: "100%" }}
              >
                {/* <Form.Item
                    label={t("advert:category")}
                    className="text-label"
                    style={{width: "100%"}}
                  >
                    <Checkbox.Group
                      options={ACTIVITY_MOMENT}
                    />
                  </Form.Item> */}
                {/* <Form.Item
                  label={t("advert:attribute.offerType")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder={t("advert:attribute.offerType")}
                    style={{ width: "100%" }}
                    value={offerType}
                  />
                </Form.Item> */}
                <Form.Item
                  label={t("advert:attribute.numberOfCourse")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder={t("advert:attribute.numberOfCourse")}
                    style={{ width: "100%" }}
                    value={coursesCount}
                    // onChange={handleCategoryNameChange}
                    readOnly={true}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.schedules")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <TextButton
                    variant="salmon"
                    type="outlined"
                    rounded
                    width="100%"
                    onClick={toggleSchedulesModal}
                  >
                    {t`advert:button.viewSchedules`}
                  </TextButton>
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.activityPlace")}
                  className="text-label"
                  help={cityError}
                  rules={[{ required: true }]}
                  validateStatus={cityError && "error"}
                  hasFeedback
                  style={{
                    width: "100%",
                    paddingBottom: 0,
                    marginBottom: 0,
                  }}
                >
                  <Input
                    placeholder={t("advert:attribute.activityPlace")}
                    style={{ width: "100%" }}
                    value={city}
                    onChange={handleActivityAddressCityChange}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.postcode")}
                  className="text-label"
                  help={postcodeError}
                  rules={[{ required: true }]}
                  validateStatus={postcodeError && "error"}
                  hasFeedback
                  style={{
                    width: "100%",
                    paddingBottom: 0,
                    marginBottom: 0,
                  }}
                >
                  <Input
                    placeholder={t("advert:attribute.postcode")}
                    style={{ width: "100%" }}
                    value={postcode}
                    onChange={handleActivityAddressPostcodeChange}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.visioLink")}
                  className="text-label"
                >
                  <Input
                    placeholder={t("advert:attribute.visioLink")}
                    style={{ width: "100%" }}
                    value={visioLink}
                    onChange={handleVisioLinkChange}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.exclusive")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Checkbox
                    checked={isExclusive}
                    onChange={onExclusivityChange}
                  >
                    {isExclusive ? t("common:yes") : t("common:no")}
                  </Checkbox>
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.aboutActivity")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <TextArea
                    placeholder={t("advert:attribute.aboutActivity")}
                    style={{ width: "100%" }}
                    value={hostDescription}
                    onChange={handleHostDescriptionChange}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.materialProvided")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <TextArea
                    placeholder={t("advert:attribute.materialProvided")}
                    style={{ width: "100%" }}
                    value={materialProvided}
                    onChange={handleMaterialProvidedChange}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.materialRequired")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <TextArea
                    placeholder={t("advert:attribute.materialRequired")}
                    style={{ width: "100%" }}
                    value={materialRequired}
                    onChange={handleMaterialRequiredChange}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.youTube")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder={t("advert:attribute.youTube")}
                    style={{ width: "100%" }}
                    value={youtube}
                    onChange={handleYoutubeChange}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.vimeo")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Input
                    placeholder={t("advert:attribute.vimeo")}
                    style={{ width: "100%" }}
                    value={vimeo}
                    onChange={handleVimeoChange}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.duration")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    min={0}
                    max={9999999999}
                    placeholder={t("advert:attribute.duration")}
                    style={{ width: "100%" }}
                    value={duration}
                  onChange={handleDurationChange}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.requiredLevel")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Radio.Group
                    name="level"
                    options={LEVELS}
                    value={level}
                    onChange={handleLevelChange}
                  ></Radio.Group>
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.forWhom")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Checkbox.Group
                    options={FOR_WHOM_OPTIONS}
                    defaultValue={forWhom}
                    onChange={onChangeForWhom}
                    value={forWhom}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:attribute.occasion")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Checkbox.Group
                    options={OCCASION_OPTIONS}
                    defaultValue={occasion}
                    onChange={onChangeOcassion}
                    value={occasion}
                  />
                </Form.Item>
              </Form>
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <div
            className="advert-card"
            // style={{ maxHeight: "95vh", overflowY: "scroll" }}
          >
            <Row>
              <h2 className="advert-box-header">{t`advert:description.title`}</h2>
            </Row>
            <Row>
              <Form
                layout="vertical"
                className="form-vertical"
                style={{ padding: 10, width: "100%" }}
              >
                <Form.Item
                  label={t("advert:description.activityTitle")}
                  className="text-label"
                  help={titleError}
                  rules={[{ required: true }]}
                  validateStatus={titleError && "error"}
                  hasFeedback
                  style={{
                    width: "100%",
                    paddingBottom: 0,
                    marginBottom: 0,
                  }}
                >
                  <Input
                    placeholder={t("advert:description.activityTitle")}
                    style={{ width: "100%" }}
                    value={title}
                    onChange={handleAdTitleChange}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:description.title")}
                  className="text-label"
                  help={descriptionError}
                  rules={[{ required: true }]}
                  validateStatus={descriptionError && "error"}
                  hasFeedback
                  style={{
                    width: "100%",
                    paddingBottom: 0,
                    marginBottom: 0,
                  }}
                >
                  <TextArea
                    placeholder={t("advert:description.title")}
                    style={{ width: "100%", height: "270px" }}
                    value={description}
                    onChange={handleAdDescriptionChange}
                  />
                </Form.Item>
                <Checkbox checked={isUnitOffert} onChange={onUnitOfferChange}>
                  {t("advert:offerTypes.unitSession")}
                </Checkbox>
                <Form.Item
                  label={t("advert:description.effectivePrice")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    min={0}
                    max={9999999999}
                    placeholder={t("advert:description.effectivePrice")}
                    style={{ width: "100%" }}
                    suffix="EUR"
                    value={unitOfferPrice}
                    onChange={handleUnitOfferPriceChange}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:description.placesAvailable")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <InputNumber
                    placeholder={t("advert:description.placesAvailable")}
                    style={{ width: "100%" }}
                    value={unitOfferAvailablePlace}
                    readOnly={true}
                  />
                </Form.Item>

                <div className="align-offers">
                  <Checkbox
                    checked={isPackOffer}
                    onChange={onPackOfferChange}
                  >
                    {t("advert:offerTypes.packSession")}
                  </Checkbox>
                  <IconButton
                    aria-label="add"
                    style={{ width: "40px", height: "40px" }}
                    disabled={!isPackOffer}
                    onClick={() => addPackOffersFormFields()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="30"
                      viewBox="0 0 16 30"
                    >
                      <text
                        id="_"
                        data-name="+"
                        transform="translate(8 30)"
                        fill="#f7a58d"
                        stroke="#f7a58d"
                        strokeLinecap="round"
                        strokeWidth="1"
                        fontSize="30"
                        fontFamily="GlacialIndifference-Bold, Glacial Indifference"
                        fontWeight="700"
                      >
                        <tspan x="-6.6" y="-6.6">
                          +
                        </tspan>
                      </text>
                    </svg>
                  </IconButton>
                </div>

                <div className="align-offers">
                  <Form.Item
                    label={t("advert:description.effectivePrice")}
                    className="text-label"
                    style={{ width: "100%" }}
                  >
                    <Input
                      type="number"
                      min={0}
                      max={9999999999}
                      placeholder={t("advert:description.effectivePrice")}
                      style={{ width: "100%" }}
                      suffix="EUR"
                      value={packOfferPrice}
                      onChange={handlePackOfferPriceChange}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t("advert:offerTypes.numberOfSessions")}
                    className="text-label"
                    style={{ width: "100%" }}
                  >
                    <InputNumber
                      type="number"
                      min={0}
                      max={9999999999}
                      placeholder={t("advert:offerTypes.numberOfSessions")}
                      style={{ width: "100%" }}
                      value={packQty}
                      onChange={handlePackQtyChange}
                    />
                  </Form.Item>
                </div>
                {isPackOffer && renderPackOffers()}
                <Checkbox
                  checked={isSubscriptionOffer}
                  onChange={onSubscriptionOfferChange}
                >
                  {t("advert:offerTypes.subscriptionSession")}
                </Checkbox>
                <Form.Item
                  label={t("advert:offerTypes.effectiveMonthlyPrice")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    min={0}
                    max={9999999999}
                    placeholder={t("advert:offerTypes.effectiveMonthlyPrice")}
                    style={{ width: "100%" }}
                    suffix="EUR"
                    value={subscriptionMonthlyPrice}
                    onChange={handleMonthlySubsPriceChange}
                  />
                </Form.Item>

                <Form.Item
                  label={t("advert:offerTypes.effectiveQuarterlyPrice")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    min={0}
                    max={9999999999}
                    placeholder={t("advert:offerTypes.effectiveQuarterlyPrice")}
                    style={{ width: "100%" }}
                    suffix="EUR"
                    value={subscriptionQuarterlyPrice}
                    onChange={handleQuarterlySubsPriceChange}
                  />
                </Form.Item>

                <Form.Item
                  label={t("advert:offerTypes.effectiveYearlyPrice")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    min={0}
                    max={9999999999}
                    placeholder={t("advert:offerTypes.effectiveYearlyPrice")}
                    style={{ width: "100%" }}
                    suffix="EUR"
                    value={subscriptionYearlyPrice}
                    onChange={handleYearlySubsPriceChange}
                  />
                </Form.Item>

                <div className="align-offers">
                  <Checkbox
                    checked={isCourseOffer}
                    onChange={onCourseOfferChange}
                  >
                    {t("advert:offerTypes.courseSession")}
                  </Checkbox>
                  <IconButton
                    aria-label="add"
                    style={{ width: "40px", height: "40px" }}
                    disabled={!isCourseOffer}
                    onClick={() => addPackCourseFormFields()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="30"
                      viewBox="0 0 16 30"
                    >
                      <text
                        id="_"
                        data-name="+"
                        transform="translate(8 30)"
                        fill="#f7a58d"
                        stroke="#f7a58d"
                        strokeLinecap="round"
                        strokeWidth="1"
                        fontSize="30"
                        fontFamily="GlacialIndifference-Bold, Glacial Indifference"
                        fontWeight="700"
                      >
                        <tspan x="-6.6" y="-6.6">
                          +
                        </tspan>
                      </text>
                    </svg>
                  </IconButton>
                </div>

                <div className="align-offers">
                  <Form.Item
                    label={t("advert:description.effectivePrice")}
                    className="text-label"
                    style={{ width: "100%" }}
                  >
                    <Input
                      type="number"
                      min={0}
                      max={9999999999}
                      placeholder={t("advert:description.effectivePrice")}
                      style={{ width: "100%" }}
                      suffix="EUR"
                      value={coursePrice}
                      onChange={handleCoursePriceChange}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t("advert:offerTypes.numberOfSessions")}
                    className="text-label"
                    style={{ width: "100%" }}
                  >
                    <Input
                      type="number"
                      min={0}
                      max={9999999999}
                      placeholder={t("advert:offerTypes.numberOfSessions")}
                      style={{ width: "100%" }}
                      value={courseQty}
                      onChange={handleCourseQtyChange}
                    />
                  </Form.Item>
                </div>
                {isCourseOffer && renderCourseOffers()}
                <Checkbox checked={isTrialOffer} onChange={onTrialOfferChange}>
                  {t("advert:offerTypes.trialSession")}
                </Checkbox>
                <Form.Item
                  label={t("advert:description.effectivePrice")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <Input
                    type="number"
                    placeholder={t("advert:description.effectivePrice")}
                    style={{ width: "100%" }}
                    suffix="EUR"
                    value={trialPrice}
                    min={0}
                    max={9999999999}
                    onChange={handleTrialPriceChange}
                  />
                </Form.Item>
                <Form.Item
                  label={t("advert:description.placesAvailable")}
                  className="text-label"
                  style={{ width: "100%" }}
                >
                  <InputNumber
                    placeholder={t("advert:description.placesAvailable")}
                    style={{ width: "100%" }}
                    value={trialAvailablePlace}
                    readOnly={true}
                  />
                </Form.Item>
              </Form>
            </Row>
          </div>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default AdvertDetail;
