import MainLayout from "layouts/main"
import { Card, TextButton } from "components";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import ProTransactionStyle from "styles/ProTransactionStyle";
const paymentConfirmationResult = new URLSearchParams(window.location.search).get("result");

const CheckOut = () => {

  const { t } = useTranslation(["common", "protransaction"]);
  const { colors } = useTheme();
  let history = useHistory();

  const goToTransactionPage = () => {
    history.push('/pro-transactions');
  }

  return (
    <MainLayout>
      <ProTransactionStyle />
      <Card>
          <h1 className="page-title">{t`protransaction:checkoutPage.title`}</h1>
          <div className="confirmation-checkout">
            <Card className="card-header">
              <p className="card-text">
                {paymentConfirmationResult === 'success' ? t`protransaction:checkoutPage.paymentSuccess` : t`protransaction:checkoutPage.paymentFail`}
              </p>
              <TextButton
                variant={"darkBlue"}
                onClick={goToTransactionPage}
                rounded
              >
                {t`protransaction:checkoutPage.goToTransactionPage`}
              </TextButton>
            </Card>
          </div>
          
      
      </Card>
    </MainLayout>
  )
}
export default CheckOut;
