import { get, patch, post } from "./axios-service";

export function getFeaturedDetail(id) {
  return get(`headline/${id}`);
}

export function createFeaturedItem(requestBody) {
  return post(`headline`, requestBody);
}

export function updateFeaturedItem(requestBody) {
  return post(`headline/update`, requestBody);
}

export function patchFeaturedItem(id, requestBody) {
  return patch(`headline/${id}`, requestBody);
}

export function getFeaturedList(queryString="") {
  return get(`headlines?filter=${queryString}`);
}

export function uploadFeaturedImage(requestBody, fileType) {
  const headers = {
    "Content-Type": "multipart/form-data",
    "Mime-Type": fileType,
  };

  return post(`admin/headline/upload-picture`, requestBody, headers);
}
