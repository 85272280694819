import { del, get, patch, post } from "./axios-service";
const apiUrl = process.env.REACT_APP_API_URL;


export async function getCategories() {
  return get(`categories`);
}

///categories

export const createCategory = async (requestBody) => {
  try {
    const response = await  post(`${apiUrl}categories`, requestBody);
    return response;
  } catch (error) {
    throw error;
  }
}

///category/{id}

export const updateCategory = async (id, requestBody) => {
  try {
    const response = await patch(`category/${id}`, requestBody);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateListOrdering = async (requestBody) => {
  try {
    const response = await patch(`categories`, requestBody);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateCategoryWithRootParent = async (id, requestBody) => {
  try {
    const response = await post(`category/${id}/update`, requestBody);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getCategoryList() {
  const response = await get(`categories/children`);
  return response;
}

export function getCategoriesValues() {
  return get(`categories/signup-pro`);
}

export function deleteCategoryAsset(assetId) {
  return del(`category-asset/${assetId}`);
}