import {
  Space,
  Col,
  Row,
  Card,
  Checkbox,
  Divider,
  message,
  Avatar,
} from "antd";
import {
  RatingInfoBanner,
  TextButton,
} from "components";
import MainLayout from "layouts/main";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RatingStyle from "styles/RatingStyle";
import { getAdminSummaryById, getRatingById, updateRating } from "services/rating";
import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import {
  format,
} from "date-fns";
import { retrieveActivityCoverPicture } from "services/advert";
import { getUser } from "services/user";
import uuid from "react-uuid";
import { Rating } from "@material-ui/lab";
import { SvgIcon } from "@material-ui/core";
import { StarIcon } from "components/icons";
import styled from "styled-components";
import SvgRatingTickIcon from "components/icons/RatingTickIcon";
import { fullImageUrl } from "utils";

export const CustomMuiStarIcon = styled(({ ...rest }) => (
  <SvgIcon>
    <StarIcon {...rest} />
  </SvgIcon>
))``;

const RatingDetail = () => {

  let history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation(["common", "rating"]);
  const [ratingSummary, setRatingSummary] = useState([]);
  const [ratingInfo, setRatingInfo] = useState(null);
  const [rating, setRating] = useState(null);
  const [activityPicture, setActivityPicture] = useState(null);
  const [seller, setSeller] = useState(null);
  const [proPageVisible, setProPageVisible] = useState(false);
  const [activityPageVisible, setActivityPageVisible] = useState(false);
  const [homePageVisible, setHomePageVisible] = useState(false);
  const [isPagesEnabled, setIsPagesEnabled] = useState(false);
  const [rsKey, setRsKey] = useState("");
  const [ratingNote, setRatingNote] = useState(null);

  useEffect(() => {
    async function fetchAdvert() {

      const response = await getAdminSummaryById(id);
      setRatingInfo(response);
      const arr = [];
      arr.push(response);
      setRatingSummary(arr);
      const rate = await getRatingById(id);
      setRating(rate);
      if (rate) {
        setHomePageVisible(rate.homePageVisible);
        setActivityPageVisible(rate.activityPageVisible);
        setProPageVisible(rate.proPageVisible);
        setIsPagesEnabled(rate.isVisible);
        const note = rate.note % 2 === 0 ? Number(rate.note).toFixed(0) : Number(rate.note).toFixed(1);
        setRatingNote(note);
      }

      if (response && response.activityId) {
        const actPicture = await retrieveActivityCoverPicture(response.activityId);
        setActivityPicture(actPicture);
      }
      if (response && response.sellerId) {
        const userResp = await getUser(response.sellerId);
        setSeller(userResp);
      }
    }
    fetchAdvert();
  }, [id]);

  const updateRatingInformation = (visible) => {

    let payload = null;

    if (visible) {
      setIsPagesEnabled(true);
      payload = {
        isVisible: visible,
      }
    } else {
      setIsPagesEnabled(false);
      setHomePageVisible(false);
      setProPageVisible(false);
      setActivityPageVisible(false);

      payload = {
        isVisible: visible,
        proPageVisible: false,
        homePageVisible: false,
        activityPageVisible: false
      }
    }

    let rs = ratingSummary;
    rs[0].isVisible = visible;
    rs[0].status = handleStatusChange(visible);
    setRatingSummary(rs);
    setRsKey(uuid());
    updateRating(id, payload);
    message.success(t`common:message.success`, 5);
  };

  const handleStatusChange = (checked) => {
    if (checked === true) {
      return "APPROVED"
    } else {
      return "REJECTED"
    }
  }

  const onProPageChange = (e) => {
    setProPageVisible(e.target.checked);
    updateRating(id, { proPageVisible: e.target.checked });
  };

  const onHomePageChange = (e) => {
    setHomePageVisible(e.target.checked);
    updateRating(id, { homePageVisible: e.target.checked });
  };

  const onActivityPageChange = (e) => {
    setActivityPageVisible(e.target.checked);
    updateRating(id, { activityPageVisible: e.target.checked });
  };


  return (
    <MainLayout>
      <RatingStyle />

      <Space direction="horizontal" size={16} className="page-header-container">
        <TextButton
          variant="darkBlue"
          rounded
          height="2.5rem"
          width="2.5rem"
          onClick={() => history.push("/evaluations")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="6.667"
            height="10.63"
            viewBox="0 0 6.667 10.63"
          >
            <g
              id="Groupe_2445"
              data-name="Groupe 2445"
              transform="translate(-89.525 -140.615)"
            >
              <path
                id="Tracé_1256"
                data-name="Tracé 1256"
                d="M1298.171,1464.772l-3.848,4.062,3.848,3.74"
                transform="translate(-1203.394 -1322.743)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeWidth="2"
              />
            </g>
          </svg>
        </TextButton>
      </Space>
      <RatingInfoBanner rating={ratingSummary} key={rsKey} />
      <div style={{ margin: "40px" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
            <div className="card-container">
              <Card style={{ padding: 0 }} className="custom-card">
                <div className="card-header-custom">
                  <div className="text-poz">
                    <p className="header-text">
                      {`${ratingInfo?.buyerFirstname} ${ratingInfo?.buyerLastname}`}
                    </p>
                    <span className="sub-header-text">{`${t`rating:memberSince`} ${ratingInfo?.buyerCreatedAt
                      ? format(
                        new Date(ratingInfo?.buyerCreatedAt),
                        "dd/MM/yyyy"
                      )
                      : ""
                      }`}</span>
                  </div>
                  <InfoCircleOutlined style={{ color: "white" }} />
                </div>
                <div className="card-body-custom">
                  <div className="rating-container">
                    <p className="rating-text">{`${ratingNote}/5`}</p>
                    <Rating
                      name="star-rating"
                      defaultValue={ratingNote}
                      value={ratingNote}
                      precision={0.5}
                      max={5}
                      emptyIcon={<CustomMuiStarIcon mode={0} />}
                      icon={<CustomMuiStarIcon mode={1} />}
                      readOnly={true}
                      className="star-rating-container"
                    />
                  </div>
                  <Divider type="vertical" className="vertical-divider" />
                  <div className="comments-container">
                    <p className="comments-text">{rating?.comments}</p>
                    <p className="comments-meta-text">
                      {`${ratingInfo?.buyerFirstname} ${ratingInfo?.buyerLastname
                        } . ${rating?.createdAt
                          ? format(new Date(rating?.createdAt), "dd MMMM yyyy")
                          : ""
                        }`}
                      {isPagesEnabled && (
                        <div>
                          <SvgRatingTickIcon />
                          <SvgRatingTickIcon />
                        </div>
                      )}
                    </p>
                  </div>
                </div>
              </Card>
              <div className="button-row">
                <TextButton
                  variant="darkBlue"
                  width="14rem"
                  rounded
                  onClick={() => updateRatingInformation(true)}
                >
                  {t`rating:button.validate`}
                </TextButton>
                <TextButton
                  variant="darkBlue"
                  type="outlined"
                  width="14rem"
                  rounded
                  onClick={() => updateRatingInformation(false)}
                >
                  {t`rating:button.block`}
                </TextButton>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <div className="activity-panel">
              <h2 className="page-title">{t`rating:moreInfo`}</h2>
              <Row style={{ marginTop: "10px" }}>
                <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
                  <img
                    src={`${process.env.REACT_APP_S3_CDN_URL}${activityPicture?.url}`}
                    alt="cover"
                    className="activity-picture"
                  />
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={16}
                  xl={16}
                  xxl={16}
                  className="activity-panel-description"
                >
                  <p className="page-title">{ratingInfo?.activityName}</p>
                  <div className="seller-section">
                    <Avatar
                      size={64}
                      src={seller ? fullImageUrl(seller?.imageUrls) : null}
                      className="user-avatar"
                      icon={<UserOutlined />}
                    />
                    <p className="seller-name">{`${seller?.firstname} ${seller?.name}`}</p>
                  </div>
                  <TextButton
                    variant="salmon"
                    width="14rem"
                    rounded
                    onClick={() =>
                      history.push(`/annonces/${ratingInfo?.activityId || ""}`)
                    }
                  >
                    {t`rating:button.seeAdvert`}
                  </TextButton>
                </Col>
              </Row>
              <h2 className="page-title">{t`rating:makePublic`}</h2>
              <div className="check-box-styles">
                <Checkbox
                  checked={proPageVisible}
                  onChange={onProPageChange}
                  className="check-box-text"
                  disabled={!isPagesEnabled}
                >
                  {t("rating:publicFilters.proPage")}
                </Checkbox>
                <Checkbox
                  checked={activityPageVisible}
                  onChange={onActivityPageChange}
                  disabled={!isPagesEnabled}
                  className="check-box-text"
                >
                  {t("rating:publicFilters.activityPage")}
                </Checkbox>
                <Checkbox
                  checked={homePageVisible}
                  onChange={onHomePageChange}
                  disabled={!isPagesEnabled}
                  className="check-box-text"
                >
                  {t("rating:publicFilters.homePage")}
                </Checkbox>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </MainLayout >
  );
};

export default RatingDetail;
