import { ParameterHeader } from "components";
import SvgAnnoncesIcon from "components/icons/AnnoncesIcon";
import MainLayout from "layouts/main";
import { useTranslation } from "react-i18next";


const ParametreAnnonces = () => {
  const { t } = useTranslation(["common", "parameters"]);

  return (
    <MainLayout>
      <ParameterHeader 
        icon={<SvgAnnoncesIcon fill={"#fff"} stroke={"#fff"} />} 
        title={t`parameters:category.title`} 
        subTitle={""} 
        goBack={"/parametres"} 
        btnClick={"/parametres/annonce/categories"}
        btnText={t`parameters:category.subTitle`}
      />
    </MainLayout>

  );
}

export default ParametreAnnonces;
