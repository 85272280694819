import * as React from "react";

function SvgEmailParamIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.824"
      height="29.02"
      viewBox="0 0 34.824 29.02"
    >
      <g
        id="Group_2647"
        data-name="Group 2647"
        transform="translate(-114.314 -670.144)"
      >
        <path
          id="Path_1449"
          data-name="Path 1449"
          d="M-237.772,385.606a.751.751,0,0,0-.788.707.726.726,0,0,0,.788.707h12.645a.751.751,0,0,0,.788-.707.726.726,0,0,0-.788-.707h-5.555v-3.5H-218.7a2.69,2.69,0,0,0,2.819-2.53v-8.111a.749.749,0,0,0-.788-.707.726.726,0,0,0-.788.707V376.9h-28.067V364.437a1.189,1.189,0,0,1,1.244-1.116h12.271a.751.751,0,0,0,.788-.707.749.749,0,0,0-.788-.707h-12.271a2.69,2.69,0,0,0-2.819,2.53v15.143a2.688,2.688,0,0,0,2.819,2.53H-232.3v3.5Zm-7.711-6.027v-1.3h28.067v1.3a1.189,1.189,0,0,1-1.244,1.116H-244.2A1.2,1.2,0,0,1-245.483,379.579Zm15.256-19.012V367.3a2.688,2.688,0,0,0,2.819,2.53h.829v2.53a.653.653,0,0,0,.456.632.838.838,0,0,0,.29.074.933.933,0,0,0,.539-.186l3.524-3.051h6.675a2.69,2.69,0,0,0,2.819-2.53V360.53a2.688,2.688,0,0,0-2.819-2.53h-12.354A2.706,2.706,0,0,0-230.227,360.567Zm16.417,0V367.3a1.189,1.189,0,0,1-1.244,1.116h-7.006a.933.933,0,0,0-.539.186l-2.446,2.121v-1.563a.749.749,0,0,0-.788-.707h-1.575a1.188,1.188,0,0,1-1.244-1.116v-6.771a1.189,1.189,0,0,1,1.244-1.116h12.354A1.165,1.165,0,0,1-213.81,360.567Z"
          transform="translate(361.414 312.144)"
          fill="#fff"
        />
      </g>
    </svg>
  );
}

export default SvgEmailParamIcon;
