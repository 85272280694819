import { get, patch, post } from "./axios-service";

export async function getAdvertList(queryString) {

  let params = '?filter=' + JSON.stringify(queryString);

  const response = await get(`admin/activities${params}`);
  return response;
}

export async function getAdvertSummaryById(id) {

  const response = await get(`admin/activity/${id}/summary`);
  return response;
}

export const updateActivity = async (id, payload) => {

  try {
    const response = await patch(`admin/activity/${id}`, payload);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getAdvertById(id) {

  const response = await get(`admin/activity/${id}`);
  return response;
}

export const updateAdvert = async (requestBody) => {
  try {
    const response = await  post("admin/activities/upsert", requestBody);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function exportAdverts() {
  const result = {
    response: null,
    error: null
  };

  try {
    const response = await get('/admin/activities/export');
    result.response = response;
    return result;
  } catch (error) {
    result.error = error;
    return result;
  }
}

export async function deleteActivityPicture(id, userId) {
  try {
    const response = await post(`admin/activity/delete-picture/${id}/${userId}`, null);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function retrieveActivityCoverPicture(id) {
  try {
    const response = await get(`admin/activity/${id}/asset/picture`);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function reindexActivityList() {
    try {
    const response = await post(`admin/activities/reindex`, null);
    return response;
  } catch (error) {
    throw error;
  }
}

export function fetchAdvertsByLocation(params = {}) {
  const strParams = JSON.stringify(params || {});

  return post(`admin/activities/by-location-count?filter=${strParams}`, null);
}

export function fetchAdvertsByCategory(params = {}) {
  const strParams = JSON.stringify(params || {});

  return post(`admin/activities/by-category-count?filter=${strParams}`, null);
}

export async function partnerExclusivityList(payload) {
  try {
    const response = await post(`admin/partner-activities`, payload);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function setPartnerActivityExclusivity(payload) {
  try {
    const response = await post(`admin/activities/set-exclusivity`, payload);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function advertDashboardKpi() {
  const response = await get(`admin/kpi/activities`);
  return response;
}