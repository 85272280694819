import { useContext, useState } from "react";
import { Menu } from "antd";
import { UserOutlined } from "@ant-design/icons";
import Sider from "antd/lib/layout/Sider";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { Row } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import { NeoIcon } from "components/neoicon";
import {
  AnnoncesIcon,
  BlogIcon,
  DashboardIcon,
  EvaluationsIcon,
  MailIcon,
  MessagerieIcon,
  ParameterIcon,
  PhoneIcon,
  SignOut,
  TransactionIcon,
  UsersIcon,
  GiftIcon,
  NotificationIcon,
  TransferIcon,
} from "components/icons";
import { useRecoilState/* , useRecoilValue */ } from "recoil";
import { menuCollapsedState } from "store/atoms";
import { useTranslation } from "react-i18next";
import { fullImageUrl } from "utils";
import { GlobalContext } from "contexts";
import Cookies from 'js-cookie'

const SiderBar = styled(({ children, ...rest }) => {
  const { t } = useTranslation(["common"]);
  const {
    avatar,
    clearAvatar
  } = useContext(GlobalContext);

  const menuItems = [
    {
      key: "dashboard",
      label: t`common:dashboard`,
      link: "/tableau-de-bord",
      icon: DashboardIcon,
    },
    {
      key: "transaction",
      label: t`common:transactions`,
      link: "/transactions",
      icon: TransactionIcon,
    },
    {
      key: "users",
      label: t`common:users`,
      link: "/utilisateurs",
      icon: UsersIcon,
    },
    {
      key: "ads",
      label: t`common:ads`,
      link: "/annonces",
      icon: AnnoncesIcon,
    },
    {
      key: "evaluation",
      label: t`common:evaluation`,
      link: "/evaluations",
      icon: EvaluationsIcon,
    },
    {
      key: "settings",
      label: t`common:settings`,
      link: "/parametres",
      icon: ParameterIcon,
    },
    {
      key: "messages",
      label: t`common:messages`,
      link: "/messageries",
      icon: MessagerieIcon,
    },
    {
      key: "promos",
      label: t`common:promos`,
      link: "/promos",
      icon: GiftIcon,
    },
    {
      key: "notifications",
      label: t`common:notification`,
      link: "/notifications",
      icon: NotificationIcon,
    },
    {
      key: "featured",
      label: t`common:featured`,
      link: "/a-la-une",
      icon: BlogIcon,
    },
    {
      key: "protransaction",
      label: t`common:proTransaction`,
      link: "/pro-transactions",
      icon: TransferIcon,
    },
  ];

  const history = useHistory();
  const location = useLocation();
  let menuItemIndex = menuItems.findIndex(menuItem => menuItem.link === location.pathname);
  if (menuItemIndex >= 0){
    sessionStorage.setItem("selectedItem", menuItemIndex.toString());
  } else {
    menuItemIndex =  parseInt(sessionStorage.getItem("selectedItem"));
  }

  const currentSelectedMenuItemKey = menuItems[menuItemIndex >= 0 ? menuItemIndex : 0]?.key || "dashboard";
  const [collapsed, setCollapsed] = useRecoilState(menuCollapsedState);
  const [selectedMenuItemKey, setSelectedMenuItemKey] = useState(currentSelectedMenuItemKey);

  const onCollapse = (collapsed, event) => {
    if (event === true) sessionStorage.setItem("collapsed", collapsed);
    setCollapsed(collapsed);
  };

  const onLogout = (event) => {
    event.preventDefault();
    localStorage.removeItem("authKey");
    localStorage.removeItem("currentUser");
    localStorage.removeItem("userfirstname");
    localStorage.removeItem("userlastname");
    // sessionStorage.removeItem("token");
    Cookies.remove("token");
    clearAvatar();
    sessionStorage.clear();
    history.push("/connexion");
  };

  const firstname = localStorage.getItem("userfirstname");
  const lastname = localStorage.getItem("userlastname");

  const handleSelectedMenuItemClicked = ({ key }) => {
    setSelectedMenuItemKey(key)
  };

  const renderMenuItem = item => (
    <Menu.Item
      key={item.key}
      icon={<item.icon className="icon-style" />}
      className="side-menu-item"
    >
      {
        collapsed ?
          <NavLink to={item.link}></NavLink>
          :
          <NavLink to={item.link}>
            <div className="menu-item-text-holder">
              {!collapsed && <span className="menu-item-text">{item.label}</span>}
            </div>
          </NavLink>
      }
    </Menu.Item>
  );

  const renderMenuItems = items => items.map(renderMenuItem);

  return (
    <Sider
      {...rest}
      collapsible
      collapsed={collapsed}
      breakpoint="md"
      collapsedWidth="70"
      onCollapse={() => onCollapse(collapsed, false)}
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      trigger={null}
    >
      <Row
        style={{
          paddingTop: "1.25rem",
          paddingRight: "1rem",
          paddingLeft: "1rem",
          display: "flex",
          justifyContent: collapsed ? "center" : "flex-end",
        }}
      >
        {!collapsed ? (
          <LeftOutlined
            // onClick={() => setCollapsed(!collapsed)}
            onClick={() => onCollapse(!collapsed, true)}
            style={{ fontSize: "24px", color: "#707070", cursor: "pointer" }}
          />
        ) : (
          // <span onClick={() => setCollapsed(!collapsed)}>
          <span onClick={() => onCollapse(!collapsed, true)} style={{cursor: "pointer"}}>
            <NeoIcon />
          </span>
        )}
      </Row>
      <Row
        style={{
          paddingTop: "1.25rem",
          paddingRight: "1rem",
          paddingLeft: "1rem",
          paddingBottom: 10,
          display: "flex",
          justifyContent: "center",
        }}
      >
        {
          !collapsed &&
          <Avatar
            size={120}
            icon={<UserOutlined />}
            src={fullImageUrl(avatar, "md")}
          />
        }
      </Row>
      {!collapsed && (
        <Row
          style={{
            paddingTop: "1.25rem",
            paddingRight: "1rem",
            paddingLeft: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <span className="text-font-color user-name">
            {`${firstname} ${lastname}`}
          </span>
        </Row>
      )}

      {!collapsed && (
        <Row
          style={{
            paddingTop: 2,
            paddingRight: "1rem",
            paddingLeft: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
          }}
        >
          <span className="text-font-color user-role">
            {"Admin Neosilver"}
          </span>
        </Row>
      )}

      {!collapsed && (
        <Row className="side-menu-icons-container">
          <span style={{cursor: "pointer"}}>
            <PhoneIcon />
          </span>
          <span style={{cursor: "pointer"}}>
            <MailIcon />
          </span>
          <span onClick={onLogout} style={{cursor: "pointer"}}>
            <SignOut />
          </span>
        </Row>
      )}

      <Menu theme="light" defaultSelectedKeys={[selectedMenuItemKey]} onClick={handleSelectedMenuItemClicked} mode="inline">
        {renderMenuItems(menuItems)}
      </Menu>
    </Sider>
  );
})`
  box-shadow: var(--box-shadow);
  z-index: 11;
  overflow: auto;
  height: calc(100vh - var(--header-height));
  position: fixed;
  left: 0;
  background: ${({ theme }) => theme.colors.white};

  .icon-style {
    font-size: 24px !important;
    // padding-right: 20;
    // padding-left: 0;
    // margin-top: 5;
    // color: #828282;
    color: red;
  }

  .menu-item-text-holder {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
  }
  .text-font-color {
    color: #828282;
  }

  .menu-item-text {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    color: #828282;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: ${({ theme }) => `${theme.colors.lightBlue}1F`};
  }

  .ant-menu-item .menu-item-text {
    font-family: var(--font-glacial);
    font-size: 1rem;
  }

  .ant-menu-item-selected > svg path {
    fill: ${({ theme }) => theme.colors.purple};
  }

  .ant-menu-item-selected .menu-item-text {
    font-weight: 800;
    color: ${({ theme }) => theme.colors.purple};
  }

  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: ${({ theme }) => `0.5rem solid ${theme.colors.purple}`};
    right: unset;
    left: 0;
  }

  // .ant-menu-item {
  //   background-color: yellow;
  // }

  // .ant-menu-item + span {
  //   background-color: red;
  // }

  // .ant-menu-submenu-title + span {
  //   background-color: "red";
  //   width: 100%;
  // }

  .user-name {
    font-family: var(--font-gilroy);
    font-weight: 800;
    font-size: 1.25rem;
    color: ${({ theme }) => theme.colors.shades.grey[13]};
    text-align: center;

    &::before {
      --status-circle-width: 0.75rem;
      content: '';
      width: var(--status-circle-width);
      height: var(--status-circle-width);
      border-radius: calc(var(--status-circle-width) / 2);
      background: ${({ theme }) => theme.colors.lightGreen};
      display: inline-block;
      margin-right: 0.3rem;
    }
  }

  .user-role {
    font-family: var(--font-gilroy);
    font-weight: 200;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.colors.shades.grey[32]};
  }

  .side-menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .side-menu-icons-container {
    margin: 0.5rem 0 1.5rem 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
  }
`;

export default SiderBar;
