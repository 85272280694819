import React, { useCallback, useEffect, useRef, useState } from "react";
import MainLayout from "layouts/main";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FeaturedStyle from "styles/featuredStyles";
import { Card, Col, Form, Input, Row, message } from "antd";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";
import FormItem from "antd/lib/form/FormItem";
import {
  FileUploader,
  CustomColorPicker,
  DetailHeader,
  TextButton,
} from "components";
import { EditRounded } from "@material-ui/icons";
import SvgBlogIcon from "components/icons/BlogIcon";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import {
  createFeaturedItem,
  getFeaturedDetail,
  updateFeaturedItem,
  uploadFeaturedImage,
} from "services/featured";
import { fullImageUrl } from "utils";
import { useTheme } from "definitions/styled-components";

const { TextArea } = Input;
const MAX_TAGS = 5;

const retrieveUrlUploadPath = (url, delimiter = "uploads") => `${delimiter}${url.split(delimiter).slice(1).join(delimiter)}`;

const FeaturedDetail = () => {
  const { t } = useTranslation(["common", "featured"]);
  const { id } = useParams();
  const [showDeleteTagDialog, setShowDeleteTagDialog] = useState(false);
  const tagIndexToDelete = useRef(null);
  const [initialValues, setInitialValues] = useState(null);
  const [isFetchingTag, setIsFetchingTag] = useState(false);
  const { theme } = useTheme();

  const history = useHistory();

  const newRecord = useCallback(() => id === "nouvelle", [id]);

  const fetchRecord = () => {
    if (!newRecord()) {
      setIsFetchingTag(true);

      getFeaturedDetail(id).then(r => {
        setInitialValues({
          title: r?.title,
          subtitle: r?.subTitle,
          description: r?.description,
          media: r?.imgUrl,
          tags: r?.headlineTags,
          learnMoreUrl: r?.learnMoreUrl
        })
      }).catch(e => {
        message.error(t`common:errors.commonError`, 10);
        history.push("/a-la-une");
      }).finally(() => {
        setIsFetchingTag(false);
      });
    }
  };

  useEffect(() => {
    fetchRecord();
  }, []);

  const openDeleteTagDialog = (index) => {
    tagIndexToDelete.current = index;
    setShowDeleteTagDialog(true);
  }

  const closeDeleteTagDialog = () => setShowDeleteTagDialog(false);

  const validationSchema = yup.object().shape({
    title: yup.string().required(t`common:errors.requiredField`),
    subtitle: yup.string().required(t`common:errors.requiredField`),
    description: yup.string().required(t`common:errors.requiredField`),
    media: yup.string().required(),
    tags: yup.array().of(
      yup.object().shape({
        content: yup.string().required(t`common:errors.requiredField`),
        colorCode: yup.string(),
        url: yup.string().optional().nullable().url(t`common:errors.incorrectUrl`),
      })
    ),
    learnMoreUrl: yup.string().required(t`common:errors.requiredField`).url(t`common:errors.incorrectUrl`)
  });

  return (
    <MainLayout>
      <FeaturedStyle />
      {
        !isFetchingTag &&
        <Formik
          initialValues={{
            title: initialValues?.title || "",
            subtitle: initialValues?.subtitle || "",
            description: initialValues?.description || "",
            media: initialValues?.media || null,
            tags: initialValues?.tags || [],
            learnMoreUrl: initialValues?.learnMoreUrl || "",
          }}
          validateOnChange={true}
          validateOnMount={true}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);

            if (newRecord()) {
              const requestBody = {
                title: values.title,
                subTitle: values.subtitle,
                description: values.description,
                imgUrl: retrieveUrlUploadPath(values.media),
                learnMoreUrl: values.learnMoreUrl,
                headlineTags: values.tags
              };

              createFeaturedItem(requestBody).then((resp) => {
                message.success(t`common:message.success`, 5);
                history.push(`/a-la-une/${resp?.id}`);
              }).catch(e => {
                message.error(t`common:errors.commonError`, 10);
              }).finally(() => {
                setSubmitting(false);
              });
            } else {
              const requestBody = {
                id: +id,
                title: values.title,
                subTitle: values.subtitle,
                description: values.description,
                imgUrl: retrieveUrlUploadPath(values.media),
                learnMoreUrl: values.learnMoreUrl,
                headlineTags: values.tags
              };

              setIsFetchingTag(true);

              updateFeaturedItem(requestBody).then((resp) => {
                message.success(t`common:message.success`, 5);
                fetchRecord();
              }).catch(e => {
                message.error(t`common:errors.commonError`, 10);
              }).finally(() => {
                setSubmitting(false);
              });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            handleSubmit,
            dirty,
            isValid,
            setFieldTouched,
            setFieldValue,
            resetForm,
          }) => {
            const retrieveErrors = (field) =>
              (touched[field] && errors[field]) || "";

            const handleChangeField = (e) => {
              e?.target?.name &&
                !touched[e.target.name] &&
                setFieldTouched(e.target.name);
              handleChange(e);
            };

            const handleChangeFieldByName = (name) => (value) => {
              setFieldTouched(name);
              setFieldValue(name, value);
            };

            const addTagIsDisabled = () => values.tags.length >= MAX_TAGS;

            const handleChangeTag = (index, attr) => (e) => {
              const newTags = [...values.tags];

              if (e?.target?.value === undefined) {
                newTags[index][attr] = e;
              } else {
                newTags[index][attr] = e?.target?.value;
              }

              handleChangeFieldByName("tags")(newTags);
            };

            const addTag = () => {
              handleChangeFieldByName("tags")([
                ...values.tags,
                {
                  content: "",
                  colorCode: "#5122ff",
                  url: "",
                }
              ]);
            };

            const deleteTag = () => {
              if (
                !Number.isInteger(tagIndexToDelete.current) ||
                tagIndexToDelete.current < 0
              ) return;

              const newTags = [...values.tags];
              newTags.splice(tagIndexToDelete.current, 1);

              handleChangeFieldByName("tags")(newTags);
              tagIndexToDelete.current = undefined;
              closeDeleteTagDialog();
            };

            const renderTag = (tag, index) => (
              <div key={`tag-${index}`} className="tag-container">
                <div className="delete-btn-container">
                  <IconButton
                    aria-label="delete tag"
                    className="delete-tag-btn"
                    onClick={() => openDeleteTagDialog(index)}
                  >
                    <Delete color="error" />
                  </IconButton>
                </div>
                <div className="tag-form-container">
                  <FormItem
                    label={`${t("featured:detail.tags.fields.content.label")}*`}
                    help={retrieveErrors("tags") && retrieveErrors("tags")[index] && retrieveErrors("tags")[index].content}
                    validateStatus={retrieveErrors("tags") && retrieveErrors("tags")[index] && retrieveErrors("tags")[index].content && "error"}
                    className="tag-content"
                  >
                    <Input
                      name="title"
                      placeholder={t("featured:detail.tags.fields.content.placeholder")}
                      value={tag.content}
                      onChange={handleChangeTag(index, "content")}
                    />
                    <CustomColorPicker initialColor={tag.colorCode || theme.colors.shades.grey[60]} onChange={handleChangeTag(index, "colorCode")} />
                  </FormItem>
                  <FormItem
                    label={t("featured:detail.tags.fields.url.label")}
                    help={retrieveErrors("tags") && retrieveErrors("tags")[index] && retrieveErrors("tags")[index].url}
                    validateStatus={retrieveErrors("tags") && retrieveErrors("tags")[index] && retrieveErrors("tags")[index].url && "error"}
                    className="tag-url"
                  >
                    <Input
                      name="title"
                      placeholder={t("featured:detail.tags.fields.url.placeholder")}
                      value={tag.url}
                      onChange={handleChangeTag(index, "url")}
                    />
                  </FormItem>
                </div>
              </div>
            );

            const renderTags = () => values.tags.map(renderTag);

            const renderDeleteTagDialog = () => (
              <Dialog
                open={showDeleteTagDialog}
                onClose={closeDeleteTagDialog}
              >
                <DialogTitle>
                  {t`featured:detail.tags.deleteModal.title`}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    {t`featured:detail.tags.deleteModal.content`}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <TextButton
                    onClick={closeDeleteTagDialog}
                    variant="darkBlue"
                    width="10rem"
                    rounded
                  >
                    {t`featured:detail.tags.deleteModal.no`}
                  </TextButton>
                  <TextButton
                    onClick={deleteTag}
                    variant="darkBlue"
                    type="outlined"
                    width="10rem"
                    rounded
                  >
                    {t`featured:detail.tags.deleteModal.yes`}
                  </TextButton>
                </DialogActions>
              </Dialog>
            );

            const handleFeaturedImageUpload = (name) => ({ blob, base64, file }) => {
              setFieldValue(name, base64);

              if (base64) {
                const fileName = file.name;
                const fileType = file.type;
                const payload = new FormData();
                payload.append("avatar", blob, fileName);

                uploadFeaturedImage(payload, fileType).then(resp => {
                  handleChangeFieldByName("media")(resp?.imageUrls?.url);
                })
                  .catch((e) => {
                    message.error(t`common:errors.commonError`, 10);
                  })
              }
            };
            console.log("ERRORS", errors);
            console.log('FORM IS VALID', isValid);
            return (
              <Form layout="vertical">
                {renderDeleteTagDialog()}
                <Row gutter={[16, 16]} className="featured-detail-content-container">
                  <DetailHeader
                    icon={<SvgBlogIcon fill={"#fff"} stroke={"#fff"} />}
                    title={t`featured:title`}
                    goBack={"/a-la-une"}
                    handleCancel={resetForm}
                    handleSave={handleSubmit}
                    submitIsDisabled={!isValid || isSubmitting}
                    isNewRecord={newRecord()}
                    showFormButtons={true}
                  />
                  <Col xs={24} sm={24} md={12} lg={12} xl={10} xxl={10} className="col-1">
                    <Card bordered={false}>
                      <h2>{t`featured:detail.text.title`}</h2>
                      <FormItem
                        label={`${t("featured:detail.text.fields.title.label")}*`}
                        help={retrieveErrors("title")}
                        validateStatus={retrieveErrors("title") && "error"}
                        className="text-label"
                      >
                        <Input
                          name="title"
                          placeholder={t("featured:detail.text.fields.title.placeholder")}
                          value={values.title}
                          onChange={handleChangeField}
                        />
                      </FormItem>
                      <FormItem
                        label={`${t("featured:detail.text.fields.subtitle.label")}*`}
                        help={retrieveErrors("subtitle")}
                        validateStatus={retrieveErrors("subtitle") && "error"}
                        className="text-label"
                      >
                        <Input
                          name="subtitle"
                          placeholder={t("featured:detail.text.fields.subtitle.placeholder")}
                          value={values.subtitle}
                          onChange={handleChangeField}
                        />
                      </FormItem>
                      <FormItem
                        label={`${t("featured:detail.text.fields.description.label")}*`}
                        help={retrieveErrors("description")}
                        validateStatus={retrieveErrors("description") && "error"}
                        className="text-label"
                      >
                        <TextArea
                          name="description"
                          placeholder={t("featured:detail.text.fields.description.placeholder")}
                          value={values.description}
                          onChange={handleChangeField}
                          className="description-textarea"
                        />
                      </FormItem>
                    </Card>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={7} xxl={5} className="col-2">
                    <Row gutter={[16, 16]} className="sub-row">
                      <Col span={24} className="sub-col-1">
                        <Card bordered={false}>
                          <h2>{t`featured:detail.media.title`}</h2>
                          <FileUploader
                            acceptMultipleFiles={false}
                            showUploadList={false}
                            listType="picture-card"
                            maxFileSize={5 * 1024 * 1024}
                            uploadFile={handleFeaturedImageUpload("media")}
                            className="category-image-uploader"
                          >
                            {
                              values.media ?
                                (<img src={fullImageUrl(values.media)} alt="a la une background" className="category-image-preview" />
                                )
                                :
                                <div>{t`common:uploadImage`}</div>
                            }
                            {
                              values.media &&
                              <IconButton className="edit-image-icon">
                                <EditRounded />
                              </IconButton>
                            }
                          </FileUploader>
                        </Card>
                      </Col>
                      <Col span={24} className="sub-col-2">
                        <Card bordered={false}>
                          <h2>{t`featured:detail.tags.title`}</h2>
                          {renderTags()}
                          <TextButton
                            className="add-tag-btn"
                            variant="white"
                            onClick={addTag}
                            disabled={addTagIsDisabled()}
                            rounded
                          >
                            {t`featured:detail.tags.addTag`}
                          </TextButton>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={7} xxl={5} className="col-3">
                    <Card bordered={false}>
                      <h2>{t("featured:detail.learnMore.title")}</h2>
                      <FormItem
                        label={`${t("featured:detail.learnMore.fields.url.label")}*`}
                        help={retrieveErrors("learnMoreUrl")}
                        validateStatus={retrieveErrors("learnMoreUrl") && "error"}
                        className="text-label"
                      >
                        <Input
                          name="learnMoreUrl"
                          value={values.learnMoreUrl}
                          placeholder={t`featured:detail.learnMore.fields.url.placeholder`}
                          onChange={handleChangeField}
                        />
                      </FormItem>
                    </Card>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      }
    </MainLayout>
  );
};

export default FeaturedDetail;
