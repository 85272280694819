import Cookies from 'js-cookie'
import { get, patch, post, postImages } from "./axios-service";

const apiUrl = process.env.REACT_APP_API_URL;

export async function adminLogin(email: string, password: string) {

  console.log("PROCESS: ", process);
  console.log("API URL: ", apiUrl);

  try {

    const response = await post('admin/auth', {
      email: email,
      password: password,
    })
    const users = response;

    localStorage.setItem("authKey", users.token);
    localStorage.setItem("currentUser", users.loggedUser.id);
    localStorage.setItem("userfirstname", users.loggedUser.firstname);
    localStorage.setItem("userlastname", users.loggedUser.name);
    // sessionStorage.setItem("token", JSON.stringify(users.token));
    Cookies.set("token", JSON.stringify(users.token));
    return response;
  } catch (error) {
    throw new Error("INVALID LOGIN");
  }
}


export async function userList(queryString) {
  let params = '?filter=' + JSON.stringify(queryString);
  const result = await get(`users${params}`);
  return result;
}

export async function exportUsers() {
  const result = {
    response: null,
    error: null
  };

  try {
    const response = await get('users/export');
    result.response = response;
    return result;
  } catch (error) {
    result.error = error;
    return result;
  }
}

export async function  getUser (id) {
  const result = await get(`user/${id}`);
  return result;
}

export const patchUser = async (id, requestBody) => {

  try {
    const response = await patch(`${apiUrl}user/${id}`, requestBody);
    return response;
  } catch (error) {
    throw error;
  }
}

export const postUserAddress = async (requestBody) => {
  try {
    console.log("Address Post >> ", requestBody);
    const response = await  post(`${apiUrl}user/user-address/residential`, requestBody);
    return response;
  } catch (error) {
    throw error;
  }
}

export const uploadAvatar = async (id, avatar, filename) => {
 
  try {
    let data = new FormData();
    data.append('avatar', avatar, filename);
    const response = await postImages(`admin/user/${id}/upload-user-avatar`, data)
    return response;
  } catch (error) {
    throw error;
  }
}

export async function getResponsableNeo() {
  const response = await get(`${apiUrl}admin/admin-users`);
  return response;
}

export async function getUserAttribute(userId) {
  try {
    const response = await get(`user/${userId}/user-attribute`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
}

export async function getRatings(userId) {
  try {
    const response = await get(`admin/ratings/user/${userId}`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
}

export const patchUserAttribute = async (id, requestBody) => {
  try {
    const response = await patch(`user/${id}/user-attribute`, requestBody);
    return response;
  } catch (error) {
    throw error;
  }
}

export const postUserAttribute = async (id, requestBody) => {
  try {
    const response = await post(`user/${id}/user-attribute`, requestBody);
    return response;
  } catch (error) {
    throw error;
  }
}

export const updateUser = async (id, payload) => {

  try {
    const response = await patch(`user/${id}`, payload);
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteUserAvatar = async (id) => {
  try {
    const response = await post(`user/delete-avatar/${id}`, null);
    return response;
  } catch (error) {
    throw error;
  }
}

export function getLatestSales(id) {
  return get(`admin/user-sales-list/${id}`);
}

export function getLatestOrders(id) {
  return get(`admin/user-transaction-list/${id}`);
}

export function getLatestAds(id) {
  return get(`admin/user/${id}/activity-list`);
}

export function getUserDocs(id) {
  return get(`user/${id}/user-docs`);
}

export function deleteUser(id) {
  return post(`admin/user/${id}/delete-account`, {});
}

export function generateUserLoginToken(id) {
  return post(`admin/user/${id}/access-token`, {});
}

export async function userDashboardKpis() {
  const response = await get(`admin/kpi/users`);
  return response;
}