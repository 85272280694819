import { get, post } from "./axios-service";

export async function getTransactionList(queryString) {

  let params = '?filter=' + JSON.stringify(queryString);

  const response = await get(`admin/transactions${params}`);
  return response;
}

export const getTransactionSummaryById = (id) => {
  return get(`/admin/transaction/${id}/summary`);
}

export const sendReminderEmail = (transactionId, requestBody = {}) => {
  return post(`/admin/send-rating-email/${transactionId}`, {});
}

export const sendEmailForRating = (transactionId) => {
  return post(`admin/send-new-rating-request/${transactionId}`, {});
};

export const getRatingTrackingInfo = (transactionId) => {
  return get(`ratings/transaction/${transactionId}/tracking-summary`);
};

export const getTransactionLogs = (transactionId) => {
  return get(`/admin/transaction/${transactionId}/logs`);
};

export const deleteTransaction = (transactionId) => {
  return post(`/admin/transaction/${transactionId}/delete`, {});
};

export const refundTransactionPayment = (payload) => {
  return post(`/admin/payment/refund`, payload);
};

export const getProTransactions = () => {
  return get(`/pro/transactions`);
};

export const executeTransfer = () => {
  return post(`/pro/transfers`, {});
};


export const transactionDashboardKpis = async() => {

  const response = await get(`admin/kpi/transactions`);
  return response;
}

export const stripeCheckout = (payload) => {
  return post(`/stripe/checkout`, payload);
};


export async function exportTransactions(queryString) {

  let params = '?filter=' + JSON.stringify(queryString);

  const response = await get(`admin/transactions/export${params}`);
  return response;
}