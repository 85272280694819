import { Radio, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const KpiToggle = styled(
  ({ opts = [], value, handleChange, color, hasToday = false, ...rest }) => {
    const { t } = useTranslation();

    return (
      <div {...rest}>
        <Radio.Group onChange={handleChange} value={value}>
          {opts.map((opt, index) => (
            <Radio.Button key={`opt-${index}`} value={opt}>
              <Tooltip
                title={
                  hasToday
                    ? t(`common:userStats.${opt}`)
                    : t(`common:stats.${opt}`)
                }
              >
                {hasToday
                  ? t(`common:userStats.${opt}`)
                  : t(`common:stats.${opt}`)}
              </Tooltip>
            </Radio.Button>
          ))}
        </Radio.Group>
      </div>
    );
  }
)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  // margin: 0 0 1rem 0;
  max-width: fit-content;

  & > .ant-radio-group {
    --border-radius: 1rem;

    border-radius: var(--border-radius);
    border: ${({ theme }) => `1px solid ${theme.colors.shades.grey[60]}`};
    display: flex;
    width: 100%;

    label {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    & > .ant-radio-button-wrapper {
      border-radius: var(--border-radius);
      border-color: transparent;

      &.ant-radio-button-wrapper-checked {
        border-color: ${({ theme, color = "salmon" }) => theme.colors[color]};
        background: ${({ theme, color = "salmon" }) => theme.colors[color]};
        color: ${({ theme }) => theme.colors.white};
      }

      &:before {
        display: none;
      }

      &:hover:not(.ant-radio-button-wrapper-checked),
      &:focus:not(.ant-radio-button-wrapper-checked) {
        color: ${({ theme, color }) => theme.colors[color]};
      }
    }
  }
`;
