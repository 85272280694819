import { Card, Steps, Popover, Space, Col } from "antd";
import React from "react";
import styled from "styled-components";
import { useTheme } from "styled-components";
import { useTranslation } from "react-i18next";

const { Step } = Steps;

export const CurrentDot = styled(({ variant, ...rest }) => (
  <div {...rest}></div>
))`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: ${({ theme, variant }) => `7px solid ${variant === "neo" ? theme.colors.darkBlue : theme.colors.salmon}`};
  background: ${({ theme }) => theme.colors.white};
`;

export const InactiveDot = styled(({ variant, ...rest }) => <div {...rest}></div>)`
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  background: ${({ theme, variant }) => variant === "neo" ? theme.colors.darkBlue : theme.colors.salmon};
`;

const customDot = (dot, { status, title }, variant) => (
  // {dot}
  <Popover content={<span>{title}</span>} className="status-popover">
    {(status === "process" || status === "finish") && (
      <CurrentDot variant={variant} />
    )}

    {status === "wait" && <InactiveDot variant={variant} />}
  </Popover>
);

export const NeoCardBody = styled(({ variant, currentStep, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Col {...rest}>
      <Steps
        progressDot={(dot, options) => customDot(dot, options, variant)}
        current={currentStep ?? 0}
        responsive={false}
        className="status-steps"
      >
        {/* <Step title="Finished" description="This is a description." /> */}
        <Step
          title={t`common:userGrades.neo.neo`}
          className={
            currentStep === 0
              ? "status-step current-status-step"
              : "status-step"
          }
        />
        <Step
          title={t`common:userGrades.neo.superNeo`}
          className={
            currentStep === 1
              ? "status-step current-status-step"
              : "status-step"
          }
        />
        <Step
          title={t`common:userGrades.neo.neoStar`}
          className={
            currentStep === 2
              ? "status-step current-status-step"
              : "status-step"
          }
        />
        <Step
          title={t`common:userGrades.neo.ambassador`}
          className={
            currentStep === 3
              ? "status-step current-status-step"
              : "status-step"
          }
        />
        <Step
          title={t`common:userGrades.neo.neoGold`}
          className={
            currentStep === 4
              ? "status-step current-status-step"
              : "status-step"
          }
        />
      </Steps>
    </Col>
  );
})`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0 0 1rem;
`;

export const ProCardBody = styled(({ variant, currentStep, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Col {...rest}>
      <Steps
        progressDot={(dot, options) => customDot(dot, options, variant)}
        current={currentStep ?? 0}
        responsive={false}
        className="status-steps"
      >
        {/* <Step title="Finished" description="This is a description." /> */}
        <Step
          title={t`common:userGrades.pro.pro`}
          className={
            currentStep === 0
              ? "status-step current-status-step"
              : "status-step"
          }
        />
        <Step
          title={t`common:userGrades.pro.superPro`}
          className={
            currentStep === 1
              ? "status-step current-status-step"
              : "status-step"
          }
        />
        <Step
          title={t`common:userGrades.pro.proStar`}
          className={
            currentStep === 2
              ? "status-step current-status-step"
              : "status-step"
          }
        />
        <Step
          title={t`common:userGrades.pro.ambassador`}
          className={
            currentStep === 3
              ? "status-step current-status-step"
              : "status-step"
          }
        />
        <Step
          title={t`common:userGrades.pro.proGold`}
          className={
            currentStep === 4
              ? "status-step current-status-step"
              : "status-step"
          }
        />
      </Steps>
    </Col>
  );
})`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 0 0 1rem;
`;

export const UserStatusCard = styled(({ variant, currentStep, ...rest }) => {
  return (
    <Card {...rest}>
      {variant === "pro" && (
        <ProCardBody variant={variant} currentStep={currentStep} />
      )}
      {variant === "neo" && (
        <NeoCardBody variant={variant} currentStep={currentStep} />
      )}
    </Card>
  );
})`
  height: 13.375rem;
  /* min-width: 55.625rem; */
  width: 100%;
  border: none;
  border-radius: 0.625rem;
  color: ${({ theme }) => theme.colors.shades.grey[30]};
  background: ${({ theme }) => theme.colors.white};
  // box-shadow: var(--box-shadow);
  .ant-card-body {
    height: 100%;
  }
  margin: 1rem 0;
  overflow-x: auto;

  .status-step {
    .ant-steps-item-tail {
      margin: 0 0 0 5rem;

      &:after {
        background: ${({ theme, variant }) => variant === "neo" ? theme.colors.darkBlue : theme.colors.salmon};
        height: 0.4rem;
        margin-top: 0.55rem;
      }
    }

    .ant-steps-item-content {
      margin: 1.5rem 0 0 0.9rem;

      .ant-steps-item-title {
        font-family: var(--font-glacial);
        font-weight: 800;
        font-size: 0.9375rem;
        color: ${({ theme }) => theme.colors.shades.grey[30]};
      }
    }
  }

  .current-status-step {
    .ant-steps-item-content {
      .ant-steps-item-title {
        color: ${({ theme, variant }) => variant === "neo" ? theme.colors.darkBlue : theme.colors.salmon};
      }
    }
  }

  .ant-popover-inner-content {
    font-family: var(--font-glacial);
    font-weight: 800;
    font-size: 0.9375rem;
    color: ${({ theme, variant }) => variant === "neo" ? theme.colors.darkBlue : theme.colors.salmon};
  }
`;
