import { createGlobalStyle } from "styled-components";

const AdvertStyle = createGlobalStyle`
.select-responsible {
  width: 100%;
  max-width: 8.875rem;
  text-align: center;
}

.page-title {
  margin-bottom: 0;
  font-family: var(--font-glacial);
  font-weight: 600;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.shades.grey[18]};
}

.ant-switch-checked {
  background-color: ${({ theme }) => theme.colors.green};
}

.dates-availability {
  width: 46px;
  max-width: 100%;
  height: 22px;
  border-radius: 20px;
  backgroundColor: red; 
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12pt;
}

.page-header-container {
  max-width: 100%;
  margin-bottom: 10px;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ant-space-item {
    min-width: 0;

    &:first-child {
      min-width: 2.5rem;
    }
  }
}

.ant-card {
  box-shadow: var(--box-shadow);
}

.advert-card {
  box-shadow: var(--box-shadow);
  // height: 100%;
  padding: 10px;

  .advert-card-heading {
    padding: 1rem ;
  }
}

.advert-card-small {
  box-shadow: var(--box-shadow);
  // height: 280px;
  padding: 10px;

  .advert-card-small-heading {
    padding: 1rem ;
  }
}

.advert-card .personal-profile-heading {
  width: 100%;
  text-align: center;
  padding-bottom: 0;
}

.personal-profile-card {
  padding: 1rem 0 0 0;
}

.personal-profile-icon {
  margin: 0 auto;
  display: block;
}

.form-container {
  margin: 1rem 0;
}

.MuiFormLabel-root.Mui-disabled {
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: var(--font-glacial);
  font-weight: 800;
  font-size: 16pt;
  padding-left: 10px;
}

.advert-info-container {
  margin: 1.5rem 0 0 0;
}

.advert-box-header {
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: var(--font-glacial);
  font-weight: 800;
  font-size: 16pt;
}

.localisation-row-spacing {
  margin-top: 10px;
  margin-bottom: 30px;
}

.carousel-image-gallery {
  width: 275px;
  height: 150px;
  object-fit: cover;
}

.carousel-tag {
  display: flex;
  flex-direction: column;
  // width: 100%;
  align-items: center;
}

.status-box-text {
  font-family: var(--font-glacial);
  font-size: 12pt;
  color: ${({ theme }) => theme.colors.shades.grey[25]};
}

.status-switch-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-label {
  font-size: 14pt;
  font-family: var(--font-glacial);
  font-weight: bold;
  color: ${({ theme }) => theme.colors.darkBlue};
  margin-top: 5;
  margin-bottom: 5;

  .ant-form-item-control{
    padding-left: 15px;
    color: black;
    font-weight: normal;
  }
}

.full-width {
  width: 100%;
}

.ant-form-item-label > label {
  color: ${({ theme }) => theme.colors.darkBlue};
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: ${({ theme }) => theme.colors.darkBlue};
  border-color: ${({ theme }) => theme.colors.darkBlue};
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: ${({ theme }) => theme.colors.darkBlue};
}

.filter-dropdown {
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.shades.grey[25]};
  font-family: var(--font-glacial);
  font-weight: 200;
  font-size: 0.75rem;
}

.filter-container {
  padding: 1rem;
  background: none;
  backdrop-filter: blur(30px);
}

.filter-menu-title {
  color: ${({ theme }) => theme.colors.shades.grey[2]};
  font-family: var(--font-glacial);
  font-weight: 200;
  font-size: 0.9375rem;
}

.MuiButtonBase-root.filter-menu-item {
  border: ${({ theme }) => `0.05rem solid ${theme.colors.darkBlue}`};
  border-radius: 0.5rem;
  margin: 0.1rem 0;
  height: 2.75rem;
  background-color: ${({ theme }) => `${theme.colors.white}80`};
}

.no-bottom-margin {
  margin-bottom: 0px;
}

.pro-tag {
  width: 50px;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
}

.advert-custom-calendar-modal {
  width: auto !important;
  max-width: 100% !important;

  & > .ant-modal-content {
    border-radius: 1rem;
    
    & > .ant-modal-header {
      border-radius: 1rem;
    }
    
    & > .ant-modal-body {
      & > .create-schedule-btn-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }
}

.ant-input[disabled] {
  color: #000;
  background-color: transparent; 
  // cursor: not-allowed;
  opacity: 1;
  border-top: none;
  border-left: none;
  border-right: none;
}

.form-text-box {
  width: 100%; 
  padding-right: 10px;
}

.align-offers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.avatar-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.swiper-container {
  width: 100%;
  height: 215px;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #F7A58D;
}

.top-card-container {
  & > .ant-card {
    max-height: 30rem;
    overflow-y: auto;
  }
}

.advert-by-location-main-container {
  margin: 2rem 0 0 0;

  .advert-by-location-total-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
    .advert-by-location-total {
      color: ${({ theme }) => theme.colors.darkBlue};
      font-size: 4rem;
      font-weight: 600;
      text-align: center;
      line-height: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 100%;
    }

    .advert-by-location-total-adverts {
      color: ${({ theme }) => theme.colors.shades.grey[40]};
      font-size: 2rem;
      line-height: 1;
    }
  }

  .adverts-by-location-container {
    .advert-by-location-row-container {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;

      .advert-by-location-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
        flex-wrap: nowrap;
        
        .advert-by-location-text-container {
          display: flex;
          flex-direction: column;
          min-width: 6rem;
          color: ${({ theme }) => theme.colors.shades.grey[20]};

          .advert-by-location-city {
            font-weight: 800;

            .advert-by-location-count {
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.adverts-by-category-container {
  margin: 2rem 0 0 0;

  .advert-by-category-row-container {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

    .advert-by-category-circle {
      --circle-size: 0.75rem;

      min-width: var(--circle-size);
      min-height: var(--circle-size);
      border-radius: calc(var(--circle-size) / 2);
    }

    .advert-by-category-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      flex-wrap: nowrap;
      
      .advert-by-category-text-container {
        display: flex;
        flex-direction: column;
        min-width: 8rem;
        color: ${({ theme }) => theme.colors.shades.grey[20]};

        .advert-by-category-city {
          font-weight: 800;

          .advert-by-category-count {
            font-weight: 400;
          }
        }
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

`;

export default AdvertStyle;
