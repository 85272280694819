import { CheckOutlined, CloseOutlined, DownOutlined, EditOutlined, UpOutlined  } from "@ant-design/icons";
import { Col, Collapse, Divider, Form, Input, message, Row, Select, Table, Card, Checkbox, Upload } from "antd";
import { ParameterHeader, TextButton } from "components";
import SvgAnnoncesIcon from "components/icons/AnnoncesIcon";
import MainLayout from "layouts/main";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { createCategory, deleteCategoryAsset, getCategories, updateCategory, updateCategoryWithRootParent, updateListOrdering } from "services/category";
import ParametersStyle from "styles/parametersStyles";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { post } from "services/axios-service";

const MAX_FILE_COUNT = 5;
const MAX_FILE_SIZE = 5 * 1024 * 1024;

const Categories = () => {

  const { t } = useTranslation(["common", "parameters"]);
  const [allCategories, setAllCategories] = useState([]);
  const [topMostParent, setTopMostParent] = useState([]);
  const [selectParentCategory, setSelectedParentCategory] = useState("null");
  const [categoryName, setCategoryName] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const [categoryDescription, setCategoryDescription] = useState("");
  // const [categoryNameError, setCategoryNameError] = useState("");
  const [language, setLanguage] = useState("");
  const [translatedName, setTranslatedName] = useState("");
  const [translatedDescription, setTranslatedDescription] = useState("");
  const [recordId, setRecordId] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [images, setImages] = useState(null);
  const { Option } = Select;
  const categoryNameError = "";

  const columns = [
    { title: 'Id', dataIndex: 'id', key: 'id', align: 'center' as 'center'},
    { title: 'Titre', dataIndex: 'name', key: 'name' },
    { title: '', key: 'operation', align: 'center' as 'center', render: (row) => { return ( <EditOutlined onClick={(e) => onEditRecord(e,row)} />)} },
    { title: '', key: 'operation', align: 'center' as 'center',render: (row) => { return ( <UpOutlined onClick={(e) => handleOrderingUp(e,row)} />)} },
    { title: '', key: 'operation', align: 'center' as 'center',render: (row) => { return ( <DownOutlined onClick={(e) => handleOrderingDown(e,row)} />)}  },
    { title: '', key: 'operation', align: 'center' as 'center',render: (row) => { return ( row.isActive ? <CheckOutlined style={{color: "green"}} onClick={(e) => handleActivateStatus(e,row, false)}/> : <CloseOutlined style={{color: "red"}} onClick={(e) => handleActivateStatus(e,row, true)} />)}  },
  ];

  const expandedRowRender = (row) => {
    const columns = [
      { title: '', dataIndex: 'id', key: 'id', align: 'center' as 'center' },
      { title: '', dataIndex: 'name', key: 'name'},
      { title: 'Action', key: 'operation', align: 'center' as 'center', render: (e, row) => { return ( <EditOutlined onClick={(e) => onEditRecord(e,row)} />)} },
      { title: 'Sort Up', key: 'operation', align: 'center' as 'center', render: (e, row) => { return ( <UpOutlined onClick={(e) => handleOrderingUp(e,row)} />)} },
      { title: 'Sort Down', key: 'operation', align: 'center' as 'center', render: (e, row) => { return ( <DownOutlined onClick={(e) => handleOrderingDown(e,row)} />)}  },
      { title: '', key: 'operation', align: 'center' as 'center',render: (row) => { return ( row.isActive ? <CheckOutlined style={{color: "green"}} onClick={(e) => handleActivateStatus(e,row, false)}/> : <CloseOutlined style={{color: "red"}} onClick={(e) => handleActivateStatus(e,row, true)} />)}  },
    ];

    let inTable = allCategories.filter(x => x.parentCategoryId === row.id);
    return <Table columns={columns} dataSource={inTable} pagination={false} expandable={{ expandedRowRender }} showHeader={false}/>;
  }

  const handleOrderingUp = async (e, record) => {
    e.preventDefault();
    e.stopPropagation();
    if (record) {
      if (record.position === 1) {
      } else {
        const previousRecord = allCategories.find(x => x.position === record.position - 1 && x.parentCategoryId === record.parentCategoryId);
        if (previousRecord) {
          let payload = [
            {
              id: record.id,
              position: record.position - 1
            },
            {
              id: previousRecord.id,
              position: previousRecord.position + 1
            }
          ];
          await updateListOrdering(payload);
          await loadInitialData();
        }
      }
    }
    //debounce
  };

  const handleOrderingDown = async (e, record) => {
    e.preventDefault();
    e.stopPropagation();

    if (record) {
        const nextRecord = allCategories.find(x => x.position === record.position + 1 && x.parentCategoryId === record.parentCategoryId);
        if (nextRecord) {
          let payload = [
            {
              id: record.id,
              position: record.position + 1
            },
            {
              id: nextRecord.id,
              position: nextRecord.position - 1
            }
          ];
          await updateListOrdering(payload);
          await loadInitialData();
        }
      }
  };

  const recurseActivation = (id, isActiveStatus) => {
    let pay = [];

    const children = allCategories.filter(
      (x) => x.parentCategoryId === id
    );
    if (children && children.length > 0) {
      children.forEach((c) => {
        pay.push({
          id: c.id,
          isActive: isActiveStatus,
        });
        recurseActivation(c.id, isActiveStatus);
      });
    }
    return pay;
  };

  const recurseParentStatus = (idParent) => {
    let allowProceed = false;
    const parent = allCategories.filter(x => x.id === idParent);
    if (parent && parent.length > 0) {
      parent.forEach(p => {
        if (p.isActive) {
          allowProceed = true;
          recurseParentStatus(p.parentCategoryId);
        } else {
          allowProceed = false;
          return allowProceed;
        }
      })
    }
    return allowProceed;
  }

  const handleActivateStatus = async (e, record, isActiveStatus) => {
    e.preventDefault();
    e.stopPropagation();
    let allowProceed = true;
    if (record) {
      if (isActiveStatus && record.parentCategoryId !== null) {
        allowProceed = recurseParentStatus(record.parentCategoryId);
      } 
      if (allowProceed) {
        let activationList = recurseActivation(record.id, isActiveStatus);
        let payload = [];
        payload = activationList;
        payload.push({
          id: record.id,
          isActive: isActiveStatus
        })
  
        await updateListOrdering(payload);
        await loadInitialData();
      } else {
        message.error(t`parameters:category.errors.activateParentFirst`, 5);
      }
      
    }
  };

  const onEditRecord = (e, record) => {
    if (record) {
      console.log("Record ", record);
      setRecordId(record.id);
      setSelectedParentCategory(record.parentCategoryId);
      setCategoryName(record.name);
      setCategoryTitle(record.title);
      setCategoryDescription(record.description);
      setImages((record?.categoryAssets || []).map(asset => ({
        uid: asset.id,
        url: `${process.env.REACT_APP_S3_CDN_URL}${asset.url}`,
      })));
      setSelectedCategory(record);
      setIsActive(record.isActive);
      setLanguage("");
      setTranslatedName("");
      setTranslatedDescription("");
    }
  };

  useEffect(() => {
    async function fetchUsers() {
      await loadInitialData();
    }
    fetchUsers();

  }, []);

  const loadInitialData = async () => {
    const response = await getCategories();
    const catWithKeys = response.map((e, i) => ({ ...e, key: i }));
    setAllCategories(catWithKeys);
    let tops = catWithKeys.filter(x=> x.parentCategoryId === null)
    const topKeys = tops.map((e, i) => ({ ...e, key: i }));
    setTopMostParent(topKeys);
  }

  const handleParentOnChange = (e) => {
      setSelectedParentCategory(e);
  };

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };

  const handleCategoryTitleChange = (event) => {
    setCategoryTitle(event.target.value);
  };

  const handleCategoryDescriptionChange = (event) => {
    setCategoryDescription(event);
  };

  const onSaveCategory = async() => {
    const payload = createPayload();
    let response;


    if (recordId && selectParentCategory === recordId) {
      message.error(t`parameters:category.errors.parentEnfant`, 5);
    } else {
      if (recordId) {
        response = await updateCategoryWithRootParent(recordId, payload);
      } else {
        response = await createCategory(payload);
      }
  
      if (response && response.id) {
        message.success(t`common:message.success`, 5);
        await loadInitialData();
      } else {
        message.error(t`common:message.error`, 5);
      }
  
      setRecordId(null);
      setSelectedParentCategory(null);
      setCategoryName("");
      setCategoryTitle("");
      setCategoryDescription("");
      setImages([]);
    }
  }

  const createPayload = () => {
    let payload = null;

    if (recordId) {
      if (selectParentCategory && selectParentCategory !== "null") {
        const parent = selectParentCategory;
        payload = {
          category: {
            parentCategoryId: parent,
            name: categoryName,
            title: categoryTitle,
            description: categoryDescription,
            id: recordId
          },
          newParentId: selectParentCategory && selectParentCategory !== "null" ? selectParentCategory : 0,
          currentParentId: selectedCategory.parentCategoryId ? selectedCategory.parentCategoryId : 0
        };
      } else {
        payload = {
          category: {
            name: categoryName,
            title: categoryTitle,
            description: categoryDescription,
            id: recordId,
          },
          newParentId: selectParentCategory && selectParentCategory !== "null" ? selectParentCategory : 0,
          currentParentId: selectedCategory.parentCategoryId ? selectedCategory.parentCategoryId : 0
        };
      }
    } else {
      if (selectParentCategory && selectParentCategory !== "null") {
        const parent = selectParentCategory;
        payload = {
          parentCategoryId: parent,
          name: categoryName,
          title: categoryTitle,
          description: categoryDescription,
        };
      } else {
        payload = {
          name: categoryName,
          title: categoryTitle,
          description: categoryDescription,
        };
      }
    }
    return payload;
  }

  const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
    ['blockquote', 'code-block'],
  
    [{ 'header': 1 }, { 'header': 2 }],               // custom button values
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
    [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    [{ 'direction': 'rtl' }],                         // text direction
  
    [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  
    [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
    [{ 'font': [] }],
    [{ 'align': [] }],
  
    ['clean']                                         // remove formatting button
  ];

  const handleLanguageOnChange = (e) => {
    setLanguage(e);
    if (e && recordId){
      if (e === "En"){
        setTranslatedName(selectedCategory.nameEn);
        setTranslatedDescription(selectedCategory.descriptionEn);
      }
    }
  };

  const handleTranslatedNameChange = (event) => {
    setTranslatedName(event.target.value);
  };


  const handleTranslatedDescriptionChange = (event) => {
    setTranslatedDescription(event);
  };

  const onSaveTranslation = async() => {
    let payload;
    if (language) {
        if (language === "En") {
          payload = {
            nameEn: translatedName,
            descriptionEn: translatedDescription ?? "",
            id: recordId
          };
        }

      const response = await updateCategory(recordId, payload);
      if (response && response.id) {
        updateAllCategoryList(recordId, translatedName, translatedDescription, language);
        message.success(t`common:message.success`, 5);
      } else {
        message.error(t`common:message.error`, 5);
      }

      setLanguage("");
      setTranslatedName("");
      setTranslatedDescription("");
    }

  }

  const updateAllCategoryList = (id, name, description, language) => {

    const catIndex = allCategories.findIndex(x => x.id === id);
    const cat = allCategories.find(x => x.id === id);

    if (cat) {
      if (language === "En"){
        cat.nameEn = name;
        cat.descriptionEn = description;
      }
    }
    allCategories[catIndex] = cat;
  }

  const onIsActiveChange = async (e) => {
    setIsActive(e.target.checked);
    if(recordId) {
      await handleActivateStatus(e, selectedCategory, e.target.checked);
    }
  }

  const handleBeforeUploadImage = (file) => {
    if ((images || [])?.length > (MAX_FILE_COUNT)) {
      message.error(t("common:tooManyFiles", {count: MAX_FILE_COUNT}), 10);
      return false;
    }
    
    return true;
  };

  const handleChangeImage = ({ file, fileList }) => {
    if (file.size > MAX_FILE_SIZE) {
      message.error(t("common:fileTooLarge", {size: `${MAX_FILE_SIZE / 1024 / 1024}MB`}), 10);
      return;
    }

    // if ((fileList || []).length >= MAX_FILE_COUNT) {
    if (images.length >= MAX_FILE_COUNT && file.status !== "removed") {
      message.error(t("common:tooManyFiles", {count: MAX_FILE_COUNT}), 10);
      return;
    }

    const newFileList = fileList.map(file => ({...file, status: "done"})) || [];
    setImages(newFileList);

    if (file.status !== "uploading") return;

    const fileName = file.name;
    const fileType = file.type;
    const fileObj = file.originFileObj;
    const blob = new Blob([fileObj]);
    const headers = {
      "Content-Type": "multipart/form-data",
      "Mime-Type": fileType,
    };
    const payload = new FormData();
    payload.append("avatar", blob, fileName);

    post(`admin/category/${recordId}/upload-picture`, payload, headers).then(resp => {
      const newImages = newFileList.map((image) => {
        if (image.uid === file.uid) {
          return {
            ...image,
            uid: resp?.id,
            status: "done"
          };
        }

        return image;
      });

      setImages(newImages);

      loadInitialData();
    })
    .catch((e) => {
      console.log("##### CATCH e :>> ", e);
      message.error(t`common:errors.commonError`, 5);
      const newImages = newFileList.filter((image) => image.uid !== file.uid);
      setImages(newImages);
    });
  };

  const handleRemoveImage = (file) => {
    return deleteCategoryAsset(file.uid).then(() => {
      loadInitialData();
    }).catch((e) => {
      message.error(t`common:errors.commonError`, 5);
    });
  };

  const { Panel } = Collapse;
  const callback = (key) => {};

  return (
    <MainLayout>
      <ParametersStyle />
      <ParameterHeader icon={<SvgAnnoncesIcon fill={"#fff"} stroke={"#fff"} />} title={t`parameters:category.title`} subTitle={t`parameters:category.subTitle`} goBack={"/parametres/annonce"}/>
      <Row gutter={[8, 8]} className="category-container">
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Card bordered={true} className="parent-card-style">
            <p className="section-title">{t`parameters:category.listTitle`}</p>
              <Table
                columns={columns}
                dataSource={topMostParent}
                locale={{ emptyText: t`common:table.emptyText`}}
                expandable={{ expandedRowRender }}
                pagination={false}
              />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12}>
          <Card bordered={true} className="parent-card-style">
            <p className="section-title">{t`parameters:category.addTitle`}</p>
            <Form layout="vertical" className="form-vertical" style={{padding: 10}}>
              <Form.Item
              label={t("parameters:category.selectTitle")}
              className="text-label"
              >
                <Select
                  placeholder={t("parameters:category.noParent")}
                  onChange={(e) => handleParentOnChange(e)}
                  value={selectParentCategory}
                  defaultValue={"null"}
                  className="select-style"
                  >
                  <Option value="null" default>{t("parameters:category.noParent")}</Option>
                  {allCategories?.filter(item => item.isActive === true).map((item) => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                </Select>
              </Form.Item>
              <Divider />
              <Form.Item 
                label={t("parameters:category.name")} 
                className="text-label" 
                validateStatus={categoryNameError && "error"}
                hasFeedback
                style={{
                  width: "100%",
                  paddingBottom: 0,
                }}
                required
              >
                <Input 
                  placeholder={t("parameters:category.name")} 
                  value={categoryName} 
                  onChange={handleCategoryNameChange}
                />
              </Form.Item>
              <Form.Item label={t("parameters:category.titleLabel")} className="text-label">
                <Input 
                  placeholder={t("parameters:category.titleLabel")}
                  value={categoryTitle} 
                  onChange={handleCategoryTitleChange}
                />
              </Form.Item>
              <Row className="quil-row-container">
                <Col span={24}>
                  <label className="text-label quill-label">{t("parameters:category.description")}</label>
                  <ReactQuill
                    theme="snow" 
                    value={categoryDescription}
                    onChange={handleCategoryDescriptionChange} 
                    modules={{toolbar: toolbarOptions}} 
                  />
                </Col>
              </Row>
              {
                recordId && selectedCategory && (
                <Row>
                  <Col span={24}>
                    <Checkbox onChange={onIsActiveChange} checked={isActive} className="text-label" style={{paddingLeft: "15px"}}>{"Active"}</Checkbox>
                  </Col>
                </Row>
              )}
              <Row className="category-image-uploader-container">
                <Col span={24}>
                  {
                    recordId &&
                    <Upload
                      listType="picture-card"
                      fileList={images}
                      // onPreview={this.handlePreview}
                      beforeUpload = {handleBeforeUploadImage}
                      customRequest={({ file, onSuccess }) => {
                        setTimeout(() => {}, 0);
                      }}
                      onChange={handleChangeImage}
                      onRemove={handleRemoveImage}
                      maxCount={MAX_FILE_COUNT}
                      accept=".jpeg,.png,.jpg"
                    >
                      <div>{t`common:uploadImage`}</div>
                    </Upload>
                  }
                </Col>
              </Row>
              <Row justify="center" className="quil-container-button">
                <TextButton
                  rounded={true}
                  variant="salmon"
                  onClick={onSaveCategory}
                  disabled={categoryNameError || !categoryName }
                >
                  {t`parameters:category.saveBtn`}
                </TextButton>
              </Row>
              <Row>
              <Collapse defaultActiveKey={['1']} onChange={callback} style={{width:"100%"}}>
                <Panel header={t`parameters:category.translation`} key="1" className="collapse-header">
                  <Row>
                    <Form.Item
                      label={t("parameters:category.selectLanguage")}
                      className="text-label"
                    >
                      <Select
                        style={{ width: "100%"}}
                        placeholder={t("parameters:category.selectLanguage")}
                        onChange={(e) => handleLanguageOnChange(e)}
                        value={language}
                        >
                        <Option value="En" default>{t("parameters:category.language.english")}</Option>
                      </Select>
                    </Form.Item>

                  </Row>
                  <Row>
                    <Form.Item 
                      label={t("parameters:category.name")} 
                      className="text-label" 
                      validateStatus={categoryNameError && "error"}
                      hasFeedback
                      style={{
                        width: "100%",
                        paddingBottom: 0,
                      }}
                    >
                      <Input 
                        placeholder={t("parameters:category.name")} 
                        value={translatedName} 
                        onChange={handleTranslatedNameChange}
                      />
                    </Form.Item>
                  </Row>
                  <Row className="quil-row-container">
                    <Form.Item label={t("parameters:category.description")} className="text-label">
                      <ReactQuill 
                        theme="snow" 
                        value={translatedDescription}
                        onChange={handleTranslatedDescriptionChange} 
                        modules={{toolbar: toolbarOptions}} 
                      />
                    </Form.Item>
                  </Row>
                  <Row justify="center" className="quil-container-button">
                    <TextButton
                      rounded={true}
                      variant="salmon"
                      onClick={onSaveTranslation}
                      disabled={!recordId || !language || !translatedName}
                    >
                      {t`common:save`}
                    </TextButton>
                  </Row>
                </Panel>
              </Collapse>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </MainLayout>

  );
}

export default Categories;
