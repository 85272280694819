import { Tabs as BaseTabs } from "antd";
import React from "react";
import styled from "styled-components";

const { TabPane } = BaseTabs;

const renderTabBar = (props, DefaultTabBar) => (
  <DefaultTabBar {...props} className="profile-tab-bar" />
);

const TabContent = (tab, index) => <TabPane tab={tab.title} key={index} />;

const renderTabs = (tabs) => tabs.map((tab, index) => TabContent(tab, index));

export const Tabs = styled(({ tabs, renderCustomTabBar, ...rest }) => (
  <BaseTabs {...rest} renderTabBar={renderCustomTabBar || renderTabBar}>
    {renderTabs(tabs || [])}
  </BaseTabs>
))`
  --tab-height: 2.7058823529411766em;
  --tab-radius: calc(var(--tab-height) / 2);

  .profile-tab-bar {
    margin: 0;

    &:before {
      border-bottom: none;
    }
  }

  .ant-tabs-nav-list {
    padding: 1rem 0.5rem;
  }

  .ant-tabs-ink-bar-animated {
    display: none;
  }

  .ant-tabs-tab {
    margin: 0;
    padding: 0;
    height: var(--tab-height);
    min-width: 12em;
    color: ${({ theme }) => theme.colors.shades.grey[22]};
    /* background: ${({ theme }) => theme.colors.white}; */
    border: ${({ theme, variant = "salmon" }) => `0.2rem solid ${theme.colors[variant]}`};
    border-right: none;
    border-left: none;


    &:first-child {
      border-top-left-radius: var(--tab-radius);
      border-bottom-left-radius: var(--tab-radius);
      border-left: ${({ theme, variant = "salmon" }) => `0.2rem solid ${theme.colors[variant]}`};
    }

    &:nth-child(${({ tabs }) => tabs.length}) {
      border-right: ${({ theme, variant = "salmon" }) => `0.2rem solid ${theme.colors[variant]}`};
    }

    /* &:first-of-type div {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.salmon};
    }

    &:not(:first-child) {
      color: ${({ theme }) => theme.colors.shades.grey[22]};
      background: ${({ theme }) => theme.colors.white};
    }

    &:nth-child(2) {
      border-top-left-radius: var(--tab-radius);
      border-bottom-left-radius: var(--tab-radius);
    } */
  }

  // last element
  .ant-tabs-tab:nth-last-of-type(2) {
    border-top-right-radius: var(--tab-radius);
    border-bottom-right-radius: var(--tab-radius);
    /* background: ${({ theme }) => theme.colors.white}; */
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme, variant = "salmon" }) => theme.colors[variant]};
      box-shadow: var(--box-shadow);
    }
  }

  .ant-tabs-tab-btn {
    font-family: var(--font-glacial);
    font-size: 1.0625rem;
    font-weight: 400;
    line-height: 1.9;
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: var(--tab-radius);
    /* background: ${({ theme }) => theme.colors.white}; */
  }
`;
