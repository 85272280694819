import { createGlobalStyle } from "styled-components";

const TransactionStyle = createGlobalStyle`
.select-responsible {
  width: 100%;
  max-width: 8.875rem;
  text-align: center;
}

.page-title {
  margin-bottom: 0;
  font-family: var(--font-glacial);
  font-weight: 600;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.shades.grey[18]};
}

.ant-switch-checked {
  background-color: ${({ theme }) => theme.colors.green};
}

.ant-card-body {
  padding: 0px;
}

/* .ant-card-head {
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: white;
  font-size: 13pt;
  font-family: var(--font-glacial);
} */

.status-tag {
  width: 170px;
  height: 30px;
  background-color: ${({ theme }) => theme.colors.shades.grey[18]};
  border-radius: 25px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-family: var(--font-glacial);
  font-weight: 200;
}

.blue-color {
  background-color: ${({ theme }) => theme.colors.darkBlue};
}

.green-color {
  background-color: ${({ theme }) => theme.colors.green};
}

.salmon-color {
  background-color: ${({ theme }) => theme.colors.salmon};
}

.yellow-color {
  background-color: ${({ theme }) => theme.colors.yellow};
}

.black-color {
  background-color: ${({ theme }) => theme.colors.black};
}

.purple-color {
  background-color: ${({ theme }) => theme.colors.purple};
}

.page-header-container {
  max-width: 100%;
  margin-bottom: 10px;

  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .ant-space-item {
    min-width: 0;

    &:first-child {
      min-width: 2.5rem;
    }
  }
}


.form-container {
  margin: 1rem 0;
}

.MuiFormLabel-root.Mui-disabled {
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: var(--font-glacial);
  font-weight: 800;
  font-size: 16pt;
  padding-left: 10px;
}

.advert-info-container {
  margin: 1.5rem 0 0 0;
}

.advert-box-header {
  color: ${({ theme }) => theme.colors.darkBlue};
  font-family: var(--font-glacial);
  font-weight: 800;
  font-size: 16pt;
}

.status-box-text {
  font-family: var(--font-glacial);
  font-size: 12pt;
  color: ${({ theme }) => theme.colors.shades.grey[25]};
}

.status-switch-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-width {
  width: 100%;
}

.filter-dropdown {
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.shades.grey[25]};
  font-family: var(--font-glacial);
  font-weight: 200;
  font-size: 0.75rem;
}

.filter-container {
  padding: 1rem;
  background: none;
  backdrop-filter: blur(30px);
}

.filter-menu-title {
  color: ${({ theme }) => theme.colors.shades.grey[2]};
  font-family: var(--font-glacial);
  font-weight: 200;
  font-size: 0.9375rem;
}

.MuiButtonBase-root.filter-menu-item {
  border: ${({ theme }) => `0.05rem solid ${theme.colors.darkBlue}`};
  border-radius: 0.5rem;
  margin: 0.1rem 0;
  height: 2.75rem;
  background-color: ${({ theme }) => `${theme.colors.white}80`};
}

.no-bottom-margin {
  margin-bottom: 0px;
}

.view-btn {
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: #fff;
  border-color: ${({ theme }) => theme.colors.darkBlue};
  width: 45px;
  height: 26px;
}

.ant-btn-icon-only.ant-btn-sm {
  width: 45px;
  height: 28px;
  font-size: 16px;
}


.card-container {
  padding: 15px;
}

.rating-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  justify-content: flex-start;
}

.rating-text {
  font-family: var(--font-glacial);;
  font-size: 33pt;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.darkBlue};
}

.comments-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;
}

.comments-text {
  font-family: var(--font-glacial);
  font-size: 12pt;
  color: ${({ theme }) => theme.colors.shades.grey[28]};
}

.comments-meta-text {
  font-family: var(--font-glacial);
  font-size: 10pt;
  color: ${({ theme }) => theme.colors.shades.grey[36]};
  font-style: italic;
}

.custom-card {
  box-shadow: var(--box-shadow);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.card-header-custom {
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: white;
  font-size: 13pt;
  font-family: var(--font-glacial);
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.card-body-custom {
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button-row {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.header-text {
  padding-left: 10px;
  font-size: 13pt;
  font-weight: 550;
}

.sub-header-text {
  padding-left: 10px;
  font-size: 9pt;
}

.vertical-divider {
  border-left: 1px solid ${({ theme }) => theme.colors.darkBlue};
  height: 80px;
}

.check-box-styles {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  
}

.check-box-text {
  font-size: 14pt;
  font-family: var(--font-glacial);
  color: ${({ theme }) => theme.colors.shades.grey[20]};
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: ${({ theme }) => theme.colors.salmon};
  border-color: ${({ theme }) => theme.colors.salmon};
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: ${({ theme }) => theme.colors.salmon};
}

.ant-checkbox-checked::after {
  border: 1px solid  ${({ theme }) => theme.colors.salmon};
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}

.activity-panel {
  padding: 15px;
  padding-left: 20px;
  margin-botton: 10px;
}

.activity-panel-description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.activity-picture {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  object-fit: cover;
}

.seller-name {
  margin-bottom: 0;
  font-family: var(--font-glacial);
  font-size: 14pt;
  color: ${({ theme }) => theme.colors.shades.grey[18]};
  text-decoration: underline;
  padding-left: 5px;
}

.seller-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding-bottom: 10px;
}

.transaction-detail-container {
  margin: 1rem 0;

  h1 {
    font-family: var(--font-glacial);
    font-size: 1.5rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  h2 {
    font-family: var(--font-glacial);
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  .transaction-card {
    box-shadow: var(--box-shadow);
    padding: 2rem;
  }

  .transaction-info-header {
    display: flex;
    flex-direction: column;
    align-items: center;

    label {
      font-family: var(--font-glacial);
      font-weight: 400;
      font-size: 0.975rem;
      color: ${({ theme }) => theme.colors.shades.grey[36]};
    }
  }

  .transaction-detail-card {
    padding: 0;
  
    .ant-card-body {
      padding: 2rem;

      .transaction-detail-content-container {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;

        img {
          border-radius: 0.5rem;
          max-width: 5.3125rem;
          object-fit: cover;
        }

        .transaction-detail-content {
          display: flex;
          flex-direction: column;

          label {
            font-family: var(--font-glacial);
            font-family: 0.85rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.darkBlue};
          }

          a {
            font-family: var(--font-glacial);
            font-weight: 400;
            font-size: 0.9125rem;
            color: ${({ theme }) => theme.colors.shades.grey[34]};
            text-decoration: underline;
            text-align: center;
          }
        }
      }

      .transaction-payment-info-container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        label {
          font-family: var(--font-glacial);
          font-family: 0.8rem;
          font-weight: 600;
          color: ${({ theme }) => theme.colors.darkBlue};

          span {
            font-weight: 400;
            color: ${({ theme }) => theme.colors.shades.grey[34]};
          }
        }

        p {

          font-family: var(--font-glacial);
          font-family: 0.7rem;
          font-weight: 400;
          color: ${({ theme }) => theme.colors.shades.grey[34]};
        }
      }

      .transaction-detail-commission {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.5rem 1rem;
        border: ${({ theme }) => `0.0625rem solid ${theme.colors.shades.grey[30]}`};
        border-radius: 0.35rem;
        margin: 0.5rem 0 0 0;
        font-family: var(--font-glacial);
        font-weight: 600;
        color: ${({ theme }) => theme.colors.shades.grey[30]};
      }
    }
  }

  .transaction-detail-actions-container {
    padding: 2rem 4rem;

    .ant-card-body {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }

  .transaction-evaluation-container {
    .transaction-evaluation-actions-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;

      a {
        font-family: var(--font-glacial);
        font-weight: 400;
        font-size: 0.9125rem;
        color: ${({ theme }) => theme.colors.shades.grey[34]};
        text-decoration: underline;
      }
    }
  }
}

`;

export default TransactionStyle;
