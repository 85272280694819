import React, { useEffect, useState } from "react";
import { Content } from "antd/lib/layout/layout";
import { useTheme } from "styled-components";
import { Card, Col, Layout, message, Row } from "antd";
import { useParams } from "react-router-dom";
import { adminAccountActivation } from "services/admin";
import { Trans, useTranslation } from "react-i18next";
import AccountActivationStyle from "styles/accountActivationStyles";
import { Header } from "components";

const AccountActivation = ({ ...props }) => {
  const { t } = useTranslation(["common", "parameters", "users"]);
  const { query } = useParams();
  const { colors } = useTheme();
  const [isActivationSuccess, setActivationStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const activation = new URLSearchParams(window.location.search).get("activation_code");

  useEffect(() => {
    if (activation) {
      userActivation();
    }
  }, [activation]);

  const userActivation = () => {
    if (activation) {
      adminAccountActivation({
        activationCode: activation,
      })
        .then((response) => {
          console.log('response :>> ', response);
          setActivationStatus(true);
          setIsLoading(false);
        })
        .catch((e) => {
          setActivationStatus(false);
          if (e?.response?.data?.error?.message === "invalidActivationCode") {
            message.error(t("parameters:admin.accountActivation.accountActivated.errors.title"), 5);
          }
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Header />
      <Layout id="main-layout">
        <AccountActivationStyle />
        <Content className="content-container">
          <Row className="page-description-container">
            <h1 className="page-description-title">{t("parameters:admin.accountActivation.title")}</h1>
            {isActivationSuccess && (
              <p className="page-description-subtitle">
                {t("parameters:admin.accountActivation.accountActivated.description")}
              </p>
            )}
          </Row>
          <Card>
            <Row justify="space-between" className="content-paragraph-container">
              {isLoading && (
                <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                  <p className="content-title">{t("common:loadingInProgress")}</p>
                  <p className="content-title">{"..."}</p>
                </Col>
              )}
              {!isLoading && isActivationSuccess && (
                <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                  <p className="content-title">
                    {t("parameters:admin.accountActivation.accountActivated.content.title")}
                  </p>
                  <br />
                  <p className="content-paragraph">
                    <Trans
                      i18nKey="parameters:admin.accountActivation.accountActivated.content.content"
                      components={{
                        anchor: (
                          <a
                            className="anchor-text"
                            href="/connexion"
                          />
                        ),
                      }}
                    />
                  </p>
                </Col>
              )}
              {!isLoading && !isActivationSuccess && (
                <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
                  <p className="content-title">
                    {t("parameters:admin.accountActivation.accountActivated.errors.title")}
                  </p>
                  <br />
                  <p className="content-paragraph">
                    <Trans
                      i18nKey="parameters:admin.accountActivation.accountActivated.errors.content"
                      components={{
                        anchor: <a className="anchor-text" />,
                      }}
                    />
                  </p>
                </Col>
              )}
              <Col
                xs={24}
                sm={24}
                md={10}
                lg={10}
                xl={10}
                xxl={10}
                className="svg-container"
              >
                <img
                  alt=""
                  src="/images/account-confirmation.svg"
                  className="confirm-email-svg"
                />
              </Col>
            </Row>
          </Card>
        </Content>
      </Layout>
    </>
  );
};

export default AccountActivation;
