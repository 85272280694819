import { useCallback, useEffect, useRef, useState } from "react";
import MainLayout from "layouts/main";
import styled from "styled-components";
import { Avatar, Checkbox, Col, Row, Space, message, Modal, Form, Select as AntSelect, Table, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import UserStyle from "styles/userStyles";
import { TextButton, UserInfoBanner, Tabs, StarRating, UserStatusCard, FileUploader } from "components";
import {
  deleteUserAvatar,
  getLatestAds,
  getLatestOrders,
  getLatestSales,
  getUser,
  getUserAttribute,
  patchUser,
  patchUserAttribute,
  postUserAddress,
  postUserAttribute,
  uploadAvatar,
  getUserDocs,
  deleteUser,
  generateUserLoginToken,
  getRatings
} from "services/user";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-image-crop/dist/ReactCrop.css";
import { format, sub } from "date-fns";
import { TextField as BaseTextField, Select as BaseSelect, MenuItem, InputLabel } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  validateEmail as emailValidator,
  validatePostCode as postCodeValidator,
  validateAddress as addressValidator,
  validateCity as cityValidator,
  validateName as nameValiidator,
  validatePhoneNumber
} from "../../utils/validators";
import { LOCALE_MAP } from "../../constants";
import COUNTRIES from "../../assets/countries.json";
import * as yup from "yup";
import { fullImageUrl, localizeCurrency, retrieveNumberOfWords } from "utils";
import { Formik } from "formik";
import FormItem from "antd/lib/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import { getCategoriesValues } from "services/category";
import { DescriptionOutlined } from "@material-ui/icons";
import "react-image-crop/dist/ReactCrop.css";

const MIN_AGE = 18;
const MAX_AVATAR_FILE_SIZE = 5;
const MIN_WORDS = 40;
const { Option } = AntSelect;

const UserDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const { t, i18n } = useTranslation(["common", "users"]);
  const [user, setUser] = useState(null);
  const [userErrors, setUserErrors] = useState({
    emailError: "",
    firstnameError: "",
    nameError: "",
    genderError: "",
    dateOfBirthError: "",
    phoneNumberError: "",
  });
  const [addressErrors, setAddressErrors] = useState({
    addressError: "",
    alternativeAddressError: "",
    postCodeError: "",
    cityError: "",
    countryError: "",
  });
  const [isUserFormValid, setIsUserFormValid] = useState(false);
  const [isAddressFormValid, setIsAddressFormValid] = useState(false);
  const [selectedTab, setSelectedTab] = useState("0");
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const [croppedAvatar, setCroppedAvatar] = useState(null);
  const [imageToCrop, setImageToCrop] = useState(null);
  // const [cropModalIsOpen, setCropModalIsOpen] = useState(false);
  const [cropIsValid, setCropIsValid] = useState(false);
  const imageRef = useRef(null);
  const notInitialRender = useRef(false);
  const avatarRef = useRef(null);
  const [isAmbassador, setIsAmbassador] = useState(false);
  const [userAttribute, setUserAttribute] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isLoadingLatestSales, setIsLoadingLatestSales] = useState(false);
  const [latestSales, setLatestSales] = useState([]);
  const [isLoadingLatestOrders, setIsLoadingLatestOrders] = useState(false);
  const [latestOrders, setLatestOrders] = useState([]);
  const [isLoadingLatestAds, setIsLoadingLatestAds] = useState(false);
  const [latestAds, setLatestAds] = useState([]);
  const [isLoadingUserDocs, setIsLoadingUserDocs] = useState(false);
  const [userDocs, setUserDocs] = useState([]);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const [rating, setRating] = useState(null);

  useEffect(() => {
    if (notInitialRender.current) {
      validateUserForm();
    }
  }, [userErrors]);

  useEffect(() => {
    if (notInitialRender.current) {
      validateAddressForm();
    }
  }, [addressErrors]);

  useEffect(() => {
    if (notInitialRender.current) {
      setCropIsValid(!!(crop && crop["height"] && crop["width"] && crop["x"] && crop["y"]));
    }
  }, [crop]);

  const isPro = useCallback(() => user?.role === "PRO", [user?.role]);

  const GRADE_GAMIFICATION = [
    {
      step: isPro() ? "PRO" : "NEO"
    },
    {
      step: isPro() ? "SUPER_PRO" : "SUPER_NEO"
    },
    {
      step: isPro() ? "PRO_STAR" : "NEO_STAR"
    },
    {
      step: "AMBASSADOR"
    },
    {
      step: isPro() ? "PRO_GOLD" : "NEO_GOLD",
      finalStep: true
    }
  ];

  useEffect(() => {
    getUser(id).then((user: any) => {
      console.log('user :>> ', user);
      setUser(user);
      setCroppedAvatar(fullImageUrl(user?.imageUrls));

      if (user && user.role && user.role === "PRO") {
        getUserAttributs();
        getCategories();
      }

      setIsLoadingLatestSales(true);
      setIsLoadingLatestOrders(true);
      setIsLoadingLatestAds(true);
      setIsLoadingUserDocs(true);


      getRatings(id)
        .then((resp) => setRating(resp))

      getLatestSales(id)
        .then((resp) => setLatestSales(resp))
        .catch((e) => message.error(t`common:errors.commonError`, 10))
        .finally(() => setIsLoadingLatestSales(false));

      getLatestOrders(id)
        .then((resp) => setLatestOrders(resp))
        .catch((e) => message.error(t`common:errors.commonError`, 10))
        .finally(() => setIsLoadingLatestOrders(false));

      getLatestAds(id)
        .then((resp) => setLatestAds(resp))
        .catch((e) => message.error(t`common:errors.commonError`, 10))
        .finally(() => setIsLoadingLatestAds(false));

      getUserDocs(id)
        .then((resp) => setUserDocs(resp))
        .catch((e) => message.error(t`common:errors.commonError`, 10))
        .finally(() => setIsLoadingUserDocs(false));
    }).catch(e => {
      if (e?.response?.status === 404) {
        // redirect to /utilisateurs if ID is not found
        history.push("/utilisateurs");
      }
    });

    if (!notInitialRender.current) {
      notInitialRender.current = true
    }
  }, []);

  useEffect(() => {
    GRADE_GAMIFICATION.map((item, index) => {
      if (user?.grade === item.step) {
        return setCurrentStepIndex(index);
      }
    });
  }, [user?.role]);

  const variant = useCallback(() => user?.role === "NEO" ? "darkBlue" : "salmon", [user?.role]);

  const getUserAttributs = async () => {

    try {
      const userAttr = await getUserAttribute(id);

      if (userAttr) {
        setUserAttribute(userAttr);
        setIsAmbassador(userAttr?.isAmbassador);
      }
    } catch (error) {
      setUserAttribute(null);
    }

  }


  const getCategories = () => {
    getCategoriesValues()
      .then((resp) => {
        console.log(resp);
        setCategories(resp);
      })
      .catch((e) => console.log(e))
  };

  const emailOnChange = (event) => {
    setUser({ ...user, email: event.target.value });
    validateEmail(event.target.value);
  }

  const firstnameOnChange = (event) => {
    setUser({ ...user, firstname: event.target.value });
    validateFirstname(event.target.value);
  }

  const nameOnChange = (event) => {
    setUser({ ...user, name: event.target.value });
    validateName(event.target.value);
  }

  const genderOnChange = (event) => {
    setUser({ ...user, gender: event.target.value });
    validateGender(event.target.value);
  }

  const dateOfBirthOnChange = (value) => {
    setUser({ ...user, dateOfBirth: value.toISOString() });
    validateDateOfBirth(value);
  }

  const phoneNumberOnChange = (event) => {
    setUser({ ...user, phoneNumber: event.target.value });
    handleValidatePhoneNumber(event.target.value);
  }

  const addressOnChange = (event) => {
    setUser({ ...user, userAddress: { ...user.userAddress, address: event.target.value } });
    validateAddress(event.target.value);
  }

  const alternativeAddressOnChange = (event) => {
    setUser({ ...user, userAddress: { ...user.userAddress, alternativeAddress: event.target.value } });
    validateAlternativeAddress(event.target.value);
  }

  const postCodeOnChange = (event) => {
    setUser({ ...user, userAddress: { ...user.userAddress, postCode: event.target.value } });
    validatePostCode(event.target.value);
  }

  const cityOnChange = (event) => {
    setUser({ ...user, userAddress: { ...user.userAddress, city: event.target.value } });
    validateCity(event.target.value);
  }

  const countryOnChange = (event) => {
    setUser({ ...user, userAddress: { ...user.userAddress, country: event.target.value } });
    validateCountry(event.target.value);
  }

  const userColumns = [
    {
      key: "email",
      onChange: emailOnChange,
      error: userErrors.emailError,
      type: "text",
      required: true,
    },
    {
      key: "firstname",
      onChange: firstnameOnChange,
      error: userErrors.firstnameError,
      type: "text",
      required: true,
    },
    {
      key: "name",
      onChange: nameOnChange,
      error: userErrors.nameError,
      type: "text",
      required: true,
    },
    {
      key: "gender",
      onChange: genderOnChange,
      error: userErrors.genderError,
      required: false,
      type: "select",
      options: {
        male: t`common:male`,
        female: t`common:female`
      }
    },
    {
      key: "dateOfBirth",
      onChange: dateOfBirthOnChange,
      error: userErrors.dateOfBirthError,
      required: false,
      type: "date",
      options: {
        minDate: null,
        maxDate: sub(new Date(), { years: MIN_AGE }),
      }
    },
    {
      key: "phoneNumber",
      onChange: phoneNumberOnChange,
      error: userErrors.phoneNumberError,
      type: "text",
      required: false,
    },
  ];

  const addressColumns = [
    {
      key: "address",
      onChange: addressOnChange,
      error: addressErrors.addressError,
      type: "text",
      required: true,
    },
    {
      key: "alternativeAddress",
      onChange: alternativeAddressOnChange,
      error: addressErrors.alternativeAddressError,
      type: "text",
      required: false,
    },
    {
      key: "postCode",
      onChange: postCodeOnChange,
      error: addressErrors.postCodeError,
      type: "text",
      required: true,
    },
    {
      key: "city",
      onChange: cityOnChange,
      error: addressErrors.cityError,
      type: "text",
      required: true,
    },
    {
      key: "country",
      onChange: countryOnChange,
      error: addressErrors.countryError,
      type: "select",
      required: true,
      options: {

      }
    },
  ];

  const validateUserForm = () => {
    const concatenatedErrors = Object.values(userErrors).reduce((acc, err) => acc + err, "");
    setIsUserFormValid(!concatenatedErrors);
  }

  const validateAddressForm = () => {
    const concatenatedErrors = Object.values(addressErrors).reduce((acc, err) => acc + err, "");
    setIsAddressFormValid(!concatenatedErrors);
  }

  const validateEmail = (email) => {
    let emailError = "";

    if (userColumns.find(userColumn => userColumn.key === "email")?.required && !email) {
      emailError = t`users:form.errors.email.blank`;
    }
    else if (!emailValidator(email)) {
      emailError = t`users:form.errors.email.invalid`;
    }

    setUserErrors({ ...userErrors, emailError });
  }

  const validateFirstname = (firstname) => {
    let firstnameError = "";

    if (userColumns.find(userColumn => userColumn.key === "firstname")?.required && !firstname) {
      firstnameError = t`users:form.errors.firstname.blank`;
    } else if (!nameValiidator(firstname)) {
      firstnameError = t`users:form.errors.firstname.invalid`;
    }

    setUserErrors({ ...userErrors, firstnameError });
  }

  const validateName = (name) => {
    let nameError = "";

    if (userColumns.find(userColumn => userColumn.key === "name")?.required && !name) {
      nameError = t`users:form.errors.name.blank`;
    } else if (!nameValiidator(name)) {
      nameError = t`users:form.errors.name.invalid`;
    }

    setUserErrors({ ...userErrors, nameError });
  }

  const validateGender = (gender) => {
    let genderError = "";

    setUserErrors({ ...userErrors, genderError });
  }

  const validateDateOfBirth = (dateOfBirth) => {
    let dateOfBirthError = "";

    if (userColumns.find(userColumn => userColumn.key === "dateOfBirth")?.required && !dateOfBirth) {
      dateOfBirthError = t`users:form.errors.dateOfBirth.blank`;
    }

    setUserErrors({ ...userErrors, dateOfBirthError });
  }

  const handleValidatePhoneNumber = async (phoneNumber) => {
    let phoneNumberError = "";
    const isPhoneValid = await validatePhoneNumber(phoneNumber);

    if (userColumns.find(userColumn => userColumn.key === "phoneNumber")?.required && !phoneNumber) {
      phoneNumberError = t`users:form.errors.phoneNumber.blank`;
    }
    else if (phoneNumber && !isPhoneValid) {
      phoneNumberError = t`users:form.errors.phoneNumber.invalid`;
    }
    //!userColumns.find(userColumn => userColumn.key === "phoneNumber")?.required && 

    setUserErrors({ ...userErrors, phoneNumberError });
  }

  const validateAddress = (address) => {
    let addressError = "";

    if (addressColumns.find(addressColumn => addressColumn.key === "address")?.required && !address) {
      addressError = t`users:form.errors.address.blank`;
    } else if (!addressColumns.find(addressColumn => addressColumn.key === "address")?.required && address && !addressValidator(address)) {
      addressError = t`users:form.errors.address.invalid`;
    }

    setAddressErrors({ ...addressErrors, addressError });
  }

  const validateAlternativeAddress = (alternativeAddress) => {
    let alternativeAddressError = "";

    if (addressColumns.find(addressColumn => addressColumn.key === "alternativeAddress")?.required && !alternativeAddress) {
      alternativeAddressError = t`users:form.errors.alternativeAddress.blank`;
    } else if (!addressColumns.find(addressColumn => addressColumn.key === "alternativeAddress") && alternativeAddress && !addressValidator(alternativeAddress)) {
      alternativeAddressError = t`users:form.errors.alternativeAddress.invalid`;
    }

    setAddressErrors({ ...addressErrors, alternativeAddressError });
  }

  const validatePostCode = (postCode) => {
    let postCodeError = "";

    if (addressColumns.find(addressColumn => addressColumn.key === "postCode")?.required && !postCode) {
      postCodeError = t`users:form.errors.postCode.blank`;
    }
    else if (!postCodeValidator(postCode)) {
      postCodeError = t`users:form.errors.postCode.invalid`;
    }

    setAddressErrors({ ...addressErrors, postCodeError });
  }

  const validateCity = (city) => {
    let cityError = "";

    if (addressColumns.find(addressColumn => addressColumn.key === "city")?.required && !city) {
      cityError = t`users:form.errors.city.blank`;
    } else if (!cityValidator(city)) {
      cityError = t`users:form.errors.city.invalid`;
    }

    setAddressErrors({ ...addressErrors, cityError });
  }

  const validateCountry = (country) => {
    let countryError = "";

    if (addressColumns.find(addressColumn => addressColumn.key === "country")?.required && !country) {
      countryError = t`users:form.errors.country.blank`;
    }

    setAddressErrors({ ...addressErrors, countryError });
  }

  const handleTabChanged = index => setSelectedTab(index);

  const tabs = [
    {
      title: t`users:tabs.details`
    },
    {
      title: t`users:tabs.edit`
    },
    {
      title: t`users:tabs.others`
    }
  ];

  const onAmbassadorChange = async (e) => {
    console.log('Ambassador change: ', e.target.checked);
    setIsAmbassador(e.target.checked);
    if (userAttribute) {
      await patchUserAttribute(id, { isAmbassador: e.target.checked })
    } else {
      await postUserAttribute(id, { isAmbassador: e.target.checked, userId: parseInt(id) })
    }
  }

  const saveUser = async () => {
    const {
      email,
      firstname,
      name,
      gender,
      dateOfBirth,
      phoneNumber,
    } = user;

    const requestBody = {
      email: email || "",
      firstname: firstname || "",
      name: name || "",
      gender: gender || "",
      dateOfBirth: format(new Date(dateOfBirth), "yyyy-MM-dd"),
      phoneNumber: phoneNumber || "",
    }

    setIsUserFormValid(false);
    try {
      await patchUser(user?.id, requestBody);
      message.success(t`common:message.success`, 5);
    } catch (error) {
      if (error?.response?.data?.error?.message === "duplicateEmail")
        message.error(t`users:form.errors.email.duplicateEmail`, 5);
      else
        message.error(t`common:unexpectedError`, 5);
    }
  };

  const saveAddress = () => {
    const {
      id,
      address,
      alternativeAddress,
      city,
      postCode,
      country,
    } = user.userAddress;

    const requestBody = {
      id,
      address: address || "",
      alternativeAddress: alternativeAddress || "",
      city: city || "",
      postCode: postCode || "",
      country: country || "",
      userId: user.id
    }

    setIsAddressFormValid(false);
    postUserAddress(requestBody);
  }

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth > 500 ? image.naturalWidth / 500 : 1;
    const scaleY = image.naturalHeight > 500 ? scaleX : 1;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As a blob
    return new Promise((resolve, reject) => {
      // As Base64 string
      const base64 = canvas.toDataURL('image/jpeg');

      canvas.toBlob(blob => {
        blob["name"] = fileName;
        resolve({ base64, blob });
      }, 'image/jpeg', 1);
    });
  }

  const deleteAvatar = async () => {
    const result = await deleteUserAvatar(user?.id);
    setCroppedAvatar(null);
    setUser({ ...user, userImage: null });
  }

  const deleteAccount = () => {
    deleteUser(id);
    history.push("/utilisateurs");
  }

  const openDeleteAccountModal = () => (
    Modal.error({
      title: t`users:account.deleteAccount`,
      content: t`users:account.deleteAccountBody`,
      okCancel: true,
      okText: t`common:yes`,
      cancelText: t`common:no`,
      onOk: deleteAccount,
    })
  );

  const goToProPublicProfile = () => {
    window.open(
      `${process.env.REACT_APP_WEBAPP_URL}ambassadeurs/${user?.id}`,
      "_blank"
    );
  };

  const logIntoAccount = () => {
    generateUserLoginToken(user?.id).then((resp) => {
      window.open(
        `${process.env.REACT_APP_WEBAPP_URL}connexion?token=${resp?.userAccessCode}`,
        "_blank"
      );
    }).catch(e => {
      message.error(t`common:unexpectedError`, 5);
    });
  };

  const renderDetailsTabContent = () => {
    const latestSalesColumns = [
      {
        key: "booking",
        title: t`users:latestSales.columns.booking`,
        dataIndex: "bookingNumber",
        align: "center" as "center",
      },
      {
        key: "seller",
        title: t`users:latestSales.columns.seller`,
        dataIndex: "seller",
        align: "center" as "center",
      },
      {
        key: "buyer",
        title: t`users:latestSales.columns.buyer`,
        dataIndex: "client",
        align: "center" as "center",
      },
      {
        key: "total",
        title: t`users:latestSales.columns.total`,
        dataIndex: "amount",
        render: (amount: string) => `${localizeCurrency(
          +amount || 0,
          i18n.language
        )}€`,
        align: "right" as "right",
      },
      {
        key: "status",
        title: t`users:latestSales.columns.status`,
        dataIndex: "status",
        render: (status: string) => (
          <div className={`booking-status-pill booking-status-${(status || "").toLowerCase()}`}>
            {status ? t(`common:bookingStatus.${status}`) : ""}
          </div>
        ),
        align: "center" as "center",
      },
      {
        key: "activityDate",
        title: t`users:latestSales.columns.activityDate`,
        dataIndex: "activityDate",
        render: (date: string) => format(new Date(date), `dd/MM/yyyy`),
        align: "center" as "center"
      },
      {
        key: "activityTime",
        title: t`users:latestSales.columns.activityTime`,
        dataIndex: "activityDate",
        render: (date: string) => `${format(new Date(date), "HH")}h${format(new Date(date), "mm")}`,
        align: "center" as "center",
      },
      {
        key: "bookingDate",
        title: t`users:latestSales.columns.bookingDate`,
        dataIndex: "bookingDate",
        render: (date: string) => `${format(new Date(date), `dd/MM/yyyy ${t("common:at")} HH`)}h${format(new Date(date), "mm")}`,
        align: "center" as "center",
      },
    ];

    const latestOrdersColumns = [
      {
        key: "booking",
        title: t`users:latestSales.columns.booking`,
        dataIndex: "bookingNumber",
        align: "center" as "center",
      },
      {
        key: "seller",
        title: t`users:latestSales.columns.seller`,
        dataIndex: "seller",
        align: "center" as "center",
      },
      {
        key: "buyer",
        title: t`users:latestSales.columns.buyer`,
        dataIndex: "client",
        align: "center" as "center",
      },
      {
        key: "total",
        title: t`users:latestSales.columns.total`,
        dataIndex: "amount",
        render: (amount: string) => `${localizeCurrency(
          +amount || 0,
          i18n.language
        )}€`,
        align: "right" as "right",
      },
      {
        key: "status",
        title: t`users:latestSales.columns.status`,
        dataIndex: "status",
        render: (status: string) => (
          <div className={`booking-status-pill booking-status-${(status || "").toLowerCase()}`}>
            {status ? t(`common:bookingStatus.${status}`) : ""}
          </div>
        ),
        align: "center" as "center",
      },
      {
        key: "activityDate",
        title: t`users:latestSales.columns.activityDate`,
        dataIndex: "activityDate",
        render: (date: string) => format(new Date(date), `dd/MM/yyyy`),
        align: "center" as "center"
      },
      {
        key: "activityTime",
        title: t`users:latestSales.columns.activityTime`,
        dataIndex: "activityDate",
        render: (date: string) => `${format(new Date(date), "HH")}h${format(new Date(date), "mm")}`,
        align: "center" as "center",
      },
      {
        key: "bookingDate",
        title: t`users:latestSales.columns.bookingDate`,
        dataIndex: "bookingDate",
        render: (date: string) => `${format(new Date(date), `dd/MM/yyyy ${t("common:at")} HH`)}h${format(new Date(date), "mm")}`,
        align: "center" as "center",
      },
    ];

    const latestAdsColumns = [
      {
        key: "id",
        title: t`users:latestAds.columns.id`,
        dataIndex: "id",
        align: "center" as "center",
      },
      {
        key: "title",
        title: t`users:latestAds.columns.title`,
        dataIndex: "title",
        render: (title: string, { id }) => (
          <Link to={`/annonces/${id || ""}`} target="_blank" className="user-latest-ads-activity-title">{title}</Link>
        ),
      },
      {
        key: "price",
        title: t`users:latestAds.columns.price`,
        dataIndex: "price",
        render: (price: string) => `${localizeCurrency(
          +price || 0,
          i18n.language
        )}€`,
        align: "center" as "center",
      },
      {
        key: "createdAt",
        title: t`users:latestAds.columns.activityDate`,
        dataIndex: "createdAt",
        render: (date: string) => `${format(new Date(date), `dd/MM/yyyy ${t("common:at")} HH`)}h${format(new Date(date), "mm")}`,
        align: "center" as "center",
      },
      {
        key: "online",
        title: t`users:latestAds.columns.online`,
        dataIndex: "online",
        render: (online: string) => (
          <div className={`user-latest-ads-online user-latest-ads-online-${!!online}`}>
            {t(`common:${online ? "yes" : "no"}`)}
          </div>
        ),
        align: "center" as "center",
      },
      {
        key: "adminResponsible",
        title: t`users:latestAds.columns.adminResponsible`,
        dataIndex: "adminResponsible",
        align: "center" as "center"
      },
    ];

    return (
      <>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <Row gutter={[8, 8]}>
            <Col span={24}>
              <div className="user-card">
                <h2 className="user-card-heading">{t`users:latestSalesSection`}</h2>
                <div className="user-card-table-container">
                  <Table
                    rowKey={record => record.id}
                    loading={isLoadingLatestSales}
                    columns={latestSalesColumns}
                    dataSource={latestSales}
                    locale={{ emptyText: t("users:latestSales.noData", { name: user?.firstname || t`users:thisUser` }) }}
                    pagination={{
                      pageSize: 10,
                      defaultPageSize: 10,
                      total: latestSales.length,
                      showSizeChanger: false
                    }}
                  />
                </div>
              </div>
            </Col>
            <Col span={24}>
              <div className="user-card">
                <h2 className="user-card-heading">{t`users:latestOrdersSection`}</h2>
                <div className="user-card-table-container">
                  <Table
                    rowKey={record => record.id}
                    loading={isLoadingLatestOrders}
                    columns={latestOrdersColumns}
                    dataSource={latestOrders}
                    locale={{ emptyText: t("users:latestOrders.noData", { name: user?.firstname || t`users:thisUser` }) }}
                    pagination={{
                      pageSize: 10,
                      defaultPageSize: 10,
                      total: latestOrders.length,
                      showSizeChanger: false
                    }}
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
          <div className="user-card">
            <h2 className="user-card-heading">{t`users:latestAdsSection`}</h2>
            <div className="user-card-table-container">
              <Table
                rowKey={record => record.id}
                loading={isLoadingLatestAds}
                columns={latestAdsColumns}
                dataSource={latestAds}
                locale={{ emptyText: t("users:latestAds.noData", { name: user?.firstname || t`users:thisUser` }) }}
                pagination={{
                  pageSize: 10,
                  defaultPageSize: 10,
                  total: latestAds.length,
                  showSizeChanger: false
                }}
              />
            </div>
          </div>
        </Col>
      </>
    )
  }

  // const handleNextLevel = () => {
  //   let nextGrade = currentStepIndex;

  //   if (!GRADE_GAMIFICATION[currentStepIndex]?.finalStep) {
  //     nextGrade++;
  //     setCurrentStepIndex(nextGrade);

  //     const requestBody = {
  //       grade: GRADE_GAMIFICATION[nextGrade].step
  //     };

  //     patchUser(user?.id, requestBody)
  //       .then((resp) => {
  //         console.log('resp :>> ', resp);
  //         // message.success(t`common:message.success`, 5);
  //       })
  //       .catch((error) => {
  //         console.log('error?.response?.data :>> ', error, error?.response, error?.response?.data);
  //         message.error(t`common:unexpectedError`, 5);
  //       });
  //   }
  // };

  const activityTypes = useCallback(() => {
    return categories?.filter((item) => item.parentCategoryId === null);
  }, [categories]);

  const validationSchema = yup.object().shape({
    profession: yup
      .string()
      .matches(
        /^([a-zA-Z0-9À-ÿ\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 -,.()]+)$/,
        t`common:errors.noSpecialCharacters`
      ),
    description: yup
      .string()
      .test(
        "len",
        t("common:errors.minWords", { count: MIN_WORDS }),
        (val) => !!(retrieveNumberOfWords(val) >= MIN_WORDS)
      ),
    activity: yup.string().required(t`common:errors.requiredField`),
    category: yup.string().required(t`common:errors.requiredField`),
  });

  const handleProfilePictureUpload = ({ blob, base64, file }) => {
    setCroppedAvatar(base64);
    setIsUploadingAvatar(true);

    if (base64) {
      const fileName = file.name;
      const fileType = file.type;
      const headers = {
        "Content-Type": "multipart/form-data",
        "Mime-Type": fileType,
      };
      const payload = new FormData();
      payload.append("avatar", blob, fileName);

      uploadAvatar(user?.id, blob, fileName).catch(e => {
        message.error(t`common:errors.commonError`, 10);
      }).finally(() => {
        setIsUploadingAvatar(false);
      });
    }
  };

  const uploadNewProfilePicture = () => avatarRef?.current?.click();

  const renderProfessionalForm = () => {
    return (
      <Formik
        initialValues={{
          description: userAttribute?.description || "",
          profession: userAttribute?.profession || "",
          activity: userAttribute?.categoryId || null,
          category: userAttribute?.subCategoryId || null,
        }}
        validateOnChange={true}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          const requestBody = {
            description: values.description,
            profession: values.profession,
            categoryId: values.activity,
            subCategoryId: values.category
          };

          patchUserAttribute(id, requestBody)
            .then((resp: any) => {
              message.success(t`common:success.saveSuccessfully`, 10);
            })
            .catch((e) => message.error(e?.response?.data?.message, 5))
            .finally(() => setSubmitting(false))
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
          dirty,
          isValid,
          setFieldTouched,
          setFieldValue,
        }) => {
          const retrieveErrors = (field) =>
            (touched[field] && errors[field]) || "";
          const handleChangeField = (e) => {
            e?.target?.name &&
              !touched[e.target.name] &&
              setFieldTouched(e.target.name);
            handleChange(e);
          };

          const handleChangeFieldByName = (name) => (e) => {
            setFieldTouched(name);
            setFieldValue(name, e.target.value);
          };

          return (
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              className="professional-info-container"
            >
              <FormItem
                help={retrieveErrors("profession")}
                validateStatus={retrieveErrors("profession") && "error"}
                label={t("users:form.profession")}
              >
                <TextField
                  name="profession"
                  onChange={handleChangeField}
                  value={values.profession}
                  radius="0.3125rem"
                  height="2.75rem"
                />
              </FormItem>
              <FormItem
                help={retrieveErrors("description")}
                validateStatus={retrieveErrors("description") && "error"}
                label={t("users:form.description")}
              >
                <TextArea name="description" onChange={handleChangeField} value={values.description} autoSize={{ minRows: 3, maxRows: 6 }} />
              </FormItem>
              <FormItem label={t`users:form.activity`}>
                <Select
                  className="select-container"
                  onChange={handleChangeFieldByName("activity")}
                  value={values.activity}
                >
                  {activityTypes()?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormItem>
              <FormItem label={t`users:form.category`}>
                <Select
                  className="select-container"
                  onChange={handleChangeFieldByName("category")}
                  value={values.category}
                >
                  {categories?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormItem>
              <TextButton
                htmlType="submit"
                rounded={true}
                variant="blue"
                width="14.5rem"
                disabled={!isValid || isSubmitting || !dirty}
              >
                {t`common:save`}
              </TextButton>
            </Form>
          );
        }}
      </Formik>)
  };

  const goToEvaluations = (type: String) => {
    history.push(`/evaluations?user_id=${id}&eval_type=${type}`)
  }

  const ratingValue = () => {
    if (rating && rating.averageNote) {
      if (rating.averageNote.split('/')[0]) {
        return +rating.averageNote.split('/')[0].trim();
      }
    }
    return 0;
  }
  const renderEditTabContent = () => {
    return (
      <>
        {/* {
          cropModalIsOpen &&
          <Dialog
            onClose={closeCropModal}
            aria-labelledby="crop-dialog"
            open={cropModalIsOpen}
            className="react-crop-container"
          >
            <ReactCrop
              ref={imageRef}
              className="avatar-react-crop"
              ruleOfThirds
              circularCrop
              src={imageToCrop}
              crop={crop}
              onChange={newCrop => setCrop(newCrop)}
            />
            <Row className="react-crop-btn-container">
              <TextButton variant="salmon" rounded width="100%" onClick={saveAvatar} disabled={!cropIsValid}>{t`common:save`}</TextButton>
            </Row>
          </Dialog>
        } */}
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <div className="user-card personal-profile-card">
            <svg xmlns="http://www.w3.org/2000/svg" width="25.835" height="28.082" viewBox="0 0 25.835 28.082" className="personal-profile-icon blurred-circle">
              <defs>
                <filter id="Ellipse_429" x="0" y="0" width="25.835" height="28.082" filterUnits="userSpaceOnUse">
                  <feOffset />
                  <feGaussianBlur stdDeviation="1.5" result="blur" />
                  <feFlood floodOpacity="0.161" />
                  <feComposite operator="in" in2="blur" />
                  <feComposite in="SourceGraphic" />
                </filter>
              </defs>
              <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Ellipse_429)">
                <ellipse id="Ellipse_429-2" data-name="Ellipse 429" cx="8.418" cy="9.541" rx="8.418" ry="9.541" transform="translate(4.5 4.5)" fill="#fff" />
              </g>
            </svg>

            <svg xmlns="http://www.w3.org/2000/svg" width="75.554" height="65.747" viewBox="0 0 75.554 65.747" className="personal-profile-icon users-icon">
              <g id="Groupe_2149" data-name="Groupe 2149" transform="translate(1 1)">
                <path id="Tracé_1295" data-name="Tracé 1295" d="M44.6,39.484h0a15.242,15.242,0,1,0-15.2-15.2A15.312,15.312,0,0,0,44.6,39.484Zm0-25.58a10.38,10.38,0,0,1,0,20.759h0a10.38,10.38,0,0,1,0-20.759Z" transform="translate(-7.824 -9)" fill="#003466" stroke="#fff" strokeWidth="2" />
                <path id="Tracé_1296" data-name="Tracé 1296" d="M71.99,48.98A8.99,8.99,0,1,0,63,39.99,8.964,8.964,0,0,0,71.99,48.98Zm0-13.076A4.086,4.086,0,1,1,67.9,39.99,4.047,4.047,0,0,1,71.99,35.9Z" transform="translate(-13.964 -13.02)" fill="#003466" stroke="#fff" strokeWidth="2" />
                <path id="Tracé_1297" data-name="Tracé 1297" d="M67.564,52.668h-.817a2.535,2.535,0,0,0-1.8.736c-1.144,1.226-1.635,1.635-2.779,1.635A18.307,18.307,0,0,0,52.445,51.2a2.242,2.242,0,0,0-1.88.654,15.062,15.062,0,0,1-10.788,4.331A15.554,15.554,0,0,1,28.989,51.85a2.334,2.334,0,0,0-1.88-.654,17.09,17.09,0,0,0-9.725,3.841c-1.144-.082-1.635-.409-2.779-1.716a2.856,2.856,0,0,0-1.8-.654H11.99A8.964,8.964,0,0,0,3,61.658v.817A5.686,5.686,0,0,0,8.721,68.2h1.8c0,.327-.082.736-.082,1.062v3.024a8.2,8.2,0,0,0,8.173,8.173H60.862a8.2,8.2,0,0,0,8.173-8.173V69.258a4.66,4.66,0,0,0-.082-1.062h1.88a5.686,5.686,0,0,0,5.721-5.721v-.817A8.964,8.964,0,0,0,67.564,52.668ZM8.721,63.292a.772.772,0,0,1-.817-.817v-.817a4.053,4.053,0,0,1,3.841-4.086,10.636,10.636,0,0,0,1.88,1.471,19.034,19.034,0,0,0-2.207,4.25Zm55.492,8.99a3.279,3.279,0,0,1-3.269,3.269H18.61a3.279,3.279,0,0,1-3.269-3.269V69.258A13.1,13.1,0,0,1,26.456,56.345a20.389,20.389,0,0,0,13.321,4.985A19.97,19.97,0,0,0,53.1,56.345a13.3,13.3,0,0,1,10.461,8.908c0,.163-.082.327-.082.572a2.58,2.58,0,0,0,.572,1.553,15.871,15.871,0,0,1,.163,1.961Zm7.437-9.807a.772.772,0,0,1-.817.817H68.054a19.034,19.034,0,0,0-2.207-4.25,8.319,8.319,0,0,0,1.88-1.471,4.123,4.123,0,0,1,3.923,4.086v.817Z" transform="translate(-3 -16.708)" fill="#003466" stroke="#fff" strokeWidth="2" />
                <path id="Tracé_1298" data-name="Tracé 1298" d="M11,39.99A8.99,8.99,0,1,0,19.99,31,8.964,8.964,0,0,0,11,39.99Zm13.076,0A4.086,4.086,0,1,1,19.99,35.9,4.047,4.047,0,0,1,24.076,39.99Z" transform="translate(-4.462 -13.02)" fill="#003466" stroke="#fff" strokeWidth="2" />
              </g>
            </svg>

            <h2 className="user-card-heading personal-profile-heading">{t`users:personalProfile`}</h2>
            <p className="member">{t("users:memberSince", { date: user?.createdAt ? format(new Date(user?.createdAt), "dd/MM/yyyy") : "" })}</p>

            <Row gutter={[0, 24]} className="form-container">
              {
                userColumns.map(userColumn => (
                  <Col span={24} key={userColumn.key}>
                    <div className="form-textfield-container" key={userColumn.key}>
                      {
                        userColumn.type === "date" &&
                        <MuiPickersUtilsProvider locale={LOCALE_MAP[i18n.language]} utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            label={t(`users:form.${userColumn.key}`)}
                            format="dd/MM/yyyy"
                            value={(user && user[userColumn.key] && new Date(user && user[userColumn.key])) || null}
                            // value={new Date()}
                            // minDate={userColumn.options.minDate}
                            maxDate={userColumn.options.maxDate}
                            InputProps={{ readOnly: true }}
                            onChange={userColumn.onChange}
                            KeyboardButtonProps={{
                              'aria-label': 'change date',
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      }
                      {
                        userColumn.type === "select" &&
                        <>
                          <InputLabel shrink>
                            {t(`users:form.${userColumn.key}`)}
                          </InputLabel>
                          <Select
                            value={user && user[userColumn.key]}
                            onChange={userColumn.onChange}
                          >
                            {
                              Object.entries(userColumn.options).map(([key, value]) => <MenuItem key={key} value={key}>{value}</MenuItem>)
                            }
                          </Select>
                        </>
                      }
                      {
                        userColumn.type === "text" &&
                        <TextField
                          label={t(`users:form.${userColumn.key}`)}
                          value={(user && user[userColumn.key]) || ""}
                          onChange={userColumn.onChange}
                          helperText={userColumn.error}
                        />
                      }
                    </div>
                  </Col>
                ))
              }
              <Col span={24}>
                <div className="form-textfield-container">
                  <TextButton variant="salmon" rounded width="100%" onClick={saveUser} disabled={!isUserFormValid}>{t`common:save`}</TextButton>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <div className="user-card">
            <h2 className="user-card-heading">{t`users:otherInfo`}</h2>
            <Row gutter={[0, 24]} className="form-container">
              {
                addressColumns.map(addressColumn => (
                  <Col span={24} key={addressColumn.key}>
                    <div className="form-textfield-container" key={addressColumn.key}>
                      {addressColumn.type === "text" &&
                        <TextField
                          label={t(`users:form.${addressColumn.key}`)}
                          value={(user?.userAddress && user.userAddress[addressColumn.key]) || ""}
                          onChange={addressColumn.onChange}
                          helperText={addressColumn.error}
                        />
                      }
                      {
                        addressColumn.type === "select" &&
                        <>
                          <InputLabel shrink>
                            {t(`users:form.${addressColumn.key}`)}
                          </InputLabel>
                          <Select
                            // value={(user?.userAddress && user.userAddress[addressColumn.key]) || ""}
                            value={(user?.userAddress && user?.userAddress?.country?.toLowerCase()) || ""}
                            onChange={addressColumn.onChange}
                          >
                            {
                              COUNTRIES?.map((c) => <MenuItem key={c.id} value={c.alpha2}>{c.name}</MenuItem>)
                            }
                          </Select>
                        </>
                      }
                    </div>

                  </Col>
                ))
              }
              <Col span={24}>
                <div className="form-textfield-container">
                  <TextButton variant="salmon" rounded width="100%" onClick={saveAddress} disabled={!isAddressFormValid}>{t`common:save`}</TextButton>
                </div>
              </Col>
              <Col span={24}>
                <div className="member">
                  {
                    user && user.role && user.role === 'PRO' && (
                      <Checkbox onChange={onAmbassadorChange} checked={isAmbassador}>{t`users:form.isAmbassador`}</Checkbox>
                    )
                  }
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6} className="user-info-col">
          <div className="user-card evaluations-card">
            <div>
              <h2 className="user-card-heading">{t`users:evaluations`}</h2>
            </div>
            <div>
              <p className="rating-text">{rating && rating.averageNote}</p>
            </div>
            <div>
              <StarRating
                defaultRating={0}
                ratingPrecision={0.5}
                ratingValue={ratingValue()}
                maxRating={5}
              />
            </div>
            <div>
              <p className="rating-count">({t("users:ratingCount", { count: rating.ratings })})</p>
            </div>
            <div className="evaluations-links-container">
              {isPro() && <Button type="link" onClick={() => goToEvaluations('received')} className="evaluations-link">{t("users:evaluationsReceivedBy", { name: user?.firstname })}</Button>}
              {!isPro() && <Button type="link" onClick={() => goToEvaluations('sent')} className="evaluations-link">{t("users:evaluationsSentBy", { name: user?.firstname })}</Button>}
            </div>
          </div>
          {isPro() && <div className="user-card">
            <h2 className="user-card-heading">{t`users:proInfo`}</h2>
            {renderProfessionalForm()}
          </div>}
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} xl={6} xxl={6}>
          <div className="user-card">
            <Col span={24}>
              <h2 className="user-card-heading">{t`users:avatar`}</h2>
            </Col>
            <Col span={24} className="profile-picture-image-uploader-container">
              {/* <Avatar size={{xs: 100, sm: 200, md: 200, lg: 200, xl: 200, xxl: 256}} src={croppedAvatar} className="user-avatar" icon={<UserOutlined />} /> */}
              <FileUploader
                disabled={isUploadingAvatar}
                uploadRef={avatarRef}
                acceptMultipleFiles={false}
                showUploadList={false}
                includeCropper={true}
                maxFileSize={MAX_AVATAR_FILE_SIZE * 1024 * 1024}
                uploadFile={handleProfilePictureUpload}
                listType="picture-card"
                className="profile-picture-image-uploader"
                cropProps={{
                  shape: "round",
                }}
                customRequest={
                  ({ file, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess("ok");
                    }, 0);
                  }}
              >
                <Avatar
                  src={croppedAvatar}
                  icon={<UserOutlined />}
                  size={240}
                />
              </FileUploader>
            </Col>
            <div className="avatar-btns-container">
              <Col span={24} className="avatar-btn-container">
                <TextButton variant="salmon" type="outlined" rounded width="100%" disabled={!user?.userImage} onClick={deleteAvatar}>{t`users:deletePhoto`}</TextButton>
              </Col>
              {/* <Col span={24} className="avatar-btn-container edit-photo-container">
                <TextButton variant="salmon" rounded  width="100%" onClick={clickAvatarInput}>
                  {t`users:editPhoto`}
                </TextButton>
                <input ref={avatarRef} type="file" accept=".jpg, .jpeg, .png" onChange={browseImage} className="avatar-file-input"/>
              </Col> */}
              <Col span={24} className="avatar-btn-container edit-photo-container">
                <TextButton
                  variant="salmon"
                  rounded={true}
                  width="100%"
                  onClick={uploadNewProfilePicture}
                  disabled={isUploadingAvatar}
                >
                  {t`users:editPhoto`}
                </TextButton>
              </Col>
            </div>
          </div>
        </Col>
      </>
    )
  }

  const renderOthersTabContent = () => {
    return (
      <Formik
        initialValues={{
          isActive: +(user?.isActive) ?? 1,
        }}
        validateOnChange={true}
        // validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          patchUser(user?.id, {
            isActive: !!values.isActive
          })
            .then(() => message.success(t`common:message.success`, 5))
            .catch(e => message.success(t`common:errors.saveError`, 10))
            .finally(() => setSubmitting(false));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          handleSubmit,
          dirty,
          isValid,
          setFieldTouched,
          setFieldValue,
        }) => {
          const retrieveErrors = (field) =>
            (touched[field] && errors[field]) || "";

          const handleChangeField = (e) => {
            e?.target?.name &&
              !touched[e.target.name] &&
              setFieldTouched(e.target.name);
            handleChange(e);
          };

          const handleChangeFieldByName = (name) => (value) => {
            setFieldTouched(name);
            setFieldValue(name, value);
          };

          const renderUserDoc = (userDoc, index) => {
            const downloadFile = () => userDoc?.url && window.open(userDoc.url, "_blank");

            return (
              <button
                key={`user-doc-${index}`}
                className="user-doc-container"
                onClick={downloadFile}
              >
                <DescriptionOutlined className="user-doc-icon" />
                {
                  userDoc?.createdAt &&
                  <p className="user-doc-created-at">
                    {t("users:importedDate", { date: `${format(new Date(userDoc.createdAt), "dd/MM/yyyy")}` })}
                  </p>
                }
              </button>
            );
          };

          return (
            <Form layout="vertical" className="users-others-form">
              <Row gutter={[8, 8]}>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <div className="user-card">
                        <h2 className="user-card-heading">{t`users:filesSection`}</h2>
                        <div className="user-docs-container">
                          {
                            userDocs && userDocs.length > 0 ?
                              userDocs.map(renderUserDoc)
                              :
                              <p className="no-doc">{t`users:noDoc`}</p>
                          }
                        </div>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="user-card">
                        <h2 className="user-card-heading">{t`users:settingsSection`}</h2>
                        <div className="user-settings-form-fields-container">
                          <InputLabel>
                            {t`users:settings.form.status.label`}
                          </InputLabel>
                          <Select name="isActive" value={values.isActive} onChange={handleChangeField}>
                            <MenuItem value={1}>{t`users:settings.form.status.activated`}</MenuItem>
                            <MenuItem value={0}>{t`users:settings.form.status.deactivated`}</MenuItem>
                          </Select>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
                  <Row gutter={[8, 8]}>
                    <Col span={24}>
                      <div className="user-card">
                        <h2 className="user-card-heading">{t`users:gamificationSection`}</h2>
                        <div className="next-level-content-container">
                          {/* <div className="next-level-btn-container">
                            <TextButton
                              variant={variant()}
                              className=""
                              rounded
                              onClick={handleNextLevel}
                            >
                              {t`users:gamification.goToNextLevel`}
                            </TextButton>
                          </div> */}
                          <UserStatusCard variant={user?.role === "NEO" ? "neo" : "pro"} currentStep={currentStepIndex} />
                        </div>
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="user-card">
                        <h2 className="user-card-heading">{t`users:accountSection`}</h2>
                        <div className="user-account-btns-container">
                          {
                            user?.role === "PRO" &&
                            <TextButton
                              variant={variant()}
                              type="outlined"
                              onClick={goToProPublicProfile}
                              rounded
                            >
                              {t`users:account.publicProfile`}
                            </TextButton>
                          }
                          {
                            user?.isActive &&
                            <TextButton
                              variant={variant()}
                              type="outlined"
                              onClick={logIntoAccount}
                              rounded
                            >
                              {t`users:account.logIntoAccount`}
                            </TextButton>
                          }
                          <TextButton
                            variant={variant()}
                            type="outlined"
                            disabled={!isValid || isSubmitting}
                            onClick={handleSubmit}
                            rounded
                          >
                            {t`users:account.saveModifications`}
                          </TextButton>
                          <TextButton
                            variant="red"
                            onClick={openDeleteAccountModal}
                            rounded
                          >
                            {t`users:account.deleteAccount`}
                          </TextButton>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    )
  }

  return (
    <MainLayout>
      <UserStyle />
      <Space direction="horizontal" size={16} className="page-header-container">
        <TextButton variant={variant()} rounded height="2.5rem" width="2.5rem" onClick={() => history.push("/utilisateurs")}>
          <svg xmlns="http://www.w3.org/2000/svg" width="6.667" height="10.63" viewBox="0 0 6.667 10.63">
            <g id="Groupe_2445" data-name="Groupe 2445" transform="translate(-89.525 -140.615)">
              <path id="Tracé_1256" data-name="Tracé 1256" d="M1298.171,1464.772l-3.848,4.062,3.848,3.74" transform="translate(-1203.394 -1322.743)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="2" />
            </g>
          </svg>
        </TextButton>
        <Tabs
          tabs={tabs}
          defaultActiveKey={selectedTab}
          onChange={handleTabChanged}
          variant={variant()}
        />
      </Space>
      <UserInfoBanner
        avatar={fullImageUrl(user?.imageUrls)}
        data={[{ ...user, key: `user-${user?.id}` }]}
        variant={variant()}
      />
      <Row gutter={[8, 8]} className="user-info-container">
        {selectedTab === "0" && renderDetailsTabContent()}
        {selectedTab === "1" && renderEditTabContent()}
        {selectedTab === "2" && renderOthersTabContent()}
      </Row>
    </MainLayout>
  );
};

const TextField = styled(({ ...rest }) => {
  const { t } = useTranslation(["common", "users"]);

  return (
    <BaseTextField
      {...rest}
      InputLabelProps={{ shrink: true }}
    />
  );
})`
  width: 100%;
  margin: 0 auto;

  label {
    font-family: var(--font-glacial);
    font-weight: 800;
    font-size: 1.3rem;
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  input {
    font-family: var(--font-glacial);
  }

  p.MuiFormHelperText-root {
    color: ${({ theme }) => `${theme.colors.shades.red[0]} !important`};
  }
`;

const Select = styled(({ ...rest }) => {
  const { t } = useTranslation(["common", "users"]);

  return (
    <BaseSelect
      {...rest}
    // InputLabelProps={{ shrink: true }}
    />
  );
})`
  width: 100%;
  margin: 0 auto;

  label {
    font-family: var(--font-glacial);
    font-weight: 800;
    font-size: 1.3rem;
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  input {
    font-family: var(--font-glacial);
  }
`;

export default UserDetail;
