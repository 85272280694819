import * as React from "react";

function SvgDeleteIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.546" height="22.829" viewBox="0 0 20.546 22.829">
  <path id="trash" d="M5.641,6.424H9.066V5.283A2.283,2.283,0,0,1,11.349,3H18.2A2.283,2.283,0,0,1,20.48,5.283V6.424H23.9a1.141,1.141,0,0,1,0,2.283H22.763V23.546a2.283,2.283,0,0,1-2.283,2.283H9.066a2.283,2.283,0,0,1-2.283-2.283V8.707H5.641a1.141,1.141,0,0,1,0-2.283ZM9.066,8.707V23.546H20.48V8.707Zm2.283-2.283H18.2V5.283H11.349Zm0,4.566h2.283V21.263H11.349Zm4.566,0H18.2V21.263H15.915Z" transform="translate(-4.5 -3)" fill="#fff"/>
</svg>

  );
}

export default SvgDeleteIcon;
