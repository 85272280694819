// #region Global Imports
import { BaseTheme } from "styled-components/macro";
// #endregion Global Imports

const common: BaseTheme = {
  colors: {
    shadow: "#00000029",
    transparent: "transparent",
    black: "#000000",
    white: "#FFFFFF",
    yellow: "#FFB93E",
    green: "#3E9363",
    lightGreen: "#86D693",
    salmon: "#F7A58D",
    grey: "#B1B1B1",
    pink: "#FDDDD5",
    aqua: "#8CE2D0",
    blue: "#0066ff",
    lightBlue: "#8BD3E6",
    brightBlue: "#3A00FF",
    darkBlue: "#003466",
    purple: "#001AFF",
    lightPurple: "#8F8CE2",
    darkSalmon: "#E1B7A7",
    red: "#EE1A1A",
    shades: {
      red: {
        0: "#DB0000",
        1: "#E50000",
        2: "#EE1A1A",
        3: "#F52626",
        4: "#FF0000",
        5: "#FF3838",
        6: "#FF562E",
        7: "#FF6158",
        8: "#C60A09",
      },
      grey: {
        0: "#010101",
        1: "#414141",
        2: "#434343",
        3: "#464646",
        4: "#484848",
        5: "#4B4B4B",
        6: "#4D4D4D",
        7: "#4C4C4C",
        8: "#505050",
        9: "#525252",
        10: "#535353",
        11: "#585858",
        12: "#5A5A5A",
        13: "#5C5C5C",
        14: "#5E5E5E",
        15: "#5F5F5F",
        16: "#606060",
        17: "#626262",
        18: "#636363",
        19: "#646464",
        20: "#656565",
        21: "#656363",
        22: "#6A6A6A",
        23: "#6D6D6D",
        24: "#6F6E6E",
        25: "#707070",
        26: "#737373",
        27: "#777777",
        28: "#797979",
        29: "#7D7D7D",
        30: "#7B7B7B",
        31: "#7A7A7A",
        32: "#868585",
        33: "#868686",
        34: "#8B8B8B",
        35: "#909090",
        36: "#979797",
        37: "#A2A2A2",
        38: "#ABAAAC",
        39: "#A8A8A8",
        40: "#ADADAD",
        41: "#AEAEAE",
        42: "#AFAFAF",
        43: "#B1B1B1",
        44: "#B3B3B3",
        45: "#B5B5B5",
        46: "#B6B6B6",
        47: "#B9B9B9",
        48: "#BDBDBD",
        49: "#BEBEBE",
        50: "#BFBFBF",
        51: "#C1C1C1",
        52: "#C6C6C6",
        53: "#C7C6C6",
        54: "#CACACA",
        55: "#C9CACB",
        56: "#D2D2D2",
        57: "#D3D2D2",
        58: "#D3D3D3",
        59: "#D5D5D5",
        60: "#D8D8D8",
        61: "#DEDEDE",
        62: "#E6E6E6",
        63: "#E8E8E8",
        64: "#EAEAEA",
        65: "#EEEEEE",
        66: "#F2F1F1",
        67: "#F2F2F2",
        68: "#F4F4F4",
        69: "#F5F5F5",
        70: "#F7F7F7",
      },
      blue: {
        1: "#DFEBFF",
        2: "#0F406F",
        3: "#7994A7",
        4: "#0900FF",
        5: "#204568"
      },
      salmon: {
        1: "#F8B29D"
      }
    }
  },
};

export { common };
