import * as React from "react";

function SvgCarouselPreviousIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="42.941"
      height="42.941"
      viewBox="0 0 34.012 42.941"
    >
      <defs>
        <filter
          id="Tracé_1379"
          x="0"
          y="0"
          width="42.941"
          height="42.941"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Tracé_1379)">
        <path
          id="Tracé_1379-2"
          data-name="Tracé 1379"
          d="M24.651,4.252a1,1,0,0,0,0-1.408L22.082.29a.981.981,0,0,0-1.393,0L12.471,8.509,4.252.29a.981.981,0,0,0-1.393,0L.29,2.844a1,1,0,0,0,0,1.408L11.774,15.721a.981.981,0,0,0,1.393,0Z"
          transform="translate(25.01 6) rotate(90)"
          fill={props.disabled ? "#B1B1B1" : "#f7a58d"}
        />
      </g>
    </svg>
  );
}

export default SvgCarouselPreviousIcon;