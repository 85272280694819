import * as React from "react";

function SvgWhiteCircleIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12.472"
      height="12.472"
      viewBox="0 0 12.472 12.472"
    >
      <circle
        id="Ellipse_491"
        data-name="Ellipse 491"
        cx="6.236"
        cy="6.236"
        r="6.236"
        fill="#faf8f8"
      />
    </svg>
  );
}

export default SvgWhiteCircleIcon;
