import React, { useEffect, useState } from "react";
import MainLayout from "layouts/main";
import { Avatar, Button, Col, Dropdown, Menu, message, Radio, Row, Select, Space, Table } from "antd";
import { exportUsers, getResponsableNeo, updateUser, userList } from "services/user";
import { format } from "date-fns";
import { DownloadOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import {
  GreenCircleIcon,
  RedCircleIcon,
  UnfilledCircleIcon,
  // RedCircleIcon,
  // UnfilledCircleIcon,
  WhiteCircleIcon,
} from "components/icons";
import { Card } from "components";
import { TextField } from "components/text-field";
import UserStyle from "styles/userStyles";
// import { useTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { MenuItem } from "@material-ui/core";
import { useTheme } from "styled-components";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { fullImageUrl } from "utils";

const {Option} = Select;


const UserList = () => {
  const [pages, setPages] = useState(1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sortField, setSortField] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [filterChoice, setFilterChoice] = useState(null);
  const [listResponsibles, setListResponsibles] = useState(null);
  const [selected, setSelected] = useState()
  const [selectedAdmin, setSelectedAdmin] = useState("")
  const [totalRecords, setTotalRecords] = useState(0);
  let history = useHistory();
  const { colors } = useTheme();

  const columns = [
    {
      key: "image",
      title: "",
      dataIndex: "imageUrls",
      render: (imageUrls) => {
        return (
          <>
            <Avatar src={fullImageUrl(imageUrls, 'sm')} icon={<UserOutlined />} />
          </>
        );
      }
    },
    {
      key: "Id",
      title: "ID",
      dataIndex: "id",
      align: 'center' as 'center'
    },
    {
      key: "Nom",
      title: "Nom",
      dataIndex: "fullname",
    },
    {
      key: "Email",
      title: "Email",
      dataIndex: "email",
    },
    {
      key: "Code Postal",
      title: "Code Postal",
      dataIndex: "postalCode",
      align: 'center' as 'center'
    },
    {
      key: "Télephone",
      title: "Télephone",
      dataIndex: "phoneNumber",
    },
    {
      key: "Groupe d'utilisateurs",
      title: "Groupe d'utilisateurs",
      dataIndex: "roleName",
      render: (tag) => {
        let role = tag === "NEO" ? "Neo" : "Professionnel";
        let color = "#F7A58D";
        if (tag === "NEO") {
          color = "#003466";
        }
        return (
        <>
          <div
            style={{
              width: "150px",
              height: "30px",
              backgroundColor: color,
              borderRadius: "25px",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <span style={{ paddingLeft: 10 }}>{role}</span>
            <span style={{ paddingRight: 10 }}>
              <WhiteCircleIcon />
            </span>
          </div>
        </>);
      },
      sorter: true
    },
    {
      key: "À Jour",
      title: "À Jour",
      dataIndex: "roleName",
      align: 'center' as 'center',
      render: (tag, record) => (
        <>
         {tag === "NEO" ? <UnfilledCircleIcon /> : record.isUpdated ?  <GreenCircleIcon /> : <RedCircleIcon />}
        </>
      ),
    },
    {
      key: "Annonces",
      title: "Annonces",
      dataIndex: "advertCount",
      align: 'center' as 'center',
      render: (text, record) => (
        <>
         {record.roleName === "NEO" ? <UnfilledCircleIcon /> : record.advertCount}
        </>
      ),
    },
    {
      key: "Mutuelle",
      title: "Mutuelle",
      dataIndex: "mutualMembershipCode",
    },
    {
      key: "Date d'inscription",
      title: "Date d'inscription",
      dataIndex: "createdAt",
      render: (date: string) => getFullDate(date),
      sorter: true
    },
    {
      key: "Responsable Neo",
      title: "Responsable Neo",
      dataIndex: "adminUserId",
      render: (text, record) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Select className="select-responsible" onChange={(value) => handleOnChange(value, record)} key={`${record.id}${selectedAdmin}` || record.adminUserId} value={record.adminUserId} 
          
          >
            {listResponsibles?.map((item) => <Option style={{textAlign: "center"}} key={item.id} value={item.id}>{item.firstname}</Option>)}
          </Select>
          </div>
          );
      },
      sorter: true
    },
  ];
  const handleOnChange = (value, record) => {
    if (record) {
      updateUser(record.id, {adminUserId: value});
      const idx = allData.findIndex(x => x.id === record.id);
      record.adminUserId = value;
      const adminFirstname = listResponsibles.find (x=> x.id === value);
      record.adminFirstname = adminFirstname.firstname;
      allData[idx] = record;
      setSelectedAdmin(`${record.id}${value}`);
    }
  };

  const [allData, setAllData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  let params = {
    "offset": 0,
    "limit": limit,
    "order": "",
  };

  useEffect(() => {
    async function fetchUsers() {
      const response = await userList(params);
      const users =  response.users;
      const usersWithKey = users.map((e, i) => ({ ...e, key: i }));
      setAllData(usersWithKey);

      setTotalRecords(response.totalRecords);

      let pages = Math.ceil(
        response.totalRecords / limit
      );
      setPages(pages);
    }
    fetchUsers();
    getResponsableNeo().then((adminUsers) => {
      setListResponsibles(adminUsers);
    });
  }, []);

  const handleSearch = (event) => {
    let searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    let searchParams = null;
    if (filterChoice) {
      searchParams = {
        where: {
          search: searchTerm,
          rolename: filterChoice
        }
      }
    } else {
      searchParams = {
        where: {
          search: searchTerm,
        }
      }
    }
    fetchData(searchParams);
  };

  const handleDownload = async () => {
    const result = await exportUsers();
    if (result.response) {
      const users = result.response;
      if (users) {
        exportToCSV(users, "export_utilisateurs");
        message.success("Export reussi", 5);
      } else {
        message.warning("Pas de données a exporter", 5);
      }
    } else {
      message.error(
        "Une erreur est survenue. Veuillez réessayer plus tard.",
        5
      );
    }
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}

  const { t } = useTranslation(["common", "users"]);

  const radioItems = [{
    label: t("users:filters.pro"),
    value: "pro"
  },
  {
    label: t("users:filters.neo"),
    value: "neo"
  },
  {
    label: t("users:filters.notFilled"),
    value: "unknown"
  }];

  const onChangeFilter = (event) => {
    setFilterChoice(event.target.value);
    let filterParams = {
      "offset": 0,
      "limit": limit,
      "where": {
        "rolename" : event.target.value
      }
    }
    fetchData(filterParams);
  }

  const showMenu = (
    <Menu className="filter-container">
      <p className="filter-menu-title">{t("users:filters.title")}</p>
      <Radio.Group value={filterChoice} onChange={onChangeFilter}>
        {radioItems.map((item, index) =>
          <MenuItem key={index} className="filter-menu-item">
            <Radio value={item.value}>{item.label}</Radio>
          </MenuItem>
        )}
      </Radio.Group>
    </Menu>
  );

  const onTableChange = (pagination, b, sort) => {
    if (sort && sort.field && sort.order) {
      sortColumn(sort.field, sort.order, pagination.current - 1);
    } else {
      setOffset(pagination.current - 1);
      let params = null;
  
      if (searchTerm && searchTerm.length > 0) {
        params = {
          offset: pagination.current - 1,
          limit: limit,
          where: {
            search: searchTerm,
            rolename: filterChoice
          },
        };
      } else {
        params = {
          offset: pagination.current - 1,
          limit: limit,
          where: {
            rolename: filterChoice
          },
        };  
      }
  
      fetchData(params);
    }
  };

  const sortColumn = (field, order, currentPage) => {
    setSortField(field);
    setSortOrder(order);

    order = order === "ascend" ? 'asc' : 'desc'; 
    let params = null;
    let sortOrder = '';

    if (field === "roleName") {
      sortOrder = `rolename ${order}`;
    }
    else if (field === "createdAt") {
      sortOrder = `createdat ${order}`;
    }
    else if (field === "adminUserId") {
      sortOrder = `adminfirstname ${order}`;
    }

    if (searchTerm && searchTerm.length > 0) {
      params = {
        offset: currentPage,
        limit: limit,
        order: sortOrder,
        where: {
          search: searchTerm,
          rolename: filterChoice
        },
      };
    } else {
      params = {
        offset: currentPage,
        limit: limit,
        order: sortOrder,
        where: {
          rolename: filterChoice
        },
      };  
    }

    fetchData(params);
  };

  const fetchData = async (params) => {
    const response = await userList(params);
    if (response) {
      const usersWithKey = response?.users?.map((e, i) => ({ ...e, key: i }));
      setAllData(usersWithKey);
      setTotalRecords(response.totalRecords);

      let pages = Math.ceil(
        response.totalRecords / limit
      );
      setPages(pages);
    }
  };

  return (
    <MainLayout>
      <UserStyle />
      <Card>
        <h1 className="user-title">{t`users:title`}</h1>
        <Row justify="space-between" gutter={[16, 8]} style={{marginBottom: "10px"}}>
          <Col>
          <Dropdown overlay={showMenu} placement="bottomLeft" arrow>
            <a className="filter-dropdown">
              {t("users:filters.title")}{"   "}<DownOutlined />
            </a>
          </Dropdown>
          </Col>
          <Col className="search-text-container">
            <Space><p className="search-text">{t("users:search")}:</p>
            <TextField radius="0.8125rem" onPressEnter={handleSearch} />
            <Button icon={<DownloadOutlined />} size="large" onClick={handleDownload} style={{color: colors.shades.grey[25], border: "1px solid #707070", fontSize: "12pt"}}>{t("users:exportUsers")}</Button></Space>
          </Col>
        </Row>
        <Table
          // rowSelection={{
          //   ...rowSelection,
          // }}
          columns={columns}
          dataSource={allData}
          onRow={(record) => {
            return {
              onClick: () => {
                history.push(`/utilisateurs/${record.id || ""}`);
              },
            };
          }}
          locale={{ emptyText: t`common:table.emptyText` }}
          onChange={onTableChange}
          pagination={{
            pageSize: limit,
            defaultPageSize: limit,
            total: totalRecords,
            showSizeChanger: false
          }}
        />
      </Card>
    </MainLayout>
  );
};

export default UserList;

function getFullDate(date: string) {
  // const dateAndTime = date.split("T");
  const dateAndTime = format(new Date(date), "dd/MM/yyyy HH:mm");
  const datePart = dateAndTime.split(" ");
  const timePart = datePart[1].replace(":", "h");
  const dateTimeFormat = datePart[0] + " à " + timePart;
  // return dateAndTime[0].split("-").reverse().join("-");
  return dateTimeFormat;
}
