import * as React from "react";

function SvgEvaluationsIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28.3"
      height="25.386"
      viewBox="0 0 28.3 25.386"
    >
      <g
        id="Group_1460"
        data-name="Group 1460"
        transform="translate(-2.027 -2.667)"
      >
        <g
          id="Group_1476"
          data-name="Group 1476"
          transform="translate(2.177 2.904)"
        >
          <g id="Group_1447" data-name="Group 1447">
            <path
              id="Path_462"
              data-name="Path 462"
              d="M29.361,26.5a1.567,1.567,0,0,0,1.465-1.452V12.815a2.079,2.079,0,0,0-.839-1.623L26.266,8.3V4.922A1.338,1.338,0,0,0,24.837,3.7h-4.5a.45.45,0,0,0-.05-.036L18.024,1.908a2.065,2.065,0,0,0-2.393,0L13.317,3.7h-4.5a1.338,1.338,0,0,0-1.428,1.22V8.308L3.662,11.194a2.078,2.078,0,0,0-.837,1.621V25.052A1.568,1.568,0,0,0,4.3,26.5a.946.946,0,0,0,.156.021H29.2A.95.95,0,0,0,29.361,26.5ZM16.243,2.488a1.009,1.009,0,0,1,1.168,0L18.852,3.6H14.8ZM3.738,25.052V12.815c0-.016.005-.031.006-.047l10.365,6.186L3.969,25.229a.781.781,0,0,0-.146.125A.606.606,0,0,1,3.738,25.052ZM29.912,12.815V25.052a.606.606,0,0,1-.083.3.783.783,0,0,0-.143-.122l-10.14-6.274,10.361-6.184C29.908,12.786,29.912,12.8,29.912,12.815ZM4.7,25.744,14.9,19.429c2.53,1.5,1.437,1.315,3.85,0l10.206,6.315H4.7ZM29.371,11.771a1.528,1.528,0,0,1,.234.228L26.38,13.923V9.448ZM8.813,4.289h4.511a.5.5,0,0,0,.295.1h6.426a.494.494,0,0,0,.294-.1h4.5a.7.7,0,0,1,.744.633V8.313a.358.358,0,0,0-.114.252c-.095,5.759.211,5.768-.267,6.061-7.954,4.578-8.041,5.163-8.719,4.79-8.554-5.1-8.3-4.8-8.3-5.232V8.57a.358.358,0,0,0-.114-.253V4.922A.7.7,0,0,1,8.813,4.289ZM7.271,9.451V13.92L4.046,12a1.518,1.518,0,0,1,.231-.224Z"
              transform="translate(-2.825 -1.526)"
              fill="#b0b0b0"
              stroke="#b0b0b0"
              strokeWidth="0.3"
            />
          </g>
          <g
            id="Group_1459"
            data-name="Group 1459"
            transform="translate(14.984 5.104)"
          >
            <g
              id="Group_1449"
              data-name="Group 1449"
              transform="translate(0.505)"
            >
              <path
                id="Path_464"
                data-name="Path 464"
                d="M36.365,16.526h4.96a.37.37,0,1,0,0-.74h-4.96a.37.37,0,0,0,0,.74Z"
                transform="translate(-35.995 -15.786)"
                fill="#828282"
              />
            </g>
            <g id="Group_1450" data-name="Group 1450">
              <path
                id="Path_465"
                data-name="Path 465"
                d="M32.615,16.526h.791a.37.37,0,1,0,0-.74h-.791a.37.37,0,0,0,0,.74Z"
                transform="translate(-32.245 -15.786)"
                fill="#828282"
              />
            </g>
            <g
              id="Group_1451"
              data-name="Group 1451"
              transform="translate(4.249 1.786)"
            >
              <path
                id="Path_466"
                data-name="Path 466"
                d="M45.168,20.3H43.951a.37.37,0,0,0,0,.74h1.217a.37.37,0,1,0,0-.74Z"
                transform="translate(-43.581 -20.299)"
                fill="#828282"
              />
            </g>
            <g
              id="Group_1452"
              data-name="Group 1452"
              transform="translate(0 1.786)"
            >
              <path
                id="Path_467"
                data-name="Path 467"
                d="M32.615,21.039h4.377a.37.37,0,0,0,0-.74H32.615a.37.37,0,0,0,0,.74Z"
                transform="translate(-32.245 -20.299)"
                fill="#828282"
              />
            </g>
            <g
              id="Group_1453"
              data-name="Group 1453"
              transform="translate(2.319 3.572)"
            >
              <path
                id="Path_468"
                data-name="Path 468"
                d="M43.188,24.811H40.042a.37.37,0,0,0,0,.74h3.146a.37.37,0,0,0,0-.74Z"
                transform="translate(-39.672 -24.811)"
                fill="#828282"
              />
            </g>
            <g
              id="Group_1454"
              data-name="Group 1454"
              transform="translate(0 3.572)"
            >
              <path
                id="Path_469"
                data-name="Path 469"
                d="M32.615,25.551h2.315a.37.37,0,1,0,0-.74H32.615a.37.37,0,0,0,0,.74Z"
                transform="translate(-32.245 -24.811)"
                fill="#828282"
              />
            </g>
            <g
              id="Group_1455"
              data-name="Group 1455"
              transform="translate(0 5.358)"
            >
              <path
                id="Path_470"
                data-name="Path 470"
                d="M32.615,30.063h3.9a.37.37,0,0,0,0-.74h-3.9a.37.37,0,0,0,0,.74Z"
                transform="translate(-32.245 -29.323)"
                fill="#828282"
              />
            </g>
          </g>
          <g
            id="Polygon_2"
            data-name="Polygon 2"
            transform="translate(7.627 5.036)"
            fill="#b0b0b0"
          >
            <path
              d="M 3.173118829727173 3.751876831054688 L 2.793109893798828 3.476900100708008 L 2.5 3.264800071716309 L 2.206890106201172 3.476900100708008 L 1.826881051063538 3.751876831054688 L 1.969920039176941 3.245280027389526 L 2.059679985046387 2.927360057830811 L 1.802449941635132 2.720089912414551 L 1.371859431266785 2.373128414154053 L 1.888440012931824 2.359240055084229 L 2.237639904022217 2.349849939346313 L 2.348939895629883 2.018719911575317 L 2.5 1.569305658340454 L 2.651060104370117 2.018719911575317 L 2.762360095977783 2.349849939346313 L 3.111560106277466 2.359240055084229 L 3.628140449523926 2.373128414154053 L 3.197550058364868 2.720089912414551 L 2.940320014953613 2.927360057830811 L 3.030080080032349 3.245280027389526 L 3.173118829727173 3.751876831054688 Z"
              stroke="none"
            />
            <path
              d="M 2.5 0 L 3.125 1.85942006111145 L 5 1.909830093383789 L 3.511270046234131 3.109419822692871 L 4.045080184936523 5 L 2.5 3.881969928741455 L 0.9549098014831543 5 L 1.488729953765869 3.109419822692871 L 0 1.909830093383789 L 1.875 1.85942006111145 L 2.5 0 Z"
              stroke="none"
              fill="#b0b0b0"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgEvaluationsIcon;
