/**
 * @param str string - sentence(s)
 * @return number - number of words
 * @description method to count the number of words in a string
 */
export const retrieveNumberOfWords = (str) => {
  return str ? str?.trim().match(/\S+/g).length : 0;
};

/**
 * @param num number - number to convert to currency
 * @param locale string - locale to convert the number in
 * @param opts object - toLocaleString options
 * @return string - localized number
 * @description method to convert a number to a localized currency number
 */
 export const localizeCurrency = (
  num = 0,
  locale = "fr",
  opts = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
) => {
  return num.toLocaleString(locale, opts);
};

/**
 * @param imageUrls string | object - image path, e.g: uploads/user/1234.png | {url: uploads/user/1234.png}
 * @return string - the full image path
 * @description method to construct full image URLs with the CDN URL
 */
 export const fullImageUrl = (imageUrls, mode = null) => {
  if (imageUrls) {
    const img =
      typeof imageUrls === "string"
        ? imageUrls
        : imageUrls[mode] || imageUrls.url;

    return `${process.env.REACT_APP_S3_CDN_URL}${img}`;
  }

  return null;
};