import { UserOutlined } from "@ant-design/icons";
import { Avatar, Card, Col, Row, Switch, Table } from "antd";
import { ParameterHeader } from "components";
import AccountIcon from "components/icons/AccountIcon";
import MainLayout from "layouts/main";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { patchAdminUser, retrieveAdmins } from "services/admin";
import AdminStyle from "styles/adminStyle";
import { useHistory } from "react-router-dom";
import { fullImageUrl } from "utils";


const AdminList = () => {
  const { t } = useTranslation(["common", "parameters"]);
  const [allAdmins, setAllAdmins] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  let history = useHistory();


  useEffect(() => {
    async function fetchUsers() {
      await loadInitialData();
    }
    fetchUsers();

  }, []);

  const loadInitialData = async () => {
    const response = await retrieveAdmins();
    console.log('response :>> ', response);
    // const catWithKeys = response.map((e, i) => ({ ...e, key: i }));
    setAllAdmins(response);
    setTotalRecords(response?.length);
  }

  const columns = [
    {
      key: "avatar",
      title: "",
      dataIndex: "imageUrls",
      align: "center" as "center",
      render: (imageUrls) => {
        return (
          <Avatar src={fullImageUrl(imageUrls, "sm")} icon={<UserOutlined />} />
        );
      }
    },
    {
      key: "Id",
      title: t`parameters:admin.table.headers.id`,
      dataIndex: "id",
      align: "center" as "center",
    },
    {
      key: "Prenom",
      title: t`parameters:admin.table.headers.firstname`,
      dataIndex: "firstname",
      sorter: (a, b) => a?.firstname?.localeCompare(b?.firstname),
    },
    {
      key: "Nom",
      title: t`parameters:admin.table.headers.name`,
      dataIndex: "name",
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
    },
    {
      key: "Email",
      title: t`parameters:admin.table.headers.email`,
      dataIndex: "email",
    },
    {
      key: "Phone",
      title: t`parameters:admin.table.headers.phone`,
      dataIndex: "phoneNumber",
      align: "center" as "center",
    },
    {
      key: "Statut",
      title: t`parameters:admin.table.headers.status`,
      dataIndex: "isActive",
      align: "center" as "center",
      sorter: (a, b) => a.isActive - b.isActive,
      render: (checked, record) => {
        return (
          <Switch
            checkedChildren={t`parameters:admin.table.checkboxStatus.yes`}
            unCheckedChildren={t`parameters:admin.table.checkboxStatus.no`}
            checked={record.isActive}
            key={`${record.id}${selectedStatus}` || record.id}
            onChange={(e) => onHandleAdminStatus(e, record)}
            onClick={(e, event) => event.stopPropagation()}
          />
        );
      },
    },
  ];

  const onHandleAdminStatus = (checked, record) => {

    let payload = {
      isActive: checked
    }

    patchAdminUser(record.id, payload);
    const idx = allAdmins.findIndex(x => x.id === record.id);
    const list = allAdmins.find(x=> x.id === record.id);
    list.isActive = checked;
    allAdmins[idx] = list;
    setSelectedStatus(`${record.id}${checked}`)
  }

  return (
    <MainLayout>
      <ParameterHeader 
        icon={<AccountIcon fill={"#fff"} stroke={"#fff"} />} 
        title={t`parameters:admin.title`} 
        subTitle={""} 
        goBack={"/parametres"} 
        btnClick={`/parametres/comptes-admin/nouvelle`}
        btnText={t`parameters:admin.btnAddAccount`}
      />
      <AdminStyle />
      <Row gutter={[8, 8]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <Card bordered={true} className="parent-card-style">
            <p className="section-title">{t`parameters:admin.listTitle`}</p>
              <Table
                columns={columns}
                dataSource={allAdmins}
                locale={{ emptyText: t`common:table.emptyText`}}
                onRow={(record) => {
                  return {
                    onClick: () => history.push(`/parametres/comptes-admin/${record.id || ""}`),
                  };
                }}
                pagination={{
                  // pageSize: 10,
                  // defaultPageSize: 10,
                  total: totalRecords,
                  showSizeChanger: true
                }}
              />
          </Card>
        </Col>
      </Row>
    </MainLayout>

  );
}

export default AdminList;
