import MainLayout from "layouts/main"
import { Card } from "components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Table, Row, Col, Dropdown, Menu, Space, Radio, DatePicker, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { DownloadOutlined, DownOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { TextField } from "components/text-field";
import { IconButton, MenuItem } from "@material-ui/core";
import { exportTransactions, getTransactionList } from "services/transaction";
import { getFullDateWithTime } from "utils/validators";
import { format, addMonths } from "date-fns";
import TransactionStyle from "styles/transactionStyle";
import moment from "moment";
import 'moment/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useTheme } from "styled-components";


const TransactionList = () => {

  const { t } = useTranslation(["common", "transaction"]);
  const { colors } = useTheme();
  let history = useHistory();
  const [allTransactions, setAllTransactions] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterChoice, setFilterChoice] = useState(null);
  const [transactionMonth, setTransactionMonth] = useState(new Date());

  const columns = [
    {
      key: "Commandes",
      title: t`transaction:table.headers.order`,
      dataIndex: "bookingReference",
    },
    {
      key: "Client",
      title: t`transaction:table.headers.client`,
      dataIndex: "buyerFirstname",
      align: "center" as "center",
    },
    {
      key: "Vendeur",
      title: t`transaction:table.headers.seller`,
      dataIndex: "sellerFirstname",
      align: "center" as "center",
    },
    {
      key: "Amount",
      title: t`transaction:table.headers.total`,
      dataIndex: "amount",
      align: "center" as "center",
    },
    {
      key: "Statut",
      title: t`transaction:table.headers.status`,
      dataIndex: "status",
      align: "center" as "center",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      render: (status: string) => createStatusTag(status),
    },
    {
      key: "Date",
      title: t`transaction:table.headers.activityDate`,
      dataIndex: "activityDateTime",
      render: (date: string) => date ? format(new Date(date), "dd/MM/yyyy") : '',
      sorter: (a, b) => new Date(a.activityDateTime).valueOf() - new Date(b.activityDateTime).valueOf(),
      align: "center" as "center",
    },
    {
      key: "Time",
      title: t`transaction:table.headers.activityTime`,
      dataIndex: "activityDateTime",
      render: (date: string) => date ? format(new Date(date), "HH:mm") : '',
      align: "center" as "center",
    },
    {
      key: "OrderDate",
      title: t`transaction:table.headers.orderDate`,
      dataIndex: "transactionDateTime",
      render: (date: string) => getFullDateWithTime(date),
      align: "center" as "center",
    },
    {
      key: "Neo",
      title: t`transaction:table.headers.responsibleNeo`,
      dataIndex: "adminNeoFirstName",
      align: "center" as "center",
    },
  ];

  const FILTER_CHOICE = [
    {
      label: t("transaction:filters.all"),
      value: "ALL"
    },
    {
      label: t("transaction:filters.created"),
      value: "CREATED"
    },
    {
      label: t("transaction:filters.paid"),
      value: "PAID"
    },
    {
      label: t("transaction:filters.confirmed"),
      value: "CONFIRMED"
    },
    {
      label: t("transaction:filters.refused"),
      value: "REFUSED"
    },
    {
      label: t("transaction:filters.abandonned"),
      value: "ABANDONNED"
    },
    {
      label: t("transaction:filters.paymentFailed"),
      value: "PAYMENT_FAILED"
    },
    {
      label: t("transaction:filters.refund"),
      value: "REFUND"
    },
  ];

  const createStatusTag = (status) => {

    let colorTag = "status-tag";
    let displayValue = status;

    if (status === "CREATED") {
      displayValue = t("transaction:status.created");
      colorTag = "status-tag blue-color";
    }
    else if (status === "PAID") {
      displayValue = t("transaction:status.paid");
      colorTag = "status-tag yellow-color";
    }
    else if (status === "WAITING_FOR_CONFIRMATION") {
      displayValue = t("transaction:status.waitingForConfirmation");
      colorTag = "status-tag yellow-color";
    }
    else if (status === "CONFIRMED") {
      displayValue = t("transaction:status.confirmed");
      colorTag = "status-tag green-color";
    }
    else if (status === "REFUSED") {
      displayValue = t("transaction:status.refused");
      colorTag = "status-tag salmon-color";
    }
    else if (status === "ABANDONNED") {
      displayValue = t("transaction:status.abandonned");
      colorTag = "status-tag purple-color";

    }
    else if (status === "PAYMENT_FAILED") {
      displayValue = t("transaction:status.paymentFailed");
      colorTag = "status-tag black-color";
    }
    else if (status === "REFUND") {
      displayValue = t("transaction:status.refund");
    }

    return (
      <div>
        <div className={colorTag}>
          <span>{displayValue}</span>
        </div>
      </div>
    )
  }


  useEffect(() => {
    async function fetchTransactions() {
      const payload = {
        where: {
          currentMonth: format(transactionMonth, 'yyyy-MM-dd')
        }
      }
      await fetchData(payload);
    }
    fetchTransactions();

  }, [transactionMonth]);

  const fetchData = async (params) => {
    const response = await getTransactionList(params);
    let transactions = [];
    if (params.where.filterBy && params.where.filterBy !== 'ALL') {
      transactions = response.transactions.filter(function (t: any) {
        return t.status === params.where.filterBy;
      });
    } else {
      transactions = response.transactions;
    }
    setAllTransactions(transactions);
    setTotalRecords(response.totalRecords);
  };

  const onChangeFilter = (event) => {
    setFilterChoice(event.target.value);
    let filterParams = {
      "where": {
        "filterBy": event.target.value,
        currentMonth: format(transactionMonth, 'yyyy-MM-dd')
      }
    }
    fetchData(filterParams);
  }

  const showMenu = (
    <Menu className="filter-container">
      <p className="filter-menu-title">{t("transaction:filters.title")}</p>
      <Radio.Group value={filterChoice} onChange={onChangeFilter}>
        {FILTER_CHOICE.map((item, index) =>
          <MenuItem key={index} className="filter-menu-item">
            <Radio value={item.value}>{item.label}</Radio>
          </MenuItem>
        )}
      </Radio.Group>
    </Menu>
  );

  const handleSearch = (event) => {
    let searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    const searchParams = {
      where: {
        search: searchTerm,
        currentMonth: format(transactionMonth, 'yyyy-MM-dd')
      },
    };

    fetchData(searchParams);
  };

  const handleDownload = async () => {
    const payload = {
      where: {
        currentMonth: format(transactionMonth, 'yyyy-MM-dd')
      }
    }
    const result = await exportTransactions(payload);
    if (result) {
      if (result.length > 0) {
        exportToCSV(result, "export_transactions");
        message.success(t("transaction:success.exportSuccess"), 5);
      } else {
        message.warning(t("transaction:error.noDataToExport"), 5);
      }
    } else {
      message.error(
        t("transaction:error.generalError"),
        5
      );
    }
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}

  const changeMonth = async (dateObj) => {

    const setNewDate = new Date(dateObj);
    setTransactionMonth(setNewDate);
    await fetchDateByDateOnly(setNewDate);

  }

  const handlePreviousMonth = () => {
    handleMonthChange(true);
  }

  const handleNextMonth = () => {
    handleMonthChange(false);
  }

  const handleMonthChange = async (isPrevious) => {

    let current = transactionMonth;
    if (isPrevious)
      current = addMonths(new Date(transactionMonth), -1);
    else
      current = addMonths(new Date(transactionMonth), 1);

    setTransactionMonth(current);
    await fetchDateByDateOnly(current);

  }

  const fetchDateByDateOnly = async (current) => {
    const payload = {
      where: {
        currentMonth: format(current, 'yyyy-MM-dd')
      }
    }
    fetchData(payload);
  }


  return (
    <MainLayout>
      <TransactionStyle />
      <Card>
        <h1 className="page-title">{t`transaction:title`}</h1>
        <Row justify="space-between" gutter={[16, 8]} style={{ marginBottom: "10px", display: "flex", flexDirection: "row", alignItems: "center" }}>
          <Col>
            <Dropdown overlay={showMenu} placement="bottomLeft" arrow>
              <p className="filter-dropdown" style={{ cursor: "pointer" }}>
                {t("transaction:filters.title")}{"   "}<DownOutlined />
              </p>
            </Dropdown>
          </Col>
          <Col>
            <IconButton
              aria-label="delete"
              style={{ fontSize: "12pt" }}
              onClick={() => handlePreviousMonth()}
            >
              <LeftOutlined />
            </IconButton>
            <DatePicker
              style={{ width: "150px" }}
              picker="month" bordered={false}
              onChange={changeMonth}
              value={moment(new Date(transactionMonth), 'MMMMYYYY')}
              format={'MMMM YYYY'}
              locale={locale}
              allowClear={false}
              inputReadOnly={true}
            //suffixIcon={<div></div>} 
            />
            <IconButton
              aria-label="delete"
              style={{ fontSize: "12pt" }}
              onClick={() => handleNextMonth()}
            >
              <RightOutlined />
            </IconButton>
          </Col>
          <Col className="search-text-container">
            <Space><p className="search-text">{t("transaction:search")}:</p>
              <TextField radius="0.8125rem" onPressEnter={handleSearch} />
              <Button icon={<DownloadOutlined />} size="large" onClick={handleDownload} style={{color: colors.shades.grey[25], border: "1px solid #707070", fontSize: "12pt"}}>{t("transaction:exportTransactions")}</Button>
            </Space>
          </Col>
        </Row>
        <Table
          rowKey={record => record.id}
          columns={columns}
          dataSource={allTransactions}
          locale={{ emptyText: t`common:table.emptyText` }}
          pagination={{
            pageSize: 10,
            defaultPageSize: 10,
            total: totalRecords,
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                history.push(`/transactions/${record.id || ""}`);
              },
            };
          }}
        />
      </Card>
    </MainLayout>
  )
}
export default TransactionList;
