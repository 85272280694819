import MainLayout from "layouts/main"
import { Card } from "components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { getFullDateWithTime } from "utils/validators";
import { Table, Row, Col, Dropdown, Menu, Space, Button, Radio, Switch, message } from "antd";
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { DownloadOutlined, DownOutlined, EyeOutlined } from "@ant-design/icons";
import { TextField } from "components/text-field";
import { MenuItem } from "@material-ui/core";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import RatingStyle from "styles/RatingStyle";
import { exportRatings, getRatingList, updateRating, getUserRatings } from "services/rating";

const RatingList = () => {

  const { t } = useTranslation(["common", "rating"]);
  const { colors } = useTheme();
  let history = useHistory();
  const [allRating, setAllRating] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visible, setVisible] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filterChoice, setFilterChoice] = useState(null);
  const limit = 10;
  const [offset, setOffset] = useState(0);

  const columns = [
    {
      key: "Commandes",
      title: t`rating:table.headers.order`,
      dataIndex: "id",
      render: (value, record) => createOrderRef(record),
    },
    {
      key: "Client",
      title: t`rating:table.headers.client`,
      dataIndex: "buyer",
      align: "center" as "center",
    },
    {
      key: "Vendeur",
      title: t`rating:table.headers.seller`,
      dataIndex: "seller",
      align: "center" as "center",
    },
    {
      key: "Note",
      title: t`rating:table.headers.note`,
      dataIndex: "note",
      align: "center" as "center",
    },
    {
      key: "Visible",
      title: t`rating:table.headers.visible`,
      dataIndex: "isVisible",
      align: "center" as "center",
      render: (checked, record) => {
        return (
          <Switch
            checkedChildren={t`rating:table.checkboxStatus.yes`}
            unCheckedChildren={t`rating:table.checkboxStatus.no`}
            checked={record.isVisible}
            key={`${record.id}${visible}` || record.id}
            onChange={(e) => onHandleRatingVisible(e, record)}
            onClick={(e, event) => event.stopPropagation()}
          />
        );
      },
    },
    {
      key: "Role",
      title: t`rating:table.headers.role`,
      dataIndex: "seller",
      render: (value) => { return 'Vendeur' },
      align: "center" as "center",
    },
    {
      key: "Statut",
      title: t`rating:table.headers.status`,
      dataIndex: "status",
      render: (status: string) => {return t(`rating:status.${status}`) },
      align: "center" as "center",
    },
    {
      key: "Evalué",
      title: t`rating:table.headers.createdAt`,
      dataIndex: "createdAt",
      render: (date: string) => getFullDateWithTime(date),
      align: "center" as "center",
    },
    {
      key: "Neo",
      title: t`rating:table.headers.responsibleNeo`,
      dataIndex: "adminNeo",
      align: "center" as "center",
    },
    {
      key: "Voir",
      title: t`rating:table.headers.view`,
      dataIndex: "id",
      align: "center" as "center",
      render: (id) => {
        return (
          <div>
            <Button className="view-btn" size="small" icon={<EyeOutlined />} onClick={() => history.push(`/evaluations/${id || ""}`)} />
          </div>
        );
      },
    },
  ];

  const FILTER_CHOICE = [
    {
      label: t("rating:filters.highest"),
      value: "HIGHEST"
    },
    {
      label: t("rating:filters.lowest"),
      value: "LOWEST"
    },
    {
      label: t("rating:filters.new"),
      value: "NEW"
    },
    {
      label: t("rating:filters.old"),
      value: "OLD"
    },
  ];

  const createOrderRef = (record) => {
    return (
      <div>
        <p>{record.bookingReference}</p>
        <small>{record.activityName} {t`rating:table.headers.with`} {record.seller}</small>
      </div>
    )
  }

  const onHandleRatingVisible = async(checked, record) => {
    const res = await updateRating(record.id, { isVisible: checked });
    const idx = allRating.findIndex(x => x.id === record.id);
    const list = allRating.find(x => x.id === record.id);
    list.isVisible = checked;
    list.status = handleStatusChange(checked, record);
    allRating[idx] = list;
    setVisible(`${record.id}${checked}`)
  }

  const handleStatusChange = (checked, record) => {
    if (checked === true) {
      return "APPROVED"
    } else {
      return "REJECTED"
    }
  }

  useEffect(() => {
    async function fetchRatings() {
      let param = {
        offset: 0,
        limit: limit,
      }
      await fetchData(param);
    }
    if (window.location.search) {
      const searchParams = new URLSearchParams(window.location.search);
      const user_id = searchParams.get('user_id');
      const eval_type = searchParams.get('eval_type');
      fetchUserRatings(user_id, eval_type);
    } else {
      fetchRatings();
    }


  }, []);

  const fetchData = async (params) => {
    const response = await getRatingList(params);
    const ratings = response.ratings;
    setAllRating(ratings);
    setTotalRecords(response.totalRecords);
  };

  const fetchUserRatings = async (user_id, eval_type) => {
    const response = await getUserRatings(user_id, eval_type);
    const ratings = response.ratings;
    setAllRating(ratings);
    setTotalRecords(response.totalRecords);
  }

  const onChangeFilter = (event) => {
    setFilterChoice(event.target.value);
    let filterParams = {
      where: {
        sorting: event.target.value,
        search: searchTerm ?? null,
      }
    }
    fetchData(filterParams);
  }

  const handleDownload = async () => {
    console.log("Download");
    const result = await exportRatings();
    if (result.response) {
      const users = result.response;
      if (users) {
        exportToCSV(users, "export_evaluations");
        message.success("Export reussi", 5);
      } else {
        message.warning("Pas de données a exporter", 5);
      }
    } else {
      message.error(
        "Une erreur est survenue. Veuillez réessayer plus tard.",
        5
      );
    }
  };

  const exportToCSV = (csvData, fileName) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const onTableChange = (pagination, b, sort) => {
      setOffset(pagination.current - 1);
      let params = null;

      params = {
        offset: pagination.current - 1,
        limit: limit,
        where: {
          search: searchTerm ?? null,
          sorting: filterChoice ?? null
        },
      };
  
      fetchData(params);
  };


  const showMenu = (
    <Menu className="filter-container">
      <p className="filter-menu-title">{t("rating:filters.title")}</p>
      <Radio.Group value={filterChoice} onChange={onChangeFilter}>
        {FILTER_CHOICE.map((item, index) =>
          <MenuItem key={index} className="filter-menu-item">
            <Radio value={item.value}>{item.label}</Radio>
          </MenuItem>
        )}
      </Radio.Group>
    </Menu>
  );

  const handleSearch = (event) => {
    let searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    const searchParams = {
      where: {
        search: searchTerm,
        sorting: filterChoice ?? null
      },
    };

    fetchData(searchParams);
  };


  return (
    <MainLayout>
      <RatingStyle />
      <Card>
        <h1 className="page-title">{t`rating:title`}</h1>
        <Row justify="space-between" gutter={[16, 8]} style={{ marginBottom: "10px" }}>
          <Col>
            <Dropdown overlay={showMenu} placement="bottomLeft" arrow>
              <a className="filter-dropdown">
                {t("rating:filters.title")}{"   "}<DownOutlined />
              </a>
            </Dropdown>
          </Col>
          <Col className="search-text-container">
            <Space><p className="search-text">{t("rating:search")}:</p>
              <TextField radius="0.8125rem" onPressEnter={handleSearch} />
              <Button icon={<DownloadOutlined />} size="large" onClick={handleDownload} style={{ color: colors.shades.grey[25], border: "1px solid #707070", fontSize: "12pt" }}>{t("rating:export")}</Button>
            </Space>

          </Col>
        </Row>
        <Table
          rowKey={record => record.id}
          columns={columns}
          dataSource={allRating}
          locale={{ emptyText: t`common:table.emptyText` }}
          onChange={onTableChange}
          pagination={{
            pageSize: limit,
            defaultPageSize: limit,
            total: totalRecords,
            showSizeChanger: false
          }}
        />
      </Card>
    </MainLayout>
  )
}
export default RatingList;
