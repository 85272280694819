import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import MainLayout from "layouts/main";
import { Avatar, Col, Form, message, Row } from "antd";
import { DetailHeader, FileUploader, TextButton } from "components";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";
import AdminStyle from "styles/adminStyle";
import { Formik } from "formik";
import * as yup from "yup";
import FormItem from "antd/lib/form/FormItem";
import styled from "styled-components";
import { TextField as BaseTextField } from '@material-ui/core';
import { useParams, useHistory } from "react-router-dom";
import { AccountIcon } from "components/icons";
import { changeAdminPassword, createAdminUser, getAdminUser, patchAdminUser, uploadAdminAvatar } from "services/admin";
import { fullImageUrl } from "utils";
import { GlobalContext } from "contexts";
import "yup-phone";

const TextField = styled(({ ...rest }) => {
  return (
    <BaseTextField
      {...rest}
      InputLabelProps={{ shrink: true }}
    />
  );
})`
  width: 100%;
  margin: 0 auto;

  label {
    font-family: var(--font-glacial);
    font-weight: 800;
    font-size: 1.3rem;
    color: ${({ theme }) => theme.colors.darkBlue};
  }

  input {
    font-family: var(--font-glacial);
  }

  p.MuiFormHelperText-root {
    color: ${({ theme }) => `${theme.colors.shades.red[0]} !important`};
  }
`;

const AdminDetail = () => {
  const { t } = useTranslation(["common", "parameters", "users"]);
  const { id } = useParams();
  const [croppedAvatar, setCroppedAvatar] = useState(null);
  const profilePictureUploaderRef = useRef(null);
  const [userId, setUserId] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [initialValues, setInitialValues] = useState(null);
  const [isFetchingRecord, setIsFetchingRecord] = useState(false);
  const newRecord = useCallback(() => id === "nouvelle", [id]);
  const currentLoggedInUser = useCallback(() => localStorage.getItem("currentUser"), []);
  // const {
  //   setAvatar,
  // } = useContext(GlobalContext);

  const fetchRecord = () => {
    if (!newRecord()) {
      setIsFetchingRecord(true);

      getAdminUser(id).then(r => {
        console.log('r :>> ', r);
        setInitialValues({
          firstname: r?.firstname,
          name: r?.name,
          email: r?.email,
          phoneNumber: r?.phoneNumber,
          isActive: r?.isActive,
          avatar: fullImageUrl(r?.imageUrls, "md")
        })
        setCroppedAvatar(fullImageUrl(r?.imageUrls, "md"));
        setUserId(r?.id);

      }).catch(e => {
        message.error(t`common:errors.commonError`, 10);
        // history.push("/a-la-une");
      }).finally(() => {
        setIsFetchingRecord(false);
      });
    }
  };

  useEffect(() => {
    fetchRecord();
  }, []);

  const passwordValidationSchema = yup.object().shape({
    password: yup.string().required(t`common:errors.requiredField`),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('password'), null], t`common:errors.passwordMatch`)
      .required(t`common:errors.requiredField`),
    newPassword: yup
      .string()
      .required(t`common:errors.requiredField`)
      .min(8, t`common:errors.minLength`)
      .notOneOf([yup.ref('password')], t`common:errors.passwordMatch`)
      .test("validity", t`common:errors.incorrectPassword`, (value) => {
        const pwdRegex = /(?=^.{8,}$)(?=.*\d)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
        const isValidPassword = pwdRegex.test(value);
        if (!isValidPassword) {
          return false;
        }
        return true;
      })
  });

  const validationSchema = yup.object().shape({
    firstname: yup.string().required(t`common:errors.requiredField`),
    name: yup.string().required(t`common:errors.requiredField`),
    email: yup.string().required(t`common:errors.requiredField`).email(t`common:errors.emailIncorrect`),
    phone: yup
      .string()
      .phone(t`common:errors.phoneNumberError`)
      .required(t`common:errors.requiredField`),
  });

  const handleOnSubmit = (values, { setSubmitting }) => {
    console.log('values :>> ', values);

    const requestBody = {
      email: values.email,
      name: values.name,
      firstname: values.firstname,
      phoneNumber: values.phone,
    };

    // if (newRecord()) {
      if (id === "nouvelle") {
      createAdminUser(requestBody).then((resp) => {
        message.success(t`common:message.success`, 5);
        setUserId(resp?.id);
        history.push(`/parametres/comptes-admin/${resp?.id}`);
      }).catch(e => {
        if (e?.response?.data?.error?.message === "duplicateEmail") {
          message.error(t`common:errors.duplicateEmail`, 5);
        }
        else {
          message.error(t`common:errors.commonError`, 10);
        }
      }).finally(() => {
        setSubmitting(false);
      });
    } else {
      patchAdminUser(id, requestBody).then((resp) => {
        message.success(t`common:message.success`, 5);
        fetchRecord();
      }).catch(e => {
        message.error(t`common:errors.commonError`, 10);
      }).finally(() => {
        setSubmitting(false);
      });
    }
  };

  const handleOnSubmitPassword = (values, { resetForm, setSubmitting }) => {
    console.log('values :>> ', typeof id);
    setSubmitting(true);

    const requestBody = {
      userId: +id,
      currentPassword: values.password,
      confirmPassword: values.confirmPassword,
      password: values.newPassword
    };

    changeAdminPassword(requestBody).then((resp) => {
      console.log('resp :>> ', resp);
      message.success(t`common:success.saveSuccessfully`, 5);
      resetForm();
    }).catch((e) => {
      console.log('e?.response?.data :>> ', e?.response?.data?.error);
    }).finally(() => setSubmitting(false));
  };

  const handleProfilePictureUpload = ({ blob, base64, file }) => {
    console.log('file :>> ', file);
    setCroppedAvatar(base64);
    setLoading(true);

    if (base64) {
      const fileName = file.name;
      const fileType = file.type;
      const headers = {
        "Content-Type": "multipart/form-data",
        "Mime-Type": fileType,
      };
      const payload = new FormData();
      payload.append("avatar", blob, fileName);

      uploadAdminAvatar(userId, payload, headers)
        .then((resp) => {
          // console.log('resp :>> ', resp);
          // // setCurrentUser({ ...currentUser, userImages: resp?.imageUrls });
          // // setAvatar(resp?.imageUrls);
        })
        .catch((e) => {
          console.log("##### CATCH e :>> ", e);
          message.error(t`common:errors.unexpectedError`, 5);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const uploadNewProfilePicture = () =>
    profilePictureUploaderRef?.current?.click();

  return (
    <MainLayout>
      <AdminStyle />
      {!isFetchingRecord &&
        <Row gutter={[16, 16]} className="admin-user-detail-container">
          <Col span={24}>
            <DetailHeader
              icon={<AccountIcon fill={"#fff"} stroke={"#fff"} />}
              title={t`parameters:admin.title`}
              goBack={"/parametres/comptes-admin"}
              showFormButtons={false}
              isNewRecord={newRecord()}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <div className="admin-card">
              <img src="/icons/round-icon.svg" alt="round-icon" />
              <img src="/icons/user-icon.svg" alt="user-icon" />
              <h2 className="admin-card-heading">{t`users:personalProfile`}</h2>
              <Formik
                initialValues={{
                  firstname: initialValues?.firstname || "",
                  name: initialValues?.name || "",
                  email: initialValues?.email || "",
                  phone: initialValues?.phoneNumber || ""
                }}
                validationSchema={validationSchema}
                onSubmit={handleOnSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  isSubmitting,
                  handleSubmit,
                  dirty,
                  isValid,
                  setFieldTouched,
                  setFieldValue,
                  resetForm,
                }) => {
                  const retrieveErrors = (field) =>
                    (touched[field] && errors[field]) || "";

                  const handleChangeField = (e) => {
                    e?.target?.name &&
                      !touched[e.target.name] &&
                      setFieldTouched(e.target.name);
                    handleChange(e);
                  };

                  return (
                    <Form layout="vertical" className="admin-form-container" onFinish={handleSubmit}>
                      <FormItem
                        label={`${t("parameters:admin.table.headers.firstname")}*`}
                        help={retrieveErrors("firstname")}
                        validateStatus={retrieveErrors("firstname") && "error"}
                        className="text-label"
                      >
                        <TextField name="firstname"
                          placeholder={t("parameters:admin.table.headers.firstname")}
                          value={values.firstname}
                          onChange={handleChangeField} />
                      </FormItem>
                      <FormItem
                        label={`${t("parameters:admin.table.headers.name")}*`}
                        help={retrieveErrors("name")}
                        validateStatus={retrieveErrors("name") && "error"}
                        className="text-label"
                      >
                        <TextField
                          name="name"
                          placeholder={t("parameters:admin.table.headers.name")}
                          value={values.name}
                          onChange={handleChangeField}
                        />
                      </FormItem>
                      <FormItem
                        label={`${t("parameters:admin.table.headers.email")}*`}
                        help={retrieveErrors("email")}
                        validateStatus={retrieveErrors("email") && "error"}
                        className="text-label"
                      >
                        <TextField
                          name="email"
                          placeholder={t("parameters:admin.table.headers.email")}
                          value={values.email}
                          onChange={handleChangeField}
                        />
                      </FormItem>
                      <FormItem
                        label={`${t("parameters:admin.table.headers.phone")}*`}
                        help={retrieveErrors("phone")}
                        validateStatus={retrieveErrors("phone") && "error"}
                        className="text-label"
                      >
                        <TextField
                          name="phone"
                          placeholder={t("parameters:admin.table.headers.phone")}
                          value={values.phone}
                          onChange={handleChangeField}
                        />
                      </FormItem>

                      <TextButton variant="darkBlue"
                        rounded
                        width="100%"
                        htmlType="submit"
                        disabled={isSubmitting || !isValid}
                      >
                        {t`common:save`}
                      </TextButton>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} xxl={12}>
            <div className="admin-card">
              <h2 className="admin-card-heading">{t`users:avatar`}</h2>
              <FileUploader
                disabled={!userId || loading}
                uploadRef={profilePictureUploaderRef}
                acceptMultipleFiles={false}
                showUploadList={false}
                includeCropper={true}
                maxFileSize={5 * 1024 * 1024}
                uploadFile={handleProfilePictureUpload}
                listType="picture-card"
                className="profile-picture-image-uploader"
                cropProps={{
                  shape: "round",
                }}
                customRequest={
                  ({ file, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess("ok");
                    }, 0);
                  }}
              >
                <Avatar
                  src={croppedAvatar}
                  icon={<UserOutlined />}
                  size={240}
                />
              </FileUploader>
              <TextButton
                variant="darkBlue"
                rounded
                width="14rem"
                disabled={!userId || loading}
                onClick={uploadNewProfilePicture}
              >
                {t`users:editPhoto`}
              </TextButton>
            </div>
            {currentLoggedInUser() === id &&
              <div className="admin-card">
                <h2 className="admin-card-heading">{t`parameters:admin.changePassword.title`}</h2>
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                    newPassword: "",
                  }}
                  validationSchema={passwordValidationSchema}
                  onSubmit={handleOnSubmitPassword}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    isSubmitting,
                    handleSubmit,
                    dirty,
                    isValid,
                    setFieldTouched,
                    setFieldValue,
                  }) => {
                    const retrieveErrors = (field) =>
                      (touched[field] && errors[field]) || "";

                    const handleChangeField = (e) => {
                      e?.target?.name &&
                        !touched[e.target.name] &&
                        setFieldTouched(e.target.name);
                      handleChange(e);
                    };

                    return (
                      <Form layout="vertical" className="admin-form-container" onFinish={handleSubmit}>
                        <FormItem
                          label={`${t("parameters:admin.changePassword.password")}*`}
                          help={retrieveErrors("password")}
                          validateStatus={retrieveErrors("password") && "error"}
                          className="text-label"
                        >
                          <TextField
                            name="password"
                            placeholder={t("parameters:admin.changePassword.password")}
                            value={values.password}
                            onChange={handleChangeField}
                            type="password"
                          />
                        </FormItem>
                        <FormItem
                          label={`${t("parameters:admin.changePassword.confirmPassword")}*`}
                          help={retrieveErrors("confirmPassword")}
                          validateStatus={retrieveErrors("confirmPassword") && "error"}
                          className="text-label"
                        >
                          <TextField
                            name="confirmPassword"
                            placeholder={t("parameters:admin.changePassword.confirmPassword")}
                            value={values.confirmPassword}
                            onChange={handleChangeField}
                            type="password"
                          />
                        </FormItem>
                        <FormItem
                          label={`${t("parameters:admin.changePassword.newPassword")}*`}
                          help={retrieveErrors("newPassword")}
                          validateStatus={retrieveErrors("newPassword") && "error"}
                          className="text-label"
                        >
                          <TextField
                            name="newPassword"
                            placeholder={t("parameters:admin.changePassword.newPassword")}
                            value={values.newPassword}
                            onChange={handleChangeField}
                            type="password"
                          />
                        </FormItem>

                        <TextButton variant="darkBlue"
                          rounded
                          width="100%"
                          htmlType="submit"
                          disabled={isSubmitting || !isValid || !dirty}
                        >
                          {t`common:save`}
                        </TextButton>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            }
          </Col>
        </Row>
      }
    </MainLayout>
  );
};

export default AdminDetail;
