import { atom } from "recoil";
import { Atoms } from "store/constants";

/**
 * This file is generated as an example of Recoil Atoms
 *
 * To learn more about Recoil and state management,
 * please visit https://recoiljs.org/
 */

export const menuCollapsedState = atom({
  key: Atoms.MenuCollapsedState,
  default:
    sessionStorage.getItem("collapsed") &&
    sessionStorage.getItem("collapsed") === "true"
      ? true
      : false,
});
