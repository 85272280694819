import { useState, createContext } from "react";

export const GlobalContext = createContext(null);

export const GlobalContextProvider = ({ children }) => {
  const [avatar, setAvatar] = useState(localStorage.getItem("avatar") ? JSON.parse(localStorage.getItem("avatar")) : "{}");

  const clearAvatar = () => {
    setAvatar(null);
    localStorage.removeItem("avatar");
  };

  const handleSetAvatar = (params) => {
    setAvatar(params || null);
    localStorage.setItem("avatar", params ? JSON.stringify(params) : "{}");
  };

  const contextValue = {
    avatar,
    setAvatar: handleSetAvatar,
    clearAvatar,
  };

  return (
    <GlobalContext.Provider value={contextValue}>
      {children}
    </GlobalContext.Provider>
  );
};
