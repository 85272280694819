import { createGlobalStyle } from "styled-components";

const ProTransactionStyle = createGlobalStyle`


.page-title {
  margin-bottom: 0;
  font-family: var(--font-glacial);
  font-weight: 600;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.shades.grey[18]};
}

.page-sub-title {
  margin-bottom: 0;
  font-family: var(--font-glacial);
  font-weight: 600;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.shades.grey[18]};
  padding-top: 10px;
}

.not-sufficient-fund {
  color: ${({ theme }) => theme.colors.red};
}

.button-row {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.header-text {
  padding-left: 10px;
  font-size: 13pt;
  font-weight: 550;
}

.sub-header-text {
  padding-left: 10px;
  font-size: 9pt;
}

.not-sufficient-fund-text {
  margin-bottom: 0;
  font-family: var(--font-glacial);
  font-weight: 600;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.red};
}

.checkout-button {

  display: flex;
  flex-direction: row;
  justify-content: flex-end;

}

.confirmation-checkout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-header {
  background: ${({ theme }) => theme.colors.shades.blue[1]};
  border-radius: 0.1875rem;
  box-shadow: var(--box-shadow);
  .ant-card-body {
    padding: 0;
    color: ${({ theme }) => theme.colors.violet};
    font-weight: 200;
  }
}

.card-text {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-family: var(--font-glacial);
  font-size: 18pt;
  color: ${({ theme }) => theme.colors.shades.grey[18]};
  font-weight: 600;
}

.topup-display-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.topup-row-style {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.topup-button-pos-style {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.discount-label {
  font-family: var(--font-glacial);
  font-size: 14pt;
  font-style: italic;
  color: #B0B0B0;
  margin-bottom: 10px;
}

`;

export default ProTransactionStyle;
