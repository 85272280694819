import React, { useEffect, useRef, useState } from "react";
import MainLayout from "layouts/main";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import FeaturedStyle from "styles/featuredStyles";
import { Avatar, Button, Card, Col, Row, Space, Switch, Table } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { TextField } from "components/text-field";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";
import { format } from "date-fns";
import { EditOutlined } from "@material-ui/icons";
import { DeleteIcon } from "components/icons";
import styled from "styled-components";
import { TextButton } from "components";
import { getFeaturedList, patchFeaturedItem } from "services/featured";

const StyledAvatar = styled(({ label, ...rest }) => {
  return <Avatar size={40} {...rest}>{label.substring(0, 4)}</Avatar>;
})`
  background-color: ${({ color }) => color};
`;

const FeaturedList = () => {
  const { t } = useTranslation(["common", "featured"]);
  const history = useHistory();
  const [limit, setLimit] = useState(25);
  const [allData, setAllData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [showDeleteTagDialog, setShowDeleteTagDialog] = useState(false);
  const [isUpdatingVisibility, setIsUpdatingVisibility] = useState(false);
  const tagIndexToDelete = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const columns = [
    {
      title: t`featured:columns.title`,
      dataIndex: "title",
      sorter: (a, b) => {
        return a.title > b.title ? 1 : -1;
      }
    },
    {
      title: t`featured:columns.visible`,
      dataIndex: "isVisible",
      render: (checked, record) => {
        return (
          <Switch
            checkedChildren="O"
            unCheckedChildren="N"
            checked={record.isVisible}
            key={`${record.id}` || record.id}
            onChange={(e) => onHandleVisibility(e, record)}
            onClick={(e, event) => event.stopPropagation()}
            disabled={isUpdatingVisibility}
          />
        );
      },
      align: "center" as "center",
      sorter: (a, b) => a.isVisible - b.isVisible,
    },
    {
      title: t`featured:columns.label`,
      dataIndex: "headlineTags",
      render: (tags) => <span className="featured-tag-container">
        {tags?.map((tag, i) => {
          return <StyledAvatar key={i} color={tag.colorCode} label={tag.content} />
        })
        }</span>,
      align: "center" as "center",
    },
    {
      title: t`featured:columns.publish`,
      dataIndex: "createdAt",
      render: (date: string) => format(new Date(date), `dd/MM/yyyy ${t("common:at")} HH:mm`),
      align: "center" as "center",
      sorter: (a, b) => a.createdAt > b.createdAt ? 1 : -1,
    },
    {
      title: t`featured:columns.edit`,
      render: (record) => {
        return (
          <Button type="primary" icon={<EditOutlined />} className="featured-icon-button-edit" onClick={handleEditFeatured(record.id)} />
        );
      },
      align: 'center' as "center"
    },
    {
      title: t`featured:columns.delete`,
      render: (record) => {
        return (
          <Button type="primary" icon={<DeleteIcon />} className="featured-icon-button-delete" onClick={() => openDeleteTagDialog(record.id)} />
        );
      },
      align: "center" as "center"
    },
  ];

  const openDeleteTagDialog = (index) => {
    tagIndexToDelete.current = index;
    setShowDeleteTagDialog(true);
  }

  const closeDeleteTagDialog = () => setShowDeleteTagDialog(false);

  const onHandleVisibility = (checked, record) => {
    setIsUpdatingVisibility(true);
    patchFeaturedItem(record.id, { isVisible: checked })
      .then((resp) => {
        const listFeatured = allData.map((featuredItem) => ({
          ...featuredItem,
          isVisible: featuredItem.id === record.id ? checked : featuredItem.isVisible,
        }));
        setAllData(listFeatured);
      })
      .catch((e) => console.log(e))
      .finally(() => setIsUpdatingVisibility(false));
  }

  const handleEditFeatured = (id) => () => history.push(`/a-la-une/${id}`);

  useEffect(() => {
    getFeaturedList().then((resp) => {
      resp.filter((item) => !item.isDeleted);
      setAllData(resp);
      setTotalRecords(resp.length);
    }).catch((e) => console.log(e));
  }, []);

  const handleSearch = (event) => {
    let searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    let searchParams = null;
    searchParams = {
      where: {
        search: searchTerm,
      }
    }
    console.log('searchParams :>> ', searchParams);
    getFeaturedList(JSON.stringify(searchParams)).then((resp) => {
      console.log("SEARCH FETCH", resp);
      setAllData(resp);
      setTotalRecords(resp.length);
    }).catch((e) => console.log(e));
  };

  const handleCreateFeatured = () => history.push(`/a-la-une/nouvelle`);

  // const showMenu = (
  //   <Menu className="filter-container">
  //     <p className="filter-menu-title">{t("common:filters.filter")}</p>
  //     <Radio.Group value={filterChoice} onChange={onChangeFilter}>
  //       {radioItems.map((item, index) =>
  //         <MenuItem key={index} className="filter-menu-item">
  //           <Radio value={item.value}>{item.label}</Radio>
  //         </MenuItem>
  //       )}
  //     </Radio.Group>
  //   </Menu>
  // );

  const renderDeleteTagDialog = () => (
    <Dialog
      open={showDeleteTagDialog}
      onClose={closeDeleteTagDialog}
    >
      <DialogTitle>
        {t`featured:deleteFeatured.title`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t`featured:deleteFeatured.content`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <TextButton
          onClick={closeDeleteTagDialog}
          variant="darkBlue"
          width="10rem"
          rounded
        >
          {t`featured:detail.tags.deleteModal.no`}
        </TextButton>
        <TextButton
          onClick={handleDeleteFeaturedItem}
          variant="darkBlue"
          type="outlined"
          width="10rem"
          rounded
        >
          {t`featured:detail.tags.deleteModal.yes`}
        </TextButton>
      </DialogActions>
    </Dialog>
  );

  const handleDeleteFeaturedItem = () => {
    console.log('tagIndexToDelete :>> ', tagIndexToDelete);
    if (tagIndexToDelete) {
      setIsLoading(true);
      const deletedFeaturedItemId = tagIndexToDelete?.current;
      // api to delete 
      patchFeaturedItem(deletedFeaturedItemId, { isDeleted: true })
        .then((resp) => {
          getFeaturedList(JSON.stringify({})).then((resp) => {
            setAllData(resp);
            setTotalRecords(resp.length);
          }).catch((e) => console.log(e));
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setIsLoading(false);
          closeDeleteTagDialog()
        });
    }
  };

  return (
    <MainLayout>
      <FeaturedStyle />
      <Card>
        <Row gutter={[16, 8]} justify="start">
          <Col>
            <h1 className="featured-title">{t`featured:title`}</h1>
          </Col>
          <Col>
            <Button type="primary" icon={<PlusOutlined />} shape="circle" className="create-featured-button" onClick={handleCreateFeatured} />
          </Col>
        </Row>
        <Row justify="end" gutter={[16, 8]} className="featured-list-header">
          {/* <Col>
            <Dropdown overlay={showMenu} placement="bottomLeft" arrow>
              <a className="filter-dropdown" href=" ">
                {t("common:filters.sort")}{"   "}<DownOutlined />
              </a>
            </Dropdown>
          </Col> */}
          <Col className="search-text-container">
            <Space>
              <p className="search-text">{t("common:search")}:</p>
              <TextField radius="0.8125rem" onPressEnter={handleSearch} />
            </Space>
          </Col>
        </Row>
        {renderDeleteTagDialog()}
        <Table
          rowKey={record => record.id}
          loading={isLoading}
          columns={columns}
          dataSource={allData}
          locale={{ emptyText: t`common:table.emptyText` }}
          pagination={{
            pageSize: limit,
            defaultPageSize: limit,
            total: totalRecords,
            showSizeChanger: false
          }}
        />
      </Card>
    </MainLayout>
  );
};

export default FeaturedList;
