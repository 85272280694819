import { enUS, fr } from "date-fns/locale";

export const LOCALE_MAP = {
  en: enUS,
  fr: fr
};

export const FILE_UPLOAD_STATUS_DONE = "done";
export const FILE_UPLOAD_STATUS_UPLOADING = "uploading";

export const CALENDAR = {
  NAVIGATE: {
    PREVIOUS: "PREV",
    NEXT: "NEXT",
    TODAY: "TODAY",
    DATE: "DATE",
  },
  VIEWS: {
    MONTH: "month",
    WEEK: "week",
    WORK_WEEK: "work_week",
    DAY: "day",
    AGENDA: "agenda",
  },
};
export const FIXED_SCHEDULE = "FIXED";
export const APPOINTMENT_SCHEDULE = "APPOINTMENT";
export const SCHEDULE_REPEAT_TYPE_ONE_TIME = "ONE_TIME";
export const SCHEDULE_REPEAT_TYPE_DAY = "DAY";
export const SCHEDULE_REPEAT_TYPE_WEEK = "WEEK";
export const SCHEDULE_REPEAT_TYPE_MONTH = "MONTH";
export const SCHEDULE_REPEAT_TYPE_YEAR = "YEAR";
export const SCHEDULE_REPEAT_ON_MONDAY = "MONDAY";
export const SCHEDULE_REPEAT_ON_TUESDAY = "TUESDAY";
export const SCHEDULE_REPEAT_ON_WEDNESDAY = "WEDNESDAY";
export const SCHEDULE_REPEAT_ON_THURSDAY = "THURSDAY";
export const SCHEDULE_REPEAT_ON_FRIDAY = "FRIDAY";
export const SCHEDULE_REPEAT_ON_SATURDAY = "SATURDAY";
export const SCHEDULE_REPEAT_ON_SUNDAY = "SUNDAY";
export const SCHEDULE_REPEAT_ON_FIRST_DAY = "FIRST_DAY";
export const SCHEDULE_REPEAT_ON_LAST_DAY = "LAST_DAY";
export const SCHEDULE_REPEAT_ON_CURRENT_DATE = "CURRENT_DATE";
export const SCHEDULE_REPEAT_MONTH_TYPE_WEEKLY = "WEEKLY";
export const SCHEDULE_REPEAT_MONTH_TYPE_MONTHLY = "MONTHLY";
export const SCHEDULE_REPEAT_END_NEVER = "NEVER";
export const SCHEDULE_REPEAT_END_DATE = "DATE";
export const OFFER_TYPE_UNIT = "OFFER_UNIT";
export const OFFER_TYPE_PACK = "OFFER_UNIT";
export const OFFER_TYPE_SUBSCRIPTION = "OFFER_SUBSCRIPTION";
export const OFFER_TYPE_COURSE = "OFFER_COURSE";
export const OFFER_TYPE_TRIAL = "OFFER_TRIAL";

export const BOOKING_STATUS = {
  CREATED: "CREATED",
  PAID: "PAID",
  CONFIRMED: "CONFIRMED",
  REFUSED: "REFUSED",
  PAYMENT_FAILED: "PAYMENT_FAILED",
  ABANDONNED: "ABANDONNED",
  WAITING_FOR_CONFIRMATION: "WAITING_FOR_CONFIRMATION",
  VALIDATED: "VALIDATED",
  CANCELLED: "CANCELLED",
};

export const TRANSACTION_STATUS = {
  CREATED: "CREATED",
  CONFIRMED: "CONFIRMED"
};

export const PROMO_TYPES = {
  PROMOTIONAL_CODE: "PROMOTIONAL_CODE",
  GIFT_CARD: "GIFT_CARD"
};

export const DOCUMENT_CATEGORIES = {
  CONTRACT: "CONTRACT",
  INVOICE: "INVOICE",
  DATABASE: "DATABASE",
  OTHER: "OTHER"
}

export const ERRORS = {
  DUPLICATE_EMAIL: "duplicateEmail"
}