import { format } from "date-fns";
import * as yup from "yup";
import "yup-phone";

export const validatePhoneNumber = (phoneNumber) => {
  const phoneSchema = yup.string().phone();

  return new Promise((resolve, reject) => {
    phoneSchema
      .isValid(phoneNumber)
      .then((resp) => {
        resolve(resp);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const validateEmail = (email) => {
  const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email && regexEmail.test(email);
};

export const validatePostCode = (postCode) => {
  const regexPostCode = /^\d{5}(?:[-\s]\d{4})?$/;
  return postCode && regexPostCode.test(postCode);
};

export const validateFrenchPhoneNumber = (phoneNumber) => {
  const regexPhoneNumber =
    /^(?:(?:\+|00)33[\s.-]{0,3}(?:\(0\)[\s.-]{0,3})?|0)[1-9](?:(?:[\s.-]?\d{2}){4}|\d{2}(?:[\s.-]?\d{3}){2})$/;
  return phoneNumber && regexPhoneNumber.test(phoneNumber);
};

export const validateAddress = (address) => {
  // const regexAddress = /^\d{1,5}\s\w.\s(\b\w*\b\s){1,2}\w*\.$/;
  const regexAddress = /^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+)$/;
  return address && regexAddress.test(address);
};

export const validateCity = (city) => {
  const regexAddress = /^[a-zA-Z',.\s-]{1,25}$/;
  return city && regexAddress.test(city);
};


export const validateName = (str) => {
  const regexAlpha = /^([a-zA-Z\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9 -àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ01233456789.,]+)$/;
  // const regexAlpha = /^([a-zA-Z\sàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ -]+)$/;
  return str && regexAlpha.test(str);
};

export const getFullDateWithTime = (date) => {
  // const dateAndTime = date.split("T");
  if (date) {
    const dateAndTime = format(new Date(date), "dd/MM/yyyy HH:mm");
    const datePart = dateAndTime.split(" ");
    const timePart = datePart[1].replace(":", "h");
    const dateTimeFormat = datePart[0] + " à " + timePart;
    // return dateAndTime[0].split("-").reverse().join("-");
    return dateTimeFormat;
  } else return ''

}

export const localizeCurrency = (
  num = 0,
  locale = "fr",
  opts = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
) => {
  return (+num).toLocaleString(locale, opts);
};